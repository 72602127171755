import { Box } from '@mui/material'
import classnames from 'classnames'
import { get } from 'lodash'
import { useTranslate } from 'ra-core'
import {
  ReferenceField,
  SimpleShowLayout,
  TopToolbar,
  useGetOne,
  useRecordContext,
  useResourceContext,
} from 'react-admin'

import AdvancedBooleanField from '../../components/AdvancedBooleanField'
import AdvancedShow from '../../components/AdvancedShow'
import AdvancedTextField from '../../components/AdvancedTextField'
import BookingAdditionalDriversField from '../../components/BookingAdditionalDriversField'
import BookingJustificationField from '../../components/BookingJustificationField'
import BookingRatingField from '../../components/BookingRatingField'
import DateTooltipField from '../../components/DateTooltipField'
import FieldWrapper from '../../components/FieldWrapper'
import OperationalStatusSection from '../../components/OperationalStatusSection'
import PeriodField from '../../components/PeriodField'
import PriceField from '../../components/PriceField'
import ShowCardHeader from '../../components/ShowCardHeader'
import UnitField from '../../components/UnitField'
import UserFullNameField from '../../components/UserFullNameField'
import VehicleInteriorRatingField from '../../components/VehicleInteriorRatingField'
import {
  BOOKING_BILLING_TYPE_SOURCE,
  BOOKING_BILLING_TYPES,
  BOOKING_ERAS,
  BOOKING_PAYMENT_STATUSES,
} from '../../config/bookings'
import { SYSTEM_PERMISSION_READ, SYSTEM_PERMISSION_UPDATE } from '../../config/permissions'
import { useCommonStyles } from '../../config/theme'
import { isBookingPaid, isBookingPast, wasBookingCancelled } from '../../domain/bookings'
import { useResourcePermissions } from '../../domain/permissions'
import { isAllowed } from '../../utils'
import { formatDuration } from '../../utils/dates'
import { useSmallScreen } from '../../utils/theme'
import bookingDetailsConfig from '../bookingDetails/config'
import bookingTransactionsConfig from '../bookingTransactions/config'
import BookingTransactionsListLayout from '../bookingTransactions/list'
import { ListReference } from '../common/list'
import { ShowActions, ShowReference, ShowReferenceLinks, ShowSubSectionTitleCard, useShowStyles } from '../common/show'
import { DamageCheckShowLayout } from '../damageChecks/show'
import HubField from '../hubs/field'
import OrganisationField from '../organisations/field'
import usersConfig from '../users/config'
import { VehicleShowLayout } from '../vehicles/show'
import vouchersConfig from '../vouchers/config'
import VoucherField from '../vouchers/field'

import { EditBookingButton } from './buttons'
import config from './config'
import { useGetBookingsJustifications } from './hooks'

export const BookingShowLayout = ({ title, hasRedirect = false, excluded = [], ...props }) => {
  const record = useRecordContext(props)
  const { data: bookingDetails } = useGetOne(bookingDetailsConfig.name, { id: record.id })
  const bookingsJustifications = useGetBookingsJustifications()
  const [hasReadForUsers] = useResourcePermissions(usersConfig.name, SYSTEM_PERMISSION_READ)
  const [hasReadForVouchers] = useResourcePermissions(vouchersConfig.name, SYSTEM_PERMISSION_READ)

  const isSmallScreen = useSmallScreen()
  const commonClasses = useCommonStyles()
  const showClasses = useShowStyles()

  const shouldDisplayOrganisation = isAllowed(excluded, 'organisations')
  const shouldDisplayHub = isAllowed(excluded, 'hubs')

  const simpleShowLayoutCommonProps = { record, className: showClasses.fieldContainer }
  const operationalStatusSectionProps = { data: bookingDetails?.status_timeline }

  return (
    <>
      <ShowCardHeader hasRedirect={hasRedirect} record={record} title={title} />
      {bookingDetails && bookingsJustifications && (
        <>
          <div className={isSmallScreen ? null : showClasses.row}>
            <div className={showClasses.expanded}>
              <ShowSubSectionTitleCard text="bookingInformation" />
              {(shouldDisplayOrganisation || shouldDisplayHub || hasReadForUsers) && (
                <SimpleShowLayout {...simpleShowLayoutCommonProps}>
                  {shouldDisplayOrganisation && <OrganisationField />}
                  {shouldDisplayHub && <HubField />}
                  {hasReadForUsers && isAllowed(excluded, 'users') && (
                    <ReferenceField source="user_id" reference="users" link="show">
                      <UserFullNameField />
                    </ReferenceField>
                  )}
                  {hasReadForUsers && (
                    <BookingAdditionalDriversField label="resources.bookings.fields.additional_driver_user_ids" />
                  )}
                </SimpleShowLayout>
              )}
              <SimpleShowLayout {...simpleShowLayoutCommonProps}>
                <AdvancedTextField source="era" map={BOOKING_ERAS} />
                <PeriodField
                  startedOnSource="start_scheduled_on"
                  endedOnSource="end_scheduled_on"
                  label="resources.bookings.fields.scheduled_period"
                  addTime
                />
                <PeriodField
                  startedOnSource="started_on"
                  endedOnSource="ended_on"
                  label="resources.bookings.fields.used_period"
                  addTime
                />
                <DateTooltipField source="cancelled_on" addTime />
                <DateTooltipField source="created_on" addTime />
              </SimpleShowLayout>
              <SimpleShowLayout {...simpleShowLayoutCommonProps}>
                <VehicleInteriorRatingField />
                <BookingRatingField source="user_rating" />
              </SimpleShowLayout>
              <SimpleShowLayout {...simpleShowLayoutCommonProps}>
                <AdvancedTextField source="number_of_trips" />
                <UnitField source="fuel_delta" unit="L" />
                <UnitField source="time_driven" formatValue={(v) => formatDuration(Math.round(v / 60))} unit="" />
                <UnitField source="number_of_km_travelled" unit="km" />
              </SimpleShowLayout>
              <SimpleShowLayout {...simpleShowLayoutCommonProps}>
                <AdvancedTextField source={BOOKING_BILLING_TYPE_SOURCE} map={BOOKING_BILLING_TYPES} />
                <BookingJustificationField
                  addLabel
                  source="justification"
                  bookingsJustifications={bookingsJustifications}
                />
                {isBookingPast(record) && !wasBookingCancelled(record) && record.voucher_id ? (
                  <FieldWrapper source="amount">
                    {({ source }) => (
                      <>
                        <PriceField
                          source="initial_amount"
                          className={classnames(showClasses.italicHintText, showClasses.disabledText)}
                        />
                        {'\xa0\xa0'}
                        <PriceField source={source} className={showClasses.overrideText} />
                      </>
                    )}
                  </FieldWrapper>
                ) : (
                  <PriceField source="amount" />
                )}
                {hasReadForVouchers && <VoucherField />}
                {bookingDetails.payment && [
                  <AdvancedBooleanField
                    key="payment_required"
                    source="payment.required"
                    trueIcon={null}
                    falseIcon={null}
                  />,
                  bookingDetails.payment.status && [
                    <AdvancedTextField key="payment_status" source="payment.status" map={BOOKING_PAYMENT_STATUSES} />,
                    bookingDetails.payment.refusal_reason && (
                      <AdvancedTextField key="payment_refusal_reason" source="payment.refusal_reason" />
                    ),
                  ],
                ]}
              </SimpleShowLayout>
              {isSmallScreen && <OperationalStatusSection {...operationalStatusSectionProps} />}
              <ShowActions allowedActions={bookingDetails.allowed_actions} />
            </div>
            {!isSmallScreen && (
              <Box width="100%" maxWidth={400} className={commonClasses.borderLeft}>
                <OperationalStatusSection {...operationalStatusSectionProps} />
              </Box>
            )}
          </div>
          <ShowReferenceLinks excluded={excluded} record={record} />
        </>
      )}
    </>
  )
}

const BookingActions = () => {
  const resource = useResourceContext()
  const hasEdit = useResourcePermissions(resource, SYSTEM_PERMISSION_UPDATE)
  return <TopToolbar>{hasEdit && <EditBookingButton />}</TopToolbar>
}

const BookingAside = () => {
  const record = useRecordContext()
  const translate = useTranslate()
  const { data: bookingDetails } = useGetOne(bookingDetailsConfig.name, { id: record.id })
  const { data: organisation } = useGetOne(
    'organisations',
    { id: record.organisation_id },
    { enabled: Boolean(record.organisation_id) },
  )
  const organisationRequiresDamageReportPrompt = get(organisation, 'requires_damage_report_prompt', false)

  if (!bookingDetails || !organisation) {
    return null
  }
  return (
    <>
      {isBookingPaid(record) && Boolean(bookingDetails.payment?.required) && (
        <ListReference
          reference={bookingTransactionsConfig.name}
          target={config.options.referenceKey}
          sort={bookingTransactionsConfig.options.defaultSort}
        >
          <BookingTransactionsListLayout bookingId={record.id} />
        </ListReference>
      )}
      <ShowReference reference="vehicles" source="vehicle_id">
        <VehicleShowLayout excluded={['organisations']} hasRedirect />
      </ShowReference>
      {isBookingPast(record) && !wasBookingCancelled(record) && organisationRequiresDamageReportPrompt && (
        <ShowReference
          reference="damage-checks"
          source="id"
          errorText={translate('resources.damage-checks.missingForBooking')}
          errorTitle={translate('resources.damage-checks.name', 1)}
        >
          <DamageCheckShowLayout />
        </ShowReference>
      )}
    </>
  )
}

export default () => (
  <AdvancedShow actions={<BookingActions />} aside={<BookingAside />}>
    <BookingShowLayout excluded={['vehicles']} />
  </AdvancedShow>
)
