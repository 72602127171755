import * as base from './base'
import * as development from './development'
import * as production from './production'
import * as productionBeta from './production.beta'
import * as preproduction from './preproduction'
import * as staging from './staging'
import * as belgianarmy from './belgianarmy'
import * as belgianarmyBeta from './belgianarmy.beta'
import * as louezunvehicule from './louezunvehicule'
import * as valckenier from './valckenier'

export const DEVELOPMENT_MODE = process.env.NODE_ENV === 'development'

let local
if (DEVELOPMENT_MODE) {
  try {
    local = require('./development.local')
  } catch (error) {
    console.log('No configuration file found for local development')
  }
}

const all = {
  production,
  productionBeta,
  preproduction,
  staging,
  development: {
    ...development,
    ...local,
  },
  belgianarmy,
  belgianarmyBeta,
  louezunvehicule,
  valckenier,
}

const defaultEnv = DEVELOPMENT_MODE ? development : staging
const envNameByHostName = Object.fromEntries(Object.entries(all).map(([k, v]) => [v.HOST_NAME, v.ENV_NAME]))
const envName = envNameByHostName[window.location.hostname] || defaultEnv.ENV_NAME

const env = Object.freeze({
  ...base,
  ...all[envName],
})

if (env.ENV_NAME !== production.ENV_NAME) {
  console.log('env', env)
}

export default env
