import { Datagrid, ImageField, List, usePermissions } from 'react-admin'
import { Box, Grid } from '@mui/material'

import AdvancedTextField from '../../components/AdvancedTextField'
import CompactList from '../../components/CompactList'
import FieldWrapper from '../../components/FieldWrapper'
import ReferenceLink from '../../components/ReferenceLink'
import { SYSTEM_PERMISSION_READ } from '../../config/permissions'
import { hasResourcePermission } from '../../domain/permissions'
import { GenericListLayout, ListActions } from '../common/list'
import { AdvancedCreateInDialogButton } from '../common/create'

import OrganisationFormLayout, { transformValues } from './form'
import config from './config'

export const OrganisationsListLayout = () => {
  const { permissions } = usePermissions()

  return (
    <GenericListLayout
      compactListLayout={
        <CompactList inset linkType="show" image="picture" title="name" references={config.options.references} />
      }
      regularListLayout={
        <Datagrid rowClick="show">
          <FieldWrapper source="name">
            <Grid container direction="row" alignItems="center" spacing={2}>
              <Grid item>
                <Box
                  width={36}
                  height={36}
                  p={0.5}
                  m={-0.5}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  bgcolor="white"
                >
                  <ImageField
                    sx={{ '& img': { display: 'block', width: '100% !important', margin: '0 !important' } }}
                    source="picture"
                    label={false}
                    sortable={false}
                  />
                </Box>
              </Grid>
              <Grid item>
                <AdvancedTextField source="name" />
              </Grid>
            </Grid>
          </FieldWrapper>
          <Grid container direction="row" alignItems="center" justifyContent="flex-end" spacing={2}>
            {config.options.references
              .filter((r) => hasResourcePermission(permissions, r, SYSTEM_PERMISSION_READ))
              .map((reference) => (
                <Grid key={reference} item>
                  <ReferenceLink reference={reference} />
                </Grid>
              ))}
          </Grid>
        </Datagrid>
      }
    />
  )
}

export default () => {
  return (
    <List
      sort={config.options.defaultSort}
      filter={config.options.defaultFilterValues}
      actions={
        <ListActions>
          <AdvancedCreateInDialogButton transform={transformValues}>
            <OrganisationFormLayout />
          </AdvancedCreateInDialogButton>
        </ListActions>
      }
    >
      <OrganisationsListLayout />
    </List>
  )
}
