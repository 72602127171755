import {
  Datagrid,
  DateField,
  Filter,
  FunctionField,
  List,
  RecordRepresentation,
  ReferenceField,
  useGetResourceLabel,
  useRecordContext,
  useResourceContext,
} from 'react-admin'
import { useTranslate } from 'ra-core'
import { Box, Tooltip } from '@mui/material'
import { createElement } from 'react'

import { AdvancedDatesFilter } from '../../components/AdvancedDatesFilter'
import AdvancedTextField from '../../components/AdvancedTextField'
import CompactList from '../../components/CompactList'
import { EnumSelectInput } from '../../components/EnumSelectInput'
import {
  EVENT_CHANNEL_ICONS,
  EVENT_ACTIONS,
  EVENT_CHANNEL_GRAPHQL,
  EVENT_CHANNEL_OPS,
  EVENT_CHANNELS,
  EVENT_CHANNEL_WEBHOOK,
} from '../../config/events'
import { GenericListLayout, ListActions } from '../common/list'
import { getResourceByName } from '../index'

import config from './config'
import eventsExporter from './exporter'

const ChannelFilter = () => <EnumSelectInput source="channel" options={EVENT_CHANNELS} />
const ActionFilter = () => {
  const translate = useTranslate()
  return (
    <EnumSelectInput
      source="action"
      options={EVENT_ACTIONS}
      translateArgs={{ resource: translate('resources.events.fields.entity_id') }}
    />
  )
}

const EventsFilters = (props) => (
  <Filter {...props}>
    <AdvancedDatesFilter source={['date_gte', 'date_lte']} alwaysOn />
    <ActionFilter alwaysOn />
    <ChannelFilter alwaysOn />
  </Filter>
)

export const EventsListLayout = ({ excludedFields = [], ...props }) => (
  <GenericListLayout
    compactListLayout={
      <CompactList
        alignItems="flex-start"
        icon={<config.icon />}
        title={(record) => <AdvancedTextField record={record} source="name" />}
        body={() => null}
      />
    }
    regularListLayout={
      <Datagrid>
        <DateField source="date" showTime />
        <EventActionField source="action" />
        {!excludedFields.includes('target') && <EventTargetField source="entity_id" />}
        {!excludedFields.includes('author') && <EventAuthorField source="author" sortable={false} />}
        {!excludedFields.includes('channel') && <AdvancedEnumTextField source="channel" icons={EVENT_CHANNEL_ICONS} />}
      </Datagrid>
    }
    {...props}
  />
)

const EventActionField = ({ source }) => {
  const record = useRecordContext()
  const getResourceLabel = useGetResourceLabel()
  return <AdvancedEnumTextField source={source} translateArgs={{ resource: getResourceLabel(record.resource, 1) }} />
}

const ResourceIconField = (props) => {
  const resource = useResourceContext(props)
  const config = getResourceByName(resource)
  const getResourceLabel = useGetResourceLabel()
  return (
    <Tooltip title={getResourceLabel(resource)} placement="left">
      {createElement(config.icon)}
    </Tooltip>
  )
}

const EventTargetField = ({ source }) => {
  const { resource } = useRecordContext()
  return (
    <ReferenceField reference={resource} source={source} link="show">
      <Box display="flex" alignItems="center" gap={1}>
        <ResourceIconField resource={resource} />
        <RecordRepresentation />
      </Box>
    </ReferenceField>
  )
}

const AdvancedEnumTextField = ({ source, icons, translateArgs = {} }) => {
  const resource = useResourceContext()
  const translate = useTranslate()
  return (
    <FunctionField
      source={source}
      render={(record) => {
        const value = translate(
          `resources.${resource}.enums.${source}.values.${record[source].toLowerCase()}`,
          translateArgs,
        )
        return icons ? (
          <Tooltip title={value} placement="right">
            {createElement(icons[record[source]])}
          </Tooltip>
        ) : (
          value
        )
      }}
    />
  )
}

const EventAuthorField = () => {
  const record = useRecordContext()
  if (![EVENT_CHANNEL_OPS, EVENT_CHANNEL_GRAPHQL, EVENT_CHANNEL_WEBHOOK].includes(record.channel)) {
    return <AdvancedTextField source="author.fullName" />
  }
  const resource = record.channel === EVENT_CHANNEL_OPS ? 'ops-users' : 'users'
  return <ReferenceField reference={resource} record={record} source="author.id" link="show" />
}

export default () => (
  <List
    sort={config.options.defaultSort}
    filters={<EventsFilters />}
    actions={<ListActions hasExport />}
    exporter={eventsExporter}
  >
    <EventsListLayout />
  </List>
)
