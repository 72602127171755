import { Map as GMap, InfoWindow, Marker, Circle } from 'google-maps-react'
import { Typography } from '@mui/material'
import { useCallback, useMemo, useState } from 'react'

import mapStyles from './styles.json'

const MAP_DEFAULT_ZOOM = 15

const MapContainer = ({ google, zoom = MAP_DEFAULT_ZOOM, circles = [], markers = [] }) => {
  const [selectedMarker, setSelectedMarker] = useState(null)

  const onMapReady = useCallback(
    (mapProps, map, shouldFitBounds) => {
      // Use custom map styles
      map.setOptions({ styles: mapStyles })

      // Center map on existing markers
      if (shouldFitBounds) {
        const bounds = new google.maps.LatLngBounds()
        markers.forEach((marker) => {
          const { lat, lng } = marker.position
          bounds.extend(new google.maps.LatLng(lat, lng))
        })
        map.fitBounds(bounds)

        // We prevent the map to zoom too much at the beginning
        const zoomChangeBoundsListener = google.maps.event.addListenerOnce(map, 'bounds_changed', function () {
          if (this.getZoom() > MAP_DEFAULT_ZOOM) this.setZoom(MAP_DEFAULT_ZOOM)
        })
        setTimeout(function () {
          google.maps.event.removeListener(zoomChangeBoundsListener)
        }, 2000)
      }
    },
    [JSON.stringify(google), JSON.stringify(markers)], // eslint-disable-line react-hooks/exhaustive-deps
  )

  const onMapClicked = useCallback(() => {
    if (selectedMarker) {
      setSelectedMarker(null)
    }
  }, [selectedMarker])

  const onMarkerClick = (props, marker) => setSelectedMarker({ info: props.info, marker })

  const markersPositionInfo = useMemo(() => {
    const distinctMarkerLatitudes = [...new Set(markers.map((marker) => marker.position?.lat))].filter(
      (lat) => lat !== undefined,
    )
    const distinctMarkerLongitudes = [...new Set(markers.map((marker) => marker.position?.lng))].filter(
      (lng) => lng !== undefined,
    )
    const hasOneMarkerPosition = distinctMarkerLatitudes.length === 1 && distinctMarkerLongitudes.length === 1
    const hasSeveralMarkerPositions = distinctMarkerLatitudes.length > 1 || distinctMarkerLongitudes.length > 1
    return { hasOneMarkerPosition, hasSeveralMarkerPositions }
  }, [JSON.stringify(markers)]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <GMap
      google={google}
      zoom={zoom}
      style={{ borderRadius: '5px' }}
      initialCenter={markersPositionInfo.hasOneMarkerPosition ? markers[0].position : undefined}
      mapTypeControl={false}
      streetViewControl={false}
      onReady={(mapProps, map) => onMapReady(mapProps, map, markersPositionInfo.hasSeveralMarkerPositions)}
      onClick={onMapClicked}
    >
      {circles.map((circle, index) => (
        <Circle
          key={index}
          radius={circle.radius}
          center={circle.center}
          strokeColor="transparent"
          fillColor="#009688"
          fillOpacity={0.2}
        />
      ))}

      {markers.map((marker, index) => (
        <Marker
          key={index}
          position={marker.position}
          info={marker.info}
          icon={marker.icon}
          onClick={marker.info ? onMarkerClick : undefined}
        />
      ))}

      <InfoWindow
        marker={selectedMarker ? selectedMarker.marker : null}
        visible={Boolean(selectedMarker) && Boolean(selectedMarker.info)}
      >
        <div
          style={{
            color: 'rgba(0, 0, 0, 0.87)',
            fontWeight: 400,
            fontSize: '0.875rem',
          }}
        >
          {selectedMarker?.info && (
            <div>
              <Typography variant="subtitle2" gutterBottom={Boolean(selectedMarker.info.description)}>
                {selectedMarker.info.name}
              </Typography>
              <Typography variant="body2">{selectedMarker.info.description}</Typography>
            </div>
          )}
        </div>
      </InfoWindow>
    </GMap>
  )
}

const Map = (props) => <MapContainer {...props} google={window.google} />

export default Map
