import { Box } from '@mui/material'
import { ReferenceInput, useChoicesContext } from 'react-admin'
import { Controller } from 'react-hook-form'

import damageBlueprintsConfig from '../damageBlueprints/config'
import Blueprint from '../../components/Blueprint'

const ReferencedMarkerInput = (props) => {
  const { report, onNewMarker } = props
  const { allChoices } = useChoicesContext(props)
  const blueprint = allChoices.find((record) => record.id === report.blueprint_id)
  return blueprint ? (
    <Blueprint
      blueprint={blueprint}
      reports={report.marker_x_ratio ? [report] : []}
      onNewMarker={onNewMarker}
      {...props}
    />
  ) : null
}

const MarkerInput = (props) => (
  <Box sx={{ marginLeft: 2, marginTop: 2 }}>
    <ReferenceInput {...props}>
      <ReferencedMarkerInput {...props} />
    </ReferenceInput>
    <Controller name="marker_x_ratio" render={() => null} />
    <Controller name="marker_y_ratio" render={() => null} />
  </Box>
)

MarkerInput.defaultProps = {
  reference: damageBlueprintsConfig.name,
  source: damageBlueprintsConfig.options.referenceKey,
  editable: true,
}

export default MarkerInput
