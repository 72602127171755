import { Box, InputAdornment } from '@mui/material'
import { useTranslate } from 'ra-core'
import { useRef } from 'react'
import {
  ArrayInput,
  minValue,
  number,
  NumberInput,
  required,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  UPDATE,
} from 'react-admin'

import AdvancedDateTimeInput, { DATETIME_INPUT_MODE_DATE } from '../../components/AdvancedDateTimeInput'
import BasicFormToolbar from '../../components/BasicFormToolbar'
import StaticText from '../../components/StaticText'
import env from '../../config/env'
import { isDisabled } from '../../utils'
import { getRoundedNow } from '../../utils/dates'
import { useSmallScreen } from '../../utils/theme'
import { FormDivider } from '../common'
import { SimpleFormIteratorAddButton } from '../common/forms'
import organisationsConfig from '../organisations/config'
import OrganisationReferenceInput from '../organisations/input'

import { PricingStrategyChart } from './show'

const increasing = (source, message) => (value, values, meta) => {
  let { steps } = values
  const stepIndex = parseInt(meta.source.match(/^steps\.(\d)\./i)?.[1])
  if (steps && !isNaN(stepIndex)) {
    steps = steps
      .filter(Boolean) // remove empty steps
      .map((s, index) => ({ ...s, index })) // keep original array index
      .sort((a, b) => a.duration - b.duration) // sort by duration
    let minValue = 0
    for (const step of steps) {
      if (step[source] < minValue && step.index === stepIndex) {
        return message
      } else {
        minValue = step[source]
      }
    }
  }
  return undefined
}

const validateDuration = [
  required(),
  number('mymove.validation.number.invalid'),
  minValue(0, 'mymove.validation.number.notNegative'),
]

const validatePrice = [
  required(),
  number('mymove.validation.number.invalid'),
  minValue(0, 'mymove.validation.number.notNegative'),
  increasing('amount', 'resources.pricing-strategies.forms.validation.amount.increasing'),
]

const validateMileage = [
  required(),
  number('mymove.validation.number.invalid'),
  minValue(0, 'mymove.validation.number.notNegative'),
  increasing('mileage', 'resources.pricing-strategies.forms.validation.mileage.increasing'),
]

const validateSteps = [
  required(),
  (value) => (value?.length < 3 ? 'resources.pricing-strategies.forms.validation.steps.minimum' : undefined),
]

const PricingStrategyFormLayout = ({ disabledInputsSources = [], onClose, organisationId, type = UPDATE }) => {
  const translate = useTranslate()
  const isSmallScreen = useSmallScreen()
  const now = useRef(getRoundedNow())

  return (
    <SimpleForm
      mode="onBlur"
      toolbar={<BasicFormToolbar formType={type} onCancel={onClose} />}
      defaultValues={{ organisation_id: organisationId, steps: [{ duration: 0 }] }}
    >
      <Box>
        <OrganisationReferenceInput
          readOnly={isDisabled(disabledInputsSources, organisationsConfig.options.referenceKey)}
        />
        <TextInput source="name" validate={required()} />
        <AdvancedDateTimeInput
          validate={required()}
          source="start_date"
          mode={DATETIME_INPUT_MODE_DATE}
          minDate={now.current}
        />
        <FormDivider />
        <ArrayInput
          source="steps"
          label="resources.pricing-strategies.fields.steps.name"
          validate={validateSteps}
          sx={{ marginBottom: 0 }}
        >
          <SimpleFormIterator
            TransitionProps={{ enter: false, exit: false }}
            addButton={<SimpleFormIteratorAddButton />}
            inline
          >
            <NumberInput
              source="duration"
              validate={validateDuration}
              step={1}
              min={0}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">{translate('mymove.units.time.minutes', 2)}</InputAdornment>
                ),
              }}
            />
            <NumberInput
              source="amount"
              validate={validatePrice}
              format={(v) => (v ? v / 100 : null)}
              parse={(v) => v * 100}
              step={1}
              min={0}
              InputProps={{ endAdornment: <InputAdornment position="end">{env.CURRENCY_SYMBOL}</InputAdornment> }}
            />
            <NumberInput
              source="mileage"
              min={0}
              InputProps={{ endAdornment: <InputAdornment position="end">km</InputAdornment> }}
              validate={validateMileage}
            />
          </SimpleFormIterator>
        </ArrayInput>
        <FormDivider />
        <Box sx={{ margin: '16px 20px 8px 16px', textAlign: 'justify' }}>
          <Box sx={{ marginBottom: 1 }}>
            <StaticText>
              <em>{translate('resources.pricing-strategies.forms.helperTexts.first')}</em>
            </StaticText>
          </Box>
          <StaticText>
            <em>{translate('resources.pricing-strategies.forms.helperTexts.second')}</em>
          </StaticText>
        </Box>
        {!isSmallScreen && (
          <>
            <FormDivider />
            <Box sx={{ width: 640, margin: 2 }}>
              <PricingStrategyChart />
            </Box>
          </>
        )}
      </Box>
    </SimpleForm>
  )
}

export default PricingStrategyFormLayout
