import { Toolbar, SaveButton, CREATE } from 'react-admin'

import { useCommonStyles } from '../config/theme'
import { CancelButton } from '../resources/common/buttons'

import Spacer from './Spacer'

const BasicFormToolbar = ({ children, formType, onCancel, saveButton }) => {
  const classes = useCommonStyles()
  return (
    <Toolbar className={classes.toolbar}>
      {saveButton ?? <SaveButton alwaysEnable={formType === CREATE} />}
      <CancelButton onClick={onCancel} />
      <Spacer />
      {children}
    </Toolbar>
  )
}

export default BasicFormToolbar
