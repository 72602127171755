import { Box, Button, CardContent, Dialog, Grid, Typography } from '@mui/material'
import { alpha } from '@mui/material/styles'
import classnames from 'classnames'
import get from 'lodash/get'
import { useTranslate } from 'ra-core'
import { Fragment, useEffect, useRef, useState } from 'react'
import { Datagrid, Filter, List, Pagination, ReferenceField, useListContext } from 'react-admin'
import { useTheme } from '@mui/styles'

import AdvancedTextField from '../../components/AdvancedTextField'
import BookingConsumedField from '../../components/BookingConsumedField'
import CompactList from '../../components/CompactList'
import DateTooltipField from '../../components/DateTooltipField'
import PeriodField from '../../components/PeriodField'
import PriceField from '../../components/PriceField'
import ReferenceValue from '../../components/ReferenceValue'
import Scheduler from '../../components/Scheduler'
import StaticText from '../../components/StaticText'
import StatusField from '../../components/StatusField'
import UserFullNameField, { getUserFullName } from '../../components/UserFullNameField'
import VehicleIconField from '../../components/VehicleIconField'
import VehicleNameField, { getVehicleName } from '../../components/VehicleNameField'
import ViewModeToggle, { viewModeList, viewModeTimeline } from '../../components/ViewModeToggle'
import {
  BOOKING_BILLING_TYPE_SOURCE,
  BOOKING_BILLING_TYPES,
  BOOKING_ERA_CURRENT,
  BOOKING_ERAS,
} from '../../config/bookings'
import { SYSTEM_PERMISSION_CREATE, SYSTEM_PERMISSION_READ } from '../../config/permissions'
import { darkTheme, useCommonStyles } from '../../config/theme'
import { VEHICLE_TYPES } from '../../config/vehicles'
import { isBookingEditable, wasBookingCancelled } from '../../domain/bookings'
import { useResourcePermissions } from '../../domain/permissions'
import { getStatusStyles } from '../../domain/statuses'
import { isAllowed } from '../../utils'
import { useSmallScreen } from '../../utils/theme'
import { useCurrentAccountSelectors } from '../accounts/hooks'
import { FORM_TYPE_FILTER } from '../common/forms'
import { ListActions, ListCardTitle, useListStyles } from '../common/list'
import OrganisationField from '../organisations/field'
import usersConfig from '../users/config'
import UserReferenceInput from '../users/input'
import VehiclesFilter from '../vehicles/filter'
import vehicleUnavailabilitiesConfig from '../vehicleUnavailabilities/config'
import { useCreateVehicleUnavailability, useEditVehicleUnavailability } from '../vehicleUnavailabilities/form'
import useGetOrganisationWithHubFilters from '../common/filters'
import { AdvancedCreateInDialogButton } from '../common/create'
import { AdvancedEditInDialogButton } from '../common/edit'
import { AdvancedDatesFilter } from '../../components/AdvancedDatesFilter'

import config from './config'
import bookingsExporter from './exporter'
import { BookingCancelledFilter, BookingPeriodFilter } from './filter'
import BookingFormLayout, { transformValues, useCreateBooking, useEditBooking } from './form'

const BookingsFilters = ({ viewMode, ...props }) => {
  const organisationWithHubFilters = useGetOrganisationWithHubFilters()
  const [hasReadForUsers] = useResourcePermissions(usersConfig.name, SYSTEM_PERMISSION_READ)
  return (
    <Filter {...props}>
      {organisationWithHubFilters}
      {hasReadForUsers && <UserReferenceInput alwaysOn formType={FORM_TYPE_FILTER} validate={null} />}
      {viewMode === viewModeList && <VehiclesFilter alwaysOn />}
      {viewMode === viewModeList && <BookingCancelledFilter alwaysOn />}
      <BookingPeriodFilter alwaysOn />
      {viewMode === viewModeList && <AdvancedDatesFilter alwaysOn />}
    </Filter>
  )
}

const bookingRowStyle = (record) => {
  const wasCancelled = wasBookingCancelled(record)
  const isCurrent = !wasCancelled && get(record, 'era') === BOOKING_ERA_CURRENT
  return {
    opacity: wasCancelled ? 0.4 : 1,
    backgroundColor: isCurrent ? alpha(darkTheme.palette.secondary.main, 0.2) : 'none',
  }
}

export const BookingsListLayout = ({
  disabledInputsSources = [],
  excluded = [],
  isFullList = false,
  schedulerProps,
  setViewMode,
  viewMode = viewModeList,
}) => {
  const { hasSingleOrganisation, hasSingleHub } = useCurrentAccountSelectors()
  const [hasReadForUsers] = useResourcePermissions(usersConfig.name, SYSTEM_PERMISSION_READ)
  const [hasCreateForBookings] = useResourcePermissions(config.name, SYSTEM_PERMISSION_CREATE)
  const [hasCreateForVehicleUnavailabilities] = useResourcePermissions(
    vehicleUnavailabilitiesConfig.name,
    SYSTEM_PERMISSION_CREATE,
  )

  const [openCreateBookingPopup, createBookingPopupDialog] = useCreateBooking({ disabledInputsSources })
  const [openCreateVehicleUnavailabilityPopup, createVehicleUnavailabilityPopupDialog] = useCreateVehicleUnavailability(
    { disabledInputsSources },
  )

  const theme = useTheme()
  const listClasses = useListStyles()
  const commonClasses = useCommonStyles()
  const isSmallScreen = useSmallScreen()
  const translate = useTranslate()

  const { filterValues } = useListContext()
  const organisationId = get(filterValues, 'organisation_id')
  const hubId = get(filterValues, 'hub_id')
  const userId = get(filterValues, 'user_id')
  const vehicleId = get(filterValues, 'vehicle_id')
  const isFilteredByOrganisation = Boolean(organisationId)
  const isFilteredByHub = Boolean(hubId)
  const isFilteredByUser = Boolean(userId)
  const isFilteredByVehicle = Boolean(vehicleId)
  const isSchedulerEnabled = Boolean(schedulerProps) || (isFullList && (hasSingleHub || isFilteredByHub))

  useEffect(() => {
    if (!isSchedulerEnabled) {
      setViewMode?.(viewModeList)
    }
  }, [isSchedulerEnabled, setViewMode])

  const [bookingOrVehicleUnavailabilityChoicePopupState, setBookingOrVehicleUnavailabilityChoicePopupState] = useState({
    isOpen: false,
    values: {},
  })
  const handleBookingOrVehicleUnavailabilityChoicePopupClose = () =>
    setBookingOrVehicleUnavailabilityChoicePopupState({ isOpen: false, values: {} })

  const handleOpenCreateVehicleUnavailabilityPopup = () => {
    handleBookingOrVehicleUnavailabilityChoicePopupClose()
    openCreateVehicleUnavailabilityPopup?.({
      ...(Boolean(schedulerProps) && schedulerProps.creationInitialValues),
      ...bookingOrVehicleUnavailabilityChoicePopupState.values,
      started_on: bookingOrVehicleUnavailabilityChoicePopupState.values.start_scheduled_on,
      ended_on: bookingOrVehicleUnavailabilityChoicePopupState.values.end_scheduled_on,
    })
  }
  const handleOpenCreateBookingPopup = () => {
    handleBookingOrVehicleUnavailabilityChoicePopupClose()
    openCreateBookingPopup?.({
      ...(Boolean(schedulerProps) && schedulerProps.creationInitialValues),
      ...bookingOrVehicleUnavailabilityChoicePopupState.values,
    })
  }

  // We use a ref here to allow the update of the filter values when onBookingCreate func (in finalSchedulerProps) is called
  const onBookingOrVehicleUnavailabilityCreateInScheduler = useRef()
  onBookingOrVehicleUnavailabilityCreateInScheduler.current = (values) => {
    if (schedulerProps?.vehicleUnavailabilityCreationDisabled) {
      openCreateBookingPopup({ ...schedulerProps.creationInitialValues, ...values })
    } else {
      setBookingOrVehicleUnavailabilityChoicePopupState({ isOpen: true, values })
    }
  }

  const [openEditBookingPopup, editBookingPopupDialog] = useEditBooking()
  const [openEditVehicleUnavailabilityPopup, editVehicleUnavailabilityPopupDialog] = useEditVehicleUnavailability()

  const finalSchedulerProps = {
    filter: filterValues,
    groupAccessoryNameKey: 'designation',
    groupCategories: VEHICLE_TYPES,
    groupCategoryKey: 'type',
    groupFilter: { organisation_id: organisationId, hub_id: hubId, active: true },
    groupNameKey: (record) => getVehicleName(record),
    groupResource: 'vehicles',
    onBookingCreate: (values) => onBookingOrVehicleUnavailabilityCreateInScheduler.current(values),
    onBookingEdit: openEditBookingPopup,
    onVehicleUnavailabilityEdit: openEditVehicleUnavailabilityPopup,
    relatedResource: 'users',
    ...schedulerProps,
  }

  const shouldDisplayUsers = hasReadForUsers && isAllowed(excluded, 'users') && !isFilteredByUser
  const shouldDisplayVehiclesAttributes = isAllowed(excluded, 'vehicles') && !isFilteredByVehicle

  return (
    <Fragment>
      <CardContent className={classnames(commonClasses.titleContainer, listClasses.titleContainer)}>
        <ListCardTitle />
        {isSchedulerEnabled && <ViewModeToggle mode={viewMode} onChange={(newViewMode) => setViewMode(newViewMode)} />}
      </CardContent>

      {isSchedulerEnabled && viewMode === viewModeTimeline && <Scheduler {...finalSchedulerProps} />}

      {(!isSchedulerEnabled || viewMode === viewModeList) &&
        (isSmallScreen ? (
          <CompactList
            alignItems="flex-start"
            linkType="show"
            itemStyle={bookingRowStyle}
            icon={
              <ReferenceValue
                reference="vehicles"
                source="vehicle_id"
                target={(v) => <VehicleIconField record={v} />}
              />
            }
            iconBadgeColor={(record) => getStatusStyles(record.status, theme).mainColor}
            title={(record) => (
              <>
                <Typography component="span" variant="body1" className={commonClasses.inline} color="textPrimary">
                  <ReferenceValue record={record} reference="organisations" source="organisation_id" target="name" />
                </Typography>
                <Typography component="span" variant="body1" className={commonClasses.inline} color="textSecondary">
                  {' • '}
                  {shouldDisplayUsers ? (
                    <ReferenceValue
                      record={record}
                      reference="users"
                      source="user_id"
                      target={(u) => getUserFullName(u)}
                    />
                  ) : (
                    <ReferenceField reference="vehicles" source="vehicle_id" />
                  )}
                </Typography>
              </>
            )}
            body={(record) => (
              <>
                <StaticText>{translate('resources.hubs.name', 1)}: </StaticText>
                <ReferenceValue record={record} reference="hubs" source="hub_id" target="name" />
                <br />
                {shouldDisplayUsers && isAllowed(excluded, 'vehicles') && (
                  <>
                    <StaticText>{translate('resources.vehicles.name', 1)}: </StaticText>
                    <ReferenceValue reference="vehicles" source="vehicle_id" />
                    <br />
                  </>
                )}
                <StaticText>{'resources.bookings.fields.era'}: </StaticText>
                <AdvancedTextField record={record} source="era" map={BOOKING_ERAS} />
                <br />
                <PeriodField
                  record={record}
                  startedOnSource="effective_started_on"
                  endedOnSource="effective_ended_on"
                  addTime
                />
                <br />
                <StaticText>{'resources.bookings.fields.price'}: </StaticText>
                <PriceField record={record} source="amount" />
              </>
            )}
            references={config.options.references}
          />
        ) : (
          <Datagrid rowClick="show" rowStyle={bookingRowStyle}>
            <StatusField />
            {!hasSingleOrganisation && !isFilteredByOrganisation && <OrganisationField />}
            {!hasSingleHub && !isFilteredByHub && <ReferenceField source="hub_id" reference="hubs" link={false} />}
            {shouldDisplayUsers && (
              <ReferenceField reference="users" source="user_id" link="show">
                <UserFullNameField showFirstNameInitial={isFullList} />
              </ReferenceField>
            )}
            {shouldDisplayVehiclesAttributes &&
              [
                <VehicleIconField
                  source="vehicle_type"
                  color="textSecondary"
                  label="resources.vehicles.fields.type"
                  sortable={false}
                />,
                <ReferenceField
                  reference="vehicles"
                  source="vehicle_id"
                  link={false}
                  label="resources.vehicles.fields.model"
                >
                  <VehicleNameField />
                </ReferenceField>,
                <ReferenceField
                  source="vehicle_id"
                  sortBy="vehicle.designation"
                  reference="vehicles"
                  label="resources.vehicles.fields.designation"
                  link="show"
                >
                  <AdvancedTextField source="designation" />
                </ReferenceField>,
              ].map((field, index) => ({ ...field, key: index }))}
            <AdvancedTextField source={BOOKING_BILLING_TYPE_SOURCE} map={BOOKING_BILLING_TYPES} />
            <PriceField source="amount" />
            <BookingConsumedField />
            <AdvancedTextField source="era" map={BOOKING_ERAS} />
            <DateTooltipField source="effective_started_on" addTime />
            <DateTooltipField source="effective_ended_on" addTime />
            <AdvancedEditInDialogButton condition={isBookingEditable} transform={transformValues}>
              <BookingFormLayout />
            </AdvancedEditInDialogButton>
          </Datagrid>
        ))}
      {createBookingPopupDialog}
      {editBookingPopupDialog}
      {createVehicleUnavailabilityPopupDialog}
      {editVehicleUnavailabilityPopupDialog}
      {(hasCreateForBookings || hasCreateForVehicleUnavailabilities) && (
        <Dialog
          open={bookingOrVehicleUnavailabilityChoicePopupState.isOpen}
          onClose={handleBookingOrVehicleUnavailabilityChoicePopupClose}
          sx={{ '& .MuiDialog-paper': { flex: 'unset' } }}
        >
          <Box p={2}>
            <Grid container direction="column" spacing={2}>
              {hasCreateForBookings && (
                <Grid item>
                  <Button fullWidth variant="outlined" onClick={handleOpenCreateBookingPopup}>
                    {translate('resources.bookings.forms.create.pageTitle')}
                  </Button>
                </Grid>
              )}
              {hasCreateForVehicleUnavailabilities && (
                <Grid item>
                  <Button fullWidth variant="outlined" onClick={handleOpenCreateVehicleUnavailabilityPopup}>
                    {translate('resources.maintenances.forms.create.pageTitle')}
                  </Button>
                </Grid>
              )}
            </Grid>
          </Box>
        </Dialog>
      )}
    </Fragment>
  )
}

export default () => {
  const [viewMode, setViewMode] = useState(viewModeList)
  return (
    <List
      sort={config.options.defaultSort}
      filters={<BookingsFilters viewMode={viewMode} />}
      filterDefaultValues={config.options.defaultFilterValues}
      pagination={viewMode === viewModeList ? <Pagination /> : false}
      exporter={bookingsExporter}
      actions={
        <ListActions hasExport>
          <AdvancedCreateInDialogButton transform={transformValues}>
            <BookingFormLayout />
          </AdvancedCreateInDialogButton>
        </ListActions>
      }
    >
      <BookingsListLayout isFullList viewMode={viewMode} setViewMode={setViewMode} />
    </List>
  )
}
