import { SimpleShowLayout, useRecordContext } from 'react-admin'
import { Box } from '@mui/material'
import classnames from 'classnames'

import { ShowReferenceLinks, Title, useShowStyles } from '../common/show'
import AdvancedTextField from '../../components/AdvancedTextField'
import HubPickUpAndDropOffHoursField from '../../components/HubPickUpAndDropOffHoursField'
import HubsMapField from '../../components/HubsMapField'
import ShowCardHeader from '../../components/ShowCardHeader'
import UnitField from '../../components/UnitField'
import { PLACE_DETAILS_FIELD_FORMATTED_ADDRESS } from '../../config/addresses'
import { useCommonStyles } from '../../config/theme'
import { useSmallScreen } from '../../utils/theme'

import { CreateHubButton, EditHubButton } from './buttons'

const HubShowLayout = () => {
  const showClasses = useShowStyles()
  const commonClasses = useCommonStyles()
  const isSmallScreen = useSmallScreen()

  const record = useRecordContext()
  if (!record) return null
  const organisationId = record.organisation_id

  return (
    <>
      <ShowCardHeader
        action={
          <div className={commonClasses.toolbar}>
            <EditHubButton />
            <CreateHubButton organisationId={organisationId} />
          </div>
        }
      />
      <div className={showClasses.row}>
        <div className={showClasses.expanded}>
          <SimpleShowLayout className={showClasses.fieldContainer}>
            <AdvancedTextField source="name" />
            <AdvancedTextField
              source={`address.${PLACE_DETAILS_FIELD_FORMATTED_ADDRESS}`}
              label="resources.hubs.fields.address"
            />
            <UnitField source="geofencing" unit="m" />
          </SimpleShowLayout>
          <SimpleShowLayout className={showClasses.fieldContainer}>
            <HubPickUpAndDropOffHoursField />
          </SimpleShowLayout>
        </div>
        <Box
          width="100%"
          maxWidth={isSmallScreen ? null : 480}
          className={classnames(commonClasses.borderTop, isSmallScreen ? null : commonClasses.borderLeft)}
        >
          <SimpleShowLayout className={showClasses.map} sx={{ '& .RaSimpleShowLayout-row': { margin: 0, width: 480 } }}>
            <HubsMapField hubs={[record]} label={false} />
          </SimpleShowLayout>
        </Box>
      </div>
      <ShowReferenceLinks filterValues={{ organisation_id: organisationId }} />
    </>
  )
}

export const HubTitle = (props) => <Title>{props.details || props.record.name}</Title>

export default HubShowLayout
