import { Typography } from '@mui/material'
import { Datagrid, Filter, List, ReferenceField, useGetIdentity } from 'react-admin'
import { useTranslate } from 'ra-core'

import AdvancedTextField from '../../components/AdvancedTextField'
import CompactList from '../../components/CompactList'
import DateTooltipField from '../../components/DateTooltipField'
import ReferenceValue from '../../components/ReferenceValue'
import StaticText from '../../components/StaticText'
import UserFullNameField, { getUserFullName } from '../../components/UserFullNameField'
import VehicleIconField from '../../components/VehicleIconField'
import { DAMAGES_CATEGORIES, DAMAGES_STATUSES } from '../../config/damages'
import { OPS_USER_ROLE_LEASING_COMPANY, SYSTEM_PERMISSION_READ } from '../../config/permissions'
import { useCommonStyles } from '../../config/theme'
import { useResourcePermissions } from '../../domain/permissions'
import { isAllowed } from '../../utils'
import { useCurrentAccountSelectors } from '../accounts/hooks'
import { GenericListLayout } from '../common/list'
import HubField from '../hubs/field'
import OrganisationField from '../organisations/field'
import usersConfig from '../users/config'
import VehicleField from '../vehicles/field'
import VehiclesFilter from '../vehicles/filter'
import useGetOrganisationWithHubFilters from '../common/filters'
import { AdvancedEditInDialogButton } from '../common/edit'

import config from './config'
import damageReportsExporter from './exporter'
import MarkerField from './field'
import { DamageReportsStatusFilter } from './filter'
import DamageReportFormLayout from './form'

const DamageReportsFilters = (props) => {
  const organisationWithHubFilters = useGetOrganisationWithHubFilters()
  return (
    <Filter {...props}>
      {organisationWithHubFilters && organisationWithHubFilters.map((Filter) => Filter)}
      <VehiclesFilter alwaysOn />
      <DamageReportsStatusFilter alwaysOn />
    </Filter>
  )
}

export const DamageReportsListLayout = ({ excluded = [] }) => {
  const [hasReadForUsers] = useResourcePermissions(usersConfig.name, SYSTEM_PERMISSION_READ)
  const { hasSingleOrganisation, hasSingleHub } = useCurrentAccountSelectors()
  const translate = useTranslate()

  const commonClasses = useCommonStyles()

  return (
    <GenericListLayout
      compactListLayout={
        <CompactList
          alignItems="flex-start"
          linkType="show"
          icon={
            <ReferenceValue reference="vehicles" source="vehicle_id" target={(v) => <VehicleIconField record={v} />} />
          }
          title={(record) => (
            <>
              <Typography component="span" variant="body1" className={commonClasses.inline} color="textPrimary">
                <ReferenceValue record={record} reference="organisations" source="organisation_id" target="name" />
              </Typography>
              {isAllowed(excluded, 'vehicles') && (
                <Typography component="span" variant="body1" className={commonClasses.inline} color="textSecondary">
                  {' • '}
                  <ReferenceField record={record} reference="vehicles" source="vehicle_id" />
                </Typography>
              )}
            </>
          )}
          body={(record) => (
            <>
              <StaticText>{translate('resources.hubs.name', 1)}: </StaticText>
              <ReferenceValue record={record} reference="hubs" source="hub_id" target="name" fallback="n/a" />
              {hasReadForUsers && (
                <>
                  <br />
                  <StaticText>{'resources.damage-reports.fields.reporter_id'} </StaticText>
                  <ReferenceValue
                    record={record}
                    reference="users"
                    source="reporter_id"
                    target={(u) => getUserFullName(u)}
                  />
                </>
              )}
              <br />
              <StaticText>{'resources.damage-reports.fields.category'}: </StaticText>
              <AdvancedTextField
                record={record}
                source="category"
                fallback="resources.damage-reports.enums.category.none"
                map={DAMAGES_CATEGORIES}
              />
              <br />
              <StaticText>{'resources.damage-reports.fields.status'}: </StaticText>
              <AdvancedTextField record={record} source="status" map={DAMAGES_STATUSES} />
            </>
          )}
          references={config.options.references}
        />
      }
      regularListLayout={
        <Datagrid rowClick="show">
          {isAllowed(excluded, 'organisations') && !hasSingleOrganisation && <OrganisationField sortable={false} />}
          {isAllowed(excluded, 'hubs') && !hasSingleHub && <HubField sortable={false} />}
          {isAllowed(excluded, 'vehicles') && <VehicleField />}
          <AdvancedTextField
            source="category"
            fallback="resources.damage-reports.enums.category.none"
            map={DAMAGES_CATEGORIES}
          />
          <MarkerField containerWidth={200} markerSize={16} />
          <AdvancedTextField source="status" map={DAMAGES_STATUSES} />
          {hasReadForUsers && (
            <ReferenceField source="reporter_id" reference="users" link="show">
              <UserFullNameField />
            </ReferenceField>
          )}
          <DateTooltipField source="created_on" addTime />
          <AdvancedEditInDialogButton>
            <DamageReportFormLayout />
          </AdvancedEditInDialogButton>
        </Datagrid>
      }
    />
  )
}

export default () => {
  const { identity } = useGetIdentity()
  const translate = useTranslate()
  return (
    <List
      title={translate('resources.damage-reports.name', 2)}
      sort={config.options.defaultSort}
      filters={<DamageReportsFilters />}
      filterDefaultValues={config.options.defaultFilterValues}
      exporter={identity?.role === OPS_USER_ROLE_LEASING_COMPANY ? false : damageReportsExporter}
    >
      <DamageReportsListLayout />
    </List>
  )
}
