import { makeStyles } from '@mui/styles'

export const usePayoutCommonStyles = makeStyles((theme) => ({
  loadingContainer: {
    justifyContent: 'flex-start',
    marginTop: theme.spacing(4),
  },
  flexContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2),
  },
  largeBankIcon: {
    height: 150,
    width: 150,
    marginBottom: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  largeText: {
    fontSize: '2rem',
    color: theme.palette.text.secondary,
    textAlign: 'center',
    marginBottom: theme.spacing(2),
  },
  smallText: {
    fontSize: '1rem',
    color: theme.palette.text.secondary,
    textAlign: 'center',
    marginBottom: theme.spacing(4),
    maxWidth: 600,
  },
}))

export const usePayoutFormStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    paddingTop: theme.spacing(2),
  },
  loadingContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  formWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  formContainer: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    maxWidth: 640,
    '& .MuiFormControl-root': {
      margin: theme.spacing(2),
    },
  },
  formExplanation: {
    marginBottom: (props) => (props.isEmbedded ? 0 : theme.spacing(4)),
    marginLeft: (props) => (props.isEmbedded ? 0 : theme.spacing(2)),
    marginRight: theme.spacing(2),
    fontSize: 14,
  },
  formGroup: {
    marginBottom: theme.spacing(2),
  },
  unorderedList: {
    paddingLeft: 20,
  },
  cardHeader: {
    marginLeft: theme.spacing(2),
    paddingBottom: 0,
    paddingTop: 0,
    height: 80,
  },
  removeCardButton: {
    marginLeft: theme.spacing(2),
  },
  countryInputsContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  buttonsContainer: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
  },
  completedScreenContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2),
  },
}))

export const usePayoutShowStyles = makeStyles((theme) => ({
  card: {
    marginBottom: theme.spacing(1),
  },
  actions: {
    padding: 0,
  },
  listItemContainer: {
    height: 50,
    marginBottom: theme.spacing(1),
    paddingLeft: 12,
    borderWidth: 2,
    borderRadius: 6,
    borderColor: theme.palette.grey[400],
  },
  listItemRightContainer: {
    justifyContent: 'space-between',
    marginLeft: (props) => (props.isSmallScreen ? theme.spacing(1) : 0),
  },
  infoIcon: {
    marginLeft: 6,
    color: theme.palette.text.secondary,
    height: 15,
    width: 15,
  },
  statusIcon: {
    marginRight: 12,
  },
  bankAccountContainer: {
    paddingRight: theme.spacing(1),
  },
  bankAccountDisplayName: {
    marginRight: theme.spacing(1),
  },
  primaryAccountText: {
    color: theme.palette.grey[500],
    textTransform: 'uppercase',
    fontSize: '0.8125rem',
    fontWeight: 500,
    marginRight: 5,
  },
  setDefaultButton: {
    minHeight: 32,
    maxHeight: 32,
    minWidth: (props) => (props.isSmallScreen ? 44 : 144),
    maxWidth: (props) => (props.isSmallScreen ? 44 : 144),
  },
  defaultIcon: {
    width: (props) => (props.isSmallScreen ? null : 20),
    height: (props) => (props.isSmallScreen ? null : 20),
    paddingBottom: (props) => (props.isSmallScreen ? null : 2),
    marginRight: (props) => (props.isSmallScreen ? 10 : 6),
    color: theme.palette.grey[500],
  },
  setDefaultIcon: (props) =>
    props.isSmallScreen
      ? {}
      : {
          width: 20,
          height: 20,
          marginRight: 6,
          paddingBottom: 2,
        },
}))

export const usePayoutIconColorStyles = makeStyles((theme) => ({
  grey: {
    color: theme.palette.grey[500],
  },
  primary: {
    color: theme.palette.primary.main,
  },
  warning: {
    color: theme.palette.warning.main,
  },
  error: {
    color: theme.palette.error.main,
  },
}))
