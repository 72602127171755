import { Button as MuiButton } from '@mui/material'
import { useTranslate } from 'ra-core'
import {
  DeleteButton as DeleteButtonBase,
  EditButton as EditButtonBase,
  useNotify,
  useRecordContext,
} from 'react-admin'

export const DeleteButton = ({ condition, confirmTitle, successMessage }) => {
  const record = useRecordContext()
  const notify = useNotify()
  const onSuccess = () => notify(successMessage, { type: 'info' })
  if (!record || (typeof condition === 'function' && !condition(record))) {
    return null
  }
  return <DeleteButtonBase confirmTitle={confirmTitle} mutationMode="optimistic" mutationOptions={{ onSuccess }} />
}

export const EditButton = ({ condition, label }) => {
  const record = useRecordContext()
  if (!record || (typeof condition === 'function' && !condition(record))) {
    return null
  }
  return <EditButtonBase label={label} />
}

const goBack = () => window.history.go(-1)

export const CancelButton = ({ onClick = goBack }) => {
  const translate = useTranslate()

  // We handle the click event through mousedown because of an issue when
  // the button is not as the same place when mouseup occurs, preventing the click
  // event to fire.
  // It can happen when some errors appear under inputs, pushing the button
  // towards the window bottom.
  const handleMouseDown = (event) => {
    if (typeof onClick === 'function') {
      event.preventDefault()
      onClick()
    }
  }

  // As we handle the "click" through the mousedown event, we have to make sure we cancel
  // the default click in case the issue mentionned above does not occur.
  // Otherwise, this would trigger a standard HTML submit, not the final-form one.
  const handleClick = (event) => {
    event.preventDefault()
    event.stopPropagation()
  }

  return (
    <MuiButton variant="outlined" onMouseDown={handleMouseDown} onClick={handleClick}>
      {translate('ra.action.cancel')}
    </MuiButton>
  )
}
