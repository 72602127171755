import Icon from '@mui/icons-material/Business'

import { ORGANISATION_ACTIONS } from '../../config/organisations'
import {
  UI_PERMISSION_MENU,
  OPS_USER_ROLE_OWNER,
  OPS_USER_ROLE_ADMIN,
  OPS_USER_ROLE_SUPPORT,
  OPS_USER_ROLE_PLANNER,
  OPS_USER_ROLE_VIEWER,
} from '../../config/permissions'

export default {
  name: 'organisations',
  recordRepresentation: 'name',
  icon: Icon,
  options: {
    permissions: {
      [OPS_USER_ROLE_OWNER]: [UI_PERMISSION_MENU],
      [OPS_USER_ROLE_ADMIN]: [UI_PERMISSION_MENU],
      [OPS_USER_ROLE_SUPPORT]: [UI_PERMISSION_MENU],
      [OPS_USER_ROLE_PLANNER]: [UI_PERMISSION_MENU],
      [OPS_USER_ROLE_VIEWER]: [UI_PERMISSION_MENU],
    },
    referenceKey: 'organisation_id',
    defaultSort: { field: 'name', order: 'ASC' },
    defaultFilterValues: { is_archived: false },
    // TODO later in sync with backend: modify maintenances name
    references: ['vehicles', 'bookings', 'maintenances', 'damage-reports'],
    actions: ORGANISATION_ACTIONS,
  },
}
