import LockIcon from '@mui/icons-material/Lock'
import LockOpenIcon from '@mui/icons-material/LockOpen'

import AdvancedBooleanField from './AdvancedBooleanField'

const VehicleLockField = ({ addText, ...props }) => (
  <AdvancedBooleanField
    source="locked"
    trueIcon={LockIcon}
    falseIcon={LockOpenIcon}
    trueLabel={addText ? 'resources.vehicles.enums.locked.true' : null}
    falseLabel={addText ? 'resources.vehicles.enums.locked.false' : null}
    trueTooltip={addText ? null : 'resources.vehicles.enums.locked.true'}
    falseTooltip={addText ? null : 'resources.vehicles.enums.locked.false'}
    color="textSecondary"
    {...props}
  />
)

VehicleLockField.defaultProps = {
  addText: false,
  label: 'resources.vehicles.fields.locked',
}

export default VehicleLockField
