import accounts from './accounts'
import bookings from './bookings'
import bookingDetails from './bookingDetails'
import bookingTransactions from './bookingTransactions'
import damageBlueprints from './damageBlueprints'
import damageChecks from './damageChecks'
import damageReports from './damageReports'
import events from './events'
import hubs from './hubs'
import opsUsers from './opsUsers'
import organisations from './organisations'
import organisationDetails from './organisationDetails'
import organisationPressures from './organisationPressures'
import paymentActions from './paymentActions'
import users from './users'
import userDetails from './userDetails'
import vehicles from './vehicles'
import vehicleDetails from './vehicleDetails'
import vehicleUnavailabilities from './vehicleUnavailabilities'
import vouchers from './vouchers'
import pricingStrategies from './pricingStrategies'

const resources = {
  // order determines menu order!
  organisations,
  users,
  vehicles,
  bookings,
  // TODO later in sync with backend: remove maintenances mention
  maintenances: vehicleUnavailabilities,
  vouchers,
  damageReports,
  events,
  // resources below here are not in the menu
  accounts,
  bookingDetails,
  bookingTransactions,
  damageBlueprints,
  damageChecks,
  hubs,
  opsUsers,
  organisationDetails,
  organisationPressures,
  paymentActions,
  pricingStrategies,
  userDetails,
  vehicleDetails,
}

export const configs = Object.fromEntries(Object.entries(resources).map(([k, v]) => [k, v.config]))
export const list = Object.values(resources)
export const getResourceByName = (name) => Object.values(resources).find((r) => r.name === name)

export default resources
