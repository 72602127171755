import React, { useEffect } from 'react'
import { LinearProgress } from '@mui/material'
import { makeStyles } from '@mui/styles'
import {
  AutocompleteInput,
  Labeled,
  ReferenceInput,
  SelectInput,
  useGetList,
  useInput,
  useResourceContext,
} from 'react-admin'

import { FORM_TYPE_FILTER, useHelperText } from '../resources/common/forms'

const useStyles = makeStyles({
  linearProgress: {
    margin: (props) => (props.isFormTypeFilter ? 6 : 16),
    width: (props) => (props.isFormTypeFilter ? 227 : 256),
  },
})

const AdvancedReferenceInput = ({
  emptyText,
  emptyValue,
  excludedIds = [],
  filter,
  format,
  formType,
  parse,
  perPage = 10,
  readOnly,
  reference,
  sort,
  validate,
  ...props
}) => {
  const { data, total, isLoading } = useGetList(reference, {
    filter: { excluded_ids: excludedIds, ...filter },
    pagination: { page: 1, perPage },
    sort,
  })

  const helperText = useHelperText(props.source)
  const resource = useResourceContext()

  const {
    field: { value, onChange },
  } = useInput(props)

  const isFormTypeFilter = formType === FORM_TYPE_FILTER
  const classes = useStyles({ isFormTypeFilter })

  const label = props.label ?? `resources.${resource}.fields.${props.source}`
  const commonParentInputProps = { ...props, label }
  const commonChildInputProps = {
    defaultValue: data && total === 1 ? data[0].id : props.defaultValue,
    emptyText,
    emptyValue,
    format,
    helperText: isFormTypeFilter ? false : props.helperText ?? helperText,
    parse,
    readOnly,
    validate,
  }

  useEffect(() => {
    if (!isLoading) {
      if (total === 1 && value !== data[0].id) {
        setTimeout(() => onChange(data[0].id), 50) // tiny delay to run after interactions and prevent bugs where the value is not set
      } else if (
        (total === 0 && value !== '') ||
        (total <= perPage && data.map((item) => item.id).indexOf(value) === -1)
      ) {
        onChange('')
      }
    }
  }, [JSON.stringify(data), value, onChange, isLoading, total]) // eslint-disable-line react-hooks/exhaustive-deps

  if (isLoading) {
    return label ? (
      <Labeled label={label} className={classes.linearProgress}>
        <LinearProgress />
      </Labeled>
    ) : (
      <LinearProgress className={classes.linearProgress} />
    )
  }

  return (
    <ReferenceInput
      {...commonParentInputProps}
      enableGetChoices={({ search_designation }) => search_designation && search_designation?.length >= 1}
      filter={{ excluded_ids: excludedIds, ...filter }}
      perPage={perPage}
      reference={reference}
      sort={sort}
      source={props.source}
    >
      {total > perPage || total === 0 ? (
        <AutocompleteInput
          size="small"
          margin="dense"
          {...commonChildInputProps}
          noOptionsText="mymove.noOption"
          filterToQuery={(searchText) => ({ search_designation: searchText })}
        />
      ) : (
        <SelectInput {...commonParentInputProps} {...commonChildInputProps} readOnly={readOnly || total === 1} />
      )}
    </ReferenceInput>
  )
}

export default AdvancedReferenceInput
