import { makeStyles } from '@mui/styles'
import classnames from 'classnames'
import { isEmpty } from 'lodash'
import { ReferenceField, useGetList, useRecordContext } from 'react-admin'

import { useShowStyles } from '../resources/common/show'
import usersConfig from '../resources/users/config'

import UserFullNameField from './UserFullNameField'
import StaticText from './StaticText'

const useStyles = makeStyles((theme) => ({
  unorderedListItem: {
    marginBottom: theme.spacing(0.5),
  },
}))

const BookingAdditionalDriversField = () => {
  const record = useRecordContext()
  const { data } = useGetList('users', {
    pagination: { page: 1, perPage: record.additional_driver_user_ids.length },
    sort: usersConfig.options.defaultSort,
    filter: { id: record.additional_driver_user_ids },
  })

  const classes = useStyles()
  const showClasses = useShowStyles()

  if (isEmpty(data)) {
    return <StaticText>n/a</StaticText>
  }
  return (
    <ul className={showClasses.unorderedList}>
      {data.map(({ id }) => (
        <li key={id} className={classnames(showClasses.unorderedListItem, classes.unorderedListItem)}>
          <ReferenceField record={data.find((e) => e.id === id)} source="id" reference="users" link="show">
            <UserFullNameField />
          </ReferenceField>
        </li>
      ))}
    </ul>
  )
}

export default BookingAdditionalDriversField
