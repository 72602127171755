import { Box } from '@mui/material'
import { useCallback, useState } from 'react'
import {
  CREATE,
  UPDATE,
  required,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextInput,
  useGetOne,
  useRecordContext,
} from 'react-admin'
import { useFormContext, useWatch } from 'react-hook-form'

import AdvancedReferenceInput from '../../components/AdvancedReferenceInput'
import BasicFormToolbar from '../../components/BasicFormToolbar'
import { DAMAGES_CATEGORIES, DAMAGES_STATUSES } from '../../config/damages'
import { SYSTEM_PERMISSION_READ } from '../../config/permissions'
import { useResourcePermissions } from '../../domain/permissions'
import { FormDivider } from '../common'
import damageBlueprintsConfig from '../damageBlueprints/config'
import HubReferenceInput from '../hubs/input'
import OrganisationReferenceInput from '../organisations/input'
import usersConfig from '../users/config'
import UserReferenceInput from '../users/input'
import vehiclesConfig from '../vehicles/config'
import VehicleReferenceInput from '../vehicles/input'

import MarkerInput from './input'

const damageStatusChoices = Object.entries(DAMAGES_STATUSES).map(([k, v]) => ({ id: k, name: v }))
const damageCategoryChoices = Object.entries(DAMAGES_CATEGORIES).map(([k, v]) => ({ id: k, name: v }))

const SaveButtonWithMarker = ({ marker, ...props }) => {
  const { setValue } = useFormContext()
  const handleClick = useCallback(() => {
    setValue('marker_x_ratio', marker.xRatio)
    setValue('marker_y_ratio', marker.yRatio)
  }, [setValue, marker.xRatio, marker.yRatio])
  return <SaveButton onClick={handleClick} {...props} />
}

const BlueprintReferenceInput = ({ formType }) => {
  const vehicleId = useWatch({ name: 'vehicle_id' })
  const { data: vehicleData } = useGetOne(vehiclesConfig.name, { id: vehicleId }, { enabled: Boolean(vehicleId) })
  const blueprintSet = vehicleData?.blueprint_set
  return blueprintSet ? (
    <AdvancedReferenceInput
      reference={damageBlueprintsConfig.name}
      source={damageBlueprintsConfig.options.referenceKey}
      readOnly={!Boolean(vehicleId) || formType === UPDATE}
      filter={{ set: blueprintSet }}
    />
  ) : null
}

const DamageReportFormLayout = ({ type = UPDATE, onClose }) => {
  const record = useRecordContext()
  const [hasReadForUsers] = useResourcePermissions(usersConfig.name, SYSTEM_PERMISSION_READ)
  const [marker, setMarker] = useState({ xRatio: record.marker_x_ratio, yRatio: record.marker_y_ratio })
  const [isMarkerInputPristine, setIsMarkerInputPristine] = useState(true)

  return (
    <SimpleForm
      mode="onBlur"
      toolbar={
        <BasicFormToolbar
          onCancel={onClose}
          saveButton={<SaveButtonWithMarker marker={marker} alwaysEnable={type === CREATE || !isMarkerInputPristine} />}
        />
      }
    >
      <Box>
        <OrganisationReferenceInput readOnly={type === UPDATE} />
        <HubReferenceInput readOnly={type === UPDATE} />
        <VehicleReferenceInput formType={type} readOnly={type === UPDATE} />
        {hasReadForUsers && <UserReferenceInput source="reporter_id" readOnly={type === UPDATE} />}
        <BlueprintReferenceInput formType={type} />
        <FormDivider condition={() => Boolean(record.blueprint_id)} />
        {Boolean(record.blueprint_id) && (
          <MarkerInput
            report={record}
            onNewMarker={(m) => {
              setMarker(m)
              setIsMarkerInputPristine(false)
            }}
          />
        )}
        <FormDivider />
        <SelectInput choices={damageStatusChoices} source="status" validate={required()} />
        <SelectInput choices={damageCategoryChoices} source="category" validate={required()} />
        <FormDivider />
        <TextInput multiline source="note" validate={Boolean(record.blueprint_id) ? null : required()} />
      </Box>
    </SimpleForm>
  )
}

export default DamageReportFormLayout
