import { usePermissions } from 'react-admin'
import get from 'lodash/get'

export const hasResourcePermission = (permissions, resource, ...args) => {
  resource = resource === 'accounts' ? 'account' : resource
  return [...args].every((permission) => (get(permissions, ['resources', resource]) || []).includes(permission))
}

export const useResourcePermissions = (resource, ...args) => {
  const { permissions } = usePermissions()
  return [...args].map((permission) =>
    Boolean(permissions) ? hasResourcePermission(permissions, resource, permission) : undefined,
  )
}
