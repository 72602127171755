import lightTheme, { useCommonStyles } from '../config/theme'
import GoogleMap from '../vendor/googlemaps'

const pinPath =
  'M18,0 C8.0588745,0 0,7.5216162 0,16.8 C0,29.4 18,48 18,48 C18,48 36,29.4 36,16.8 C36,7.5216162 27.9411255,0 18,0 Z'

const getIconCommonConfig = (options) => ({
  fillColor: 'white',
  fillOpacity: 1,
  strokeColor: 'transparent',
  size: new window.google.maps.Size(options.width || 24, options.height || 24),
  anchor: new window.google.maps.Point((options.width || 24) / 2, (options.height || 24) / 2 - (options.offsetY || 0)),
})

const getFormattedPosition = (position) => ({
  lat: position.lat ?? position.latitude,
  lng: position.lng ?? position.longitude,
})

export const getMapMarkerGroup = (position, iconPath, info) => {
  return position
    ? [
        // pin shape
        {
          info,
          position: getFormattedPosition(position),
          icon: {
            ...getIconCommonConfig({ width: 36, height: 48, offsetY: -28 }),
            path: pinPath,
            fillColor: lightTheme.palette.primary[500],
            strokeColor: 'white',
            strokeWidth: 1,
          },
        },
        // icon
        {
          info,
          position: getFormattedPosition(position),
          icon: {
            ...getIconCommonConfig({ offsetY: -28 - 6 }),
            path: iconPath,
          },
        },
      ]
    : []
}

export const getMapCircle = (geofencing, position) => ({
  radius: geofencing,
  center: getFormattedPosition(position),
})

const Map = ({ zoom, width, height, markers, circles }) => {
  const classes = useCommonStyles()
  return (
    <div
      style={{
        position: 'relative',
        display: 'block',
        width: '100%',
        height: 0,
        paddingTop: (100 * height) / width + '%',
      }}
    >
      <div className={classes.absoluteFill}>
        {markers?.length > 0 ? <GoogleMap markers={markers} circles={circles} zoom={zoom} /> : null}
      </div>
    </div>
  )
}

Map.defaultProps = {
  width: 480,
  height: 360,
}

export default Map
