import { ListItem } from '@mui/material'
import AccountIcon from '@mui/icons-material/AccountBox'
import ArrowIcon from '@mui/icons-material/ChevronRight'
import { makeStyles } from '@mui/styles'
import classnames from 'classnames'
import { AppBar, Logout, MenuItemLink, TitlePortal, UserMenu } from 'react-admin'
import { useTranslate } from 'ra-core'
import { useLocation } from 'react-router-dom'

import { useDocumentTitle } from '../App'
import { useAccounts } from '../api/accountsProvider'
import { SYSTEM_PERMISSION_READ } from '../config/permissions'
import { THEME_MODE_LIGHT, useCommonStyles } from '../config/theme'
import { useResourcePermissions } from '../domain/permissions'
import accountConfig from '../resources/accounts/config'
import { AccountShowPage } from '../resources/accounts/show'
import { useShowStyles } from '../resources/common/show'
import opsUsersConfig from '../resources/opsUsers/config'
import payoutConfig, { PAYOUT_TITLE } from '../resources/payout/config'
import { PayoutShowScreen } from '../resources/payout/screens/show'
import { useSmallScreen } from '../utils/theme'

import AccountSwitch, { AccountSwitchMenuItemLink } from './AccountSwitch'
import BankIcon from './icons/BankIcon'
import LanguageSwitch, { LanguageSwitchMenuItemLink } from './LanguageSwitch'

const useStyles = makeStyles((theme) => ({
  appBarTitle: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    fontWeight: 500,
  },
  singleAccountName: {
    fontSize: 20,
    fontWeight: 500,
  },
  userMenuContainer: {
    marginLeft: theme.spacing(1),
  },
  userMenuItemLink: {
    color: theme.palette.mode === THEME_MODE_LIGHT ? 'rgba(0, 0, 0, 0.54)' : 'rgba(255, 255, 255, 0.7)',
  },
  userMenuListItem: {
    pointerEvents: 'none',
    paddingBottom: theme.spacing(2),
  },
}))

const CustomUserMenu = (props) => {
  const translate = useTranslate()
  const isSmallScreen = useSmallScreen()
  const classes = useStyles()

  const location = useLocation()
  const url = `${location.pathname}${location.search}${location.hash}`

  const [hasReadForAccount] = useResourcePermissions(accountConfig.name, SYSTEM_PERMISSION_READ)
  const { accounts, currentAccount } = useAccounts()
  const hasAccountsInfo = !!currentAccount
  const hasSeveralAccounts = hasAccountsInfo && accounts.length > 1

  const [hasReadForPayout] = useResourcePermissions(payoutConfig.name, SYSTEM_PERMISSION_READ)

  const [hasReadForOpsUsers] = useResourcePermissions(opsUsersConfig.name, SYSTEM_PERMISSION_READ)

  const menuItemLinkCommonProps = {
    divider: isSmallScreen,
    tooltipProps: { disableHoverListener: true },
    className: classes.userMenuItemLink,
  }

  return (
    <div className={classes.userMenuContainer}>
      <UserMenu {...props}>
        {hasReadForAccount && hasAccountsInfo && (
          <MenuItemLink
            {...menuItemLinkCommonProps}
            to={AccountShowPage.path}
            primaryText={isSmallScreen ? currentAccount.name : 'resources.accounts.management'}
            leftIcon={<AccountIcon />}
          />
        )}
        {isSmallScreen && !hasReadForAccount && hasAccountsInfo && (
          <ListItem className={classes.userMenuListItem} divider>
            {currentAccount.name}
          </ListItem>
        )}
        {isSmallScreen && hasSeveralAccounts && <AccountSwitchMenuItemLink {...menuItemLinkCommonProps} to={url} />}
        {hasReadForPayout && currentAccount && Boolean(currentAccount.is_payout_available) && (
          <MenuItemLink
            {...menuItemLinkCommonProps}
            to={PayoutShowScreen.path}
            primaryText={PAYOUT_TITLE}
            leftIcon={<BankIcon />}
          />
        )}
        {hasReadForOpsUsers && (
          <MenuItemLink
            {...menuItemLinkCommonProps}
            to={`/${opsUsersConfig.name}`}
            primaryText={translate('resources.ops-users.name', 2)}
            leftIcon={<opsUsersConfig.icon />}
          />
        )}
        {isSmallScreen && <LanguageSwitchMenuItemLink {...menuItemLinkCommonProps} to={url} />}
        <Logout className={classes.userMenuItemLink} />
      </UserMenu>
    </div>
  )
}

const CustomAppBar = (props) => {
  const classes = useStyles()
  const showClasses = useShowStyles()
  const commonClasses = useCommonStyles()
  const isSmallScreen = useSmallScreen()

  const { accounts, currentAccount } = useAccounts()
  const hasAccountsInfo = !!currentAccount
  const hasSingleAccount = hasAccountsInfo && accounts.length === 1
  const hasSeveralAccounts = hasAccountsInfo && accounts.length > 1

  useDocumentTitle(() => {
    const titleNode = document.getElementById('react-admin-title')
    if (!currentAccount) {
      return null
    }
    return titleNode ? currentAccount.name + ' > ' + titleNode.textContent : currentAccount.name
  })

  return hasAccountsInfo ? (
    <AppBar {...props} enableColorOnDark userMenu={<CustomUserMenu />}>
      {isSmallScreen ? (
        <div className={showClasses.expanded} />
      ) : (
        <>
          {hasSingleAccount && (
            <span className={classnames(commonClasses.textWithLargeIcons, classes.singleAccountName)}>
              {currentAccount.name}
              <ArrowIcon />
            </span>
          )}
          {hasSeveralAccounts && <AccountSwitch />}
          <TitlePortal className={classnames(showClasses.expanded, classes.appBarTitle)} />
          <LanguageSwitch />
        </>
      )}
    </AppBar>
  ) : null
}

export default CustomAppBar
