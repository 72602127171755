import { useMemo } from 'react'

import { path as hubIconPath } from './icons/HubIcon'
import Map, { getMapCircle, getMapMarkerGroup } from './Map'

const HubsMapField = ({ hubs = [] }) => {
  const mapInfo = useMemo(() => {
    let markers = []
    let circles = []
    hubs.forEach((hub) => {
      const hubMarkerGroup = getMapMarkerGroup(hub.position, hubIconPath, { name: hub.name })
      markers = markers.concat(hubMarkerGroup)
      const hubCircle = getMapCircle(hub.geofencing, hub.position)
      circles = circles.concat(hubCircle)
    })
    return { markers, circles }
  }, [JSON.stringify(hubs)]) // eslint-disable-line react-hooks/exhaustive-deps

  if (mapInfo.markers.length === 0) {
    return null
  }
  return <Map markers={mapInfo.markers} circles={mapInfo.circles} />
}

export default HubsMapField
