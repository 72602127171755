import classnames from 'classnames'
import { Datagrid, Filter, List, NumberField, ReferenceField, SimpleShowLayout } from 'react-admin'

import AdvancedTextField from '../../components/AdvancedTextField'
import CompactList from '../../components/CompactList'
import DateTooltipField from '../../components/DateTooltipField'
import PeriodField from '../../components/PeriodField'
import UserFullNameField from '../../components/UserFullNameField'
import { useCommonStyles } from '../../config/theme'
import { VOUCHER_TYPES } from '../../config/vouchers'
import { isVoucherExpired } from '../../domain/vouchers'
import { GenericListLayout, ListActions } from '../common/list'
import { useShowStyles } from '../common/show'
import OrganisationField from '../organisations/field'
import { AdvancedCreateInDialogButton } from '../common/create'
import { AdvancedEditInDialogButton } from '../common/edit'

import VoucherFormLayout, { transformValues } from './form'
import { VoucherTypeFilter } from './filter'
import { DiscountField } from './field'
import config from './config'

const VouchersFilters = (props) => (
  <Filter {...props}>
    <VoucherTypeFilter alwaysOn />
  </Filter>
)

const itemStyle = (record) => {
  const isExpired = isVoucherExpired(record)
  return {
    opacity: isExpired ? 0.4 : 1,
  }
}

const VoucherPanel = (props) => {
  const showClasses = useShowStyles()
  const commonClasses = useCommonStyles()

  return (
    <>
      <SimpleShowLayout {...props} className={classnames(showClasses.fieldContainer, commonClasses.borderless)}>
        <NumberField source="number_of_entries" />
        <NumberField source="number_of_uses" />
        <ReferenceField source="user_id" reference="users" link="show" emptyText="n/a">
          <UserFullNameField />
        </ReferenceField>
        <OrganisationField emptyText="n/a" />
      </SimpleShowLayout>
    </>
  )
}

export const VouchersListLayout = () => (
  <GenericListLayout
    compactListLayout={
      <CompactList
        linkType={null}
        itemStyle={itemStyle}
        icon={<config.icon />}
        body={(record) => (
          <>
            <AdvancedTextField record={record} source="type" map={VOUCHER_TYPES} />
            {' • '}
            <DiscountField record={record} />
            <br />
            <PeriodField record={record} startedOnSource="start_date" endedOnSource="end_date" addTime />
          </>
        )}
        references={config.options.references}
      />
    }
    regularListLayout={
      <Datagrid rowClick="expand" rowStyle={itemStyle} expand={<VoucherPanel />}>
        <AdvancedTextField source="code" />
        <AdvancedTextField source="name" />
        <AdvancedTextField source="type" map={VOUCHER_TYPES} />
        <DiscountField />
        <DateTooltipField source="start_date" addTime />
        <DateTooltipField source="end_date" addTime />
        <DateTooltipField source="created_on" addTime />
        <AdvancedEditInDialogButton transform={transformValues}>
          <VoucherFormLayout />
        </AdvancedEditInDialogButton>
      </Datagrid>
    }
  />
)

export default () => {
  return (
    <List
      sort={config.options.defaultSort}
      filters={<VouchersFilters />}
      filterDefaultValues={config.options.defaultFilterValues}
      actions={
        <ListActions>
          <AdvancedCreateInDialogButton transform={transformValues}>
            <VoucherFormLayout />
          </AdvancedCreateInDialogButton>
        </ListActions>
      }
    >
      <VouchersListLayout />
    </List>
  )
}
