import jsonExport from 'jsonexport/dist'
import { downloadCSV } from 'react-admin'

import { formatDateTimeForExport } from '../../utils/dates'

const eventsExporter = (records) => {
  const eventsForExport = records.map((event) => {
    const { author, date, ...eventForExport } = event // remove non-used fields
    // Add new fields (date formatting & header change)
    eventForExport.author_name = author.fullName
    eventForExport.date = formatDateTimeForExport(date)
    return eventForExport
  })
  jsonExport(
    eventsForExport,
    { headers: ['id', 'author_name', 'date', 'channel', 'action', 'resource', 'entity_id'] }, // order fields in the export
    (err, csv) => downloadCSV(csv, 'activity_logs'),
  )
}

export default eventsExporter
