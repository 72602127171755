import { Tooltip, Typography } from '@mui/material'
import { useTranslate } from 'ra-core'
import { useRecordContext } from 'react-admin'

import { VEHICLE_TYPES, VEHICLE_TYPES_ICONS } from '../config/vehicles'

const VehicleIconField = ({ source, color }) => {
  const { [source]: type } = useRecordContext()
  const translate = useTranslate()
  const tooltip = VEHICLE_TYPES[type]
  const icon = VEHICLE_TYPES_ICONS[type]
  return icon ? (
    <Typography component="span" variant="body2" color={color}>
      <Tooltip title={translate(tooltip)}>
        <span>
          <icon.type />
        </span>
      </Tooltip>
    </Typography>
  ) : null
}

VehicleIconField.defaultProps = {
  source: 'type',
}

export default VehicleIconField
