import { useGetList } from 'react-admin'

import config from './config'

export const useGetHubsListForOrganisation = (organisationId) =>
  useGetList(
    config.name,
    {
      pagination: { page: 1, perPage: 100 },
      sort: config.options.defaultSort,
      filter: { organisation_id: organisationId },
    },
    { enabled: Boolean(organisationId) },
  )
