import Icon from '@mui/icons-material/EventNote'
import { useGetRecordRepresentation, useRecordContext, useReference } from 'react-admin'

import { BOOKING_ACTIONS } from '../../config/bookings'
import {
  OPS_USER_ROLE_ADMIN,
  OPS_USER_ROLE_OWNER,
  OPS_USER_ROLE_PLANNER,
  OPS_USER_ROLE_SUPPORT,
  OPS_USER_ROLE_VIEWER,
  SYSTEM_PERMISSION_READ,
  UI_PERMISSION_MENU,
} from '../../config/permissions'
import usersConfig from '../users/config'
import { useCommonStyles } from '../../config/theme'
import { useResourcePermissions } from '../../domain/permissions'
import PeriodField from '../../components/PeriodField'

export default {
  name: 'bookings',
  icon: Icon,
  recordRepresentation: () => {
    const BookingRecordRepresentation = () => {
      const [hasReadForUsers] = useResourcePermissions(usersConfig.name, SYSTEM_PERMISSION_READ)
      const reference = hasReadForUsers ? 'users' : 'vehicles'

      const recordRepresentation = useGetRecordRepresentation(reference)

      const record = useRecordContext()
      const { referenceRecord } = useReference({ reference, id: hasReadForUsers ? record.user_id : record.vehicle_id })
      const commonClasses = useCommonStyles()
      return (
        <>
          {recordRepresentation(referenceRecord)}
          {' • '}
          <PeriodField
            record={record}
            className={commonClasses.inheritedFont}
            startedOnSource="start_scheduled_on"
            endedOnSource="end_scheduled_on"
            addTime
          />
        </>
      )
    }
    return <BookingRecordRepresentation />
  },
  options: {
    permissions: {
      [OPS_USER_ROLE_OWNER]: [UI_PERMISSION_MENU],
      [OPS_USER_ROLE_ADMIN]: [UI_PERMISSION_MENU],
      [OPS_USER_ROLE_SUPPORT]: [UI_PERMISSION_MENU],
      [OPS_USER_ROLE_PLANNER]: [UI_PERMISSION_MENU],
      [OPS_USER_ROLE_VIEWER]: [UI_PERMISSION_MENU],
    },
    referenceKey: 'booking_id',
    defaultSort: { field: 'effective_started_on', order: 'DESC' },
    defaultFilterValues: { cancelled: false },
    references: ['organisations', 'users', 'vehicles'],
    actions: BOOKING_ACTIONS,
    badge: true,
  },
}
