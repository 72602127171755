import { useRecordContext, useReference } from 'react-admin'

import VehicleOffIcon from '../../components/icons/VehicleOffIcon'
import {
  OPS_USER_ROLE_ADMIN,
  OPS_USER_ROLE_LEASING_COMPANY,
  OPS_USER_ROLE_OWNER,
  OPS_USER_ROLE_PLANNER,
  OPS_USER_ROLE_SUPPORT,
  OPS_USER_ROLE_VIEWER,
  UI_PERMISSION_MENU,
} from '../../config/permissions'
import { useCommonStyles } from '../../config/theme'
import VehicleNameField from '../../components/VehicleNameField'
import PeriodField from '../../components/PeriodField'

export default {
  // TODO later in sync with backend: modify maintenances name & referenceKey
  name: 'maintenances',
  recordRepresentation: () => {
    const VehicleRecordRepresentation = () => {
      const record = useRecordContext()
      const commonClasses = useCommonStyles()
      const { referenceRecord: vehicle, isLoading } = useReference({ reference: 'vehicles', id: record.vehicle_id })

      if (isLoading) {
        return 'Loading...'
      }

      if (!vehicle) {
        return ' ' // Non-breaking space
      }

      return (
        <>
          <VehicleNameField record={vehicle} className={commonClasses.inheritedFont} />
          {' • '}
          <PeriodField
            record={record}
            className={commonClasses.inheritedFont}
            startedOnSource="started_on"
            endedOnSource="ended_on"
            addTime
          />
        </>
      )
    }
    return <VehicleRecordRepresentation />
  },
  options: {
    permissions: {
      [OPS_USER_ROLE_OWNER]: [UI_PERMISSION_MENU],
      [OPS_USER_ROLE_ADMIN]: [UI_PERMISSION_MENU],
      [OPS_USER_ROLE_SUPPORT]: [UI_PERMISSION_MENU],
      [OPS_USER_ROLE_PLANNER]: [UI_PERMISSION_MENU],
      [OPS_USER_ROLE_LEASING_COMPANY]: [UI_PERMISSION_MENU],
      [OPS_USER_ROLE_VIEWER]: [UI_PERMISSION_MENU],
    },
    referenceKey: 'maintenance_id',
    defaultSort: { field: 'started_on', order: 'DESC' },
    references: ['organisations', 'vehicles'],
  },
  icon: VehicleOffIcon,
}
