import { Box, InputAdornment } from '@mui/material'
import { get } from 'lodash'
import {
  FormDataConsumer,
  maxValue,
  minValue,
  number,
  NumberInput,
  required,
  SimpleForm,
  TextInput,
  UPDATE,
  useRecordContext,
} from 'react-admin'
import { useTranslate } from 'ra-core'

import { FormDivider } from '../common'
import AdvancedSelectInput from '../../components/AdvancedSelectInput'
import AdvancedDateTimeInput, { DATETIME_INPUT_MODE_TIME } from '../../components/AdvancedDateTimeInput'
import BasicFormToolbar from '../../components/BasicFormToolbar'
import BillingTypesRadioButtonGroupInput from '../../components/BillingTypesRadioButtonGroupInput'
import DefaultBillingTypeSelectInput from '../../components/DefaultBillingTypeSelectInput'
import {
  ACCOUNT_BILLING_TYPES_SOURCE,
  ACCOUNT_FREE_BILLING_TYPE_REQUIRES_JUSTIFICATION_SOURCE,
} from '../../config/accounts'
import { BOOKING_BILLING_TYPE_FREE } from '../../config/bookings'
import { hasBillingType, hasMobilityBudgetBillingType } from '../../utils'
import { validateEndTimeAfterStartTime } from '../../utils/dates'

const validateBookingDefaultDuration = [
  required(),
  number('resources.accounts.forms.validation.booking_default_duration'),
  minValue(1 * 60, 'resources.accounts.forms.validation.booking_default_duration'),
  maxValue(24 * 60, 'resources.accounts.forms.validation.booking_default_duration'),
]

const validateBookingMaximumDuration = [
  number('resources.accounts.forms.validation.booking_maximum_duration'),
  minValue(24 * 60, 'resources.accounts.forms.validation.booking_maximum_duration'),
  maxValue(365 * 24 * 60, 'resources.accounts.forms.validation.booking_maximum_duration'),
]

export const transformValues = ({
  booking_default_duration,
  free_billing_type_requires_justification,
  has_mobility_budget,
  ...restValues
}) => ({
  ...restValues,
  [ACCOUNT_FREE_BILLING_TYPE_REQUIRES_JUSTIFICATION_SOURCE]: hasBillingType(
    restValues[ACCOUNT_BILLING_TYPES_SOURCE],
    BOOKING_BILLING_TYPE_FREE,
  )
    ? free_billing_type_requires_justification
    : false,
  ...(!restValues.display_step_duration_slider && { booking_default_duration }),
})

const AccountFormLayout = () => {
  const { billing_types: initialBillingTypes } = useRecordContext()
  const translate = useTranslate()
  return (
    <SimpleForm
      defaultValues={{
        has_mobility_budget: hasMobilityBudgetBillingType(initialBillingTypes),
        pro_hours_start: '09:00:00',
        pro_hours_end: '18:00:00',
      }}
      toolbar={<BasicFormToolbar formType={UPDATE} />}
      mode="onBlur"
    >
      <Box>
        <TextInput source="name" validate={required()} />
        <FormDivider />
        <AdvancedSelectInput source="has_mobility_budget" />
        <AdvancedSelectInput source="requires_payment" />
        <FormDivider />
        <BillingTypesRadioButtonGroupInput />
        <DefaultBillingTypeSelectInput />
        <FormDataConsumer>
          {({ formData }) =>
            hasBillingType(formData[ACCOUNT_BILLING_TYPES_SOURCE], BOOKING_BILLING_TYPE_FREE) && (
              <>
                <AdvancedSelectInput source={ACCOUNT_FREE_BILLING_TYPE_REQUIRES_JUSTIFICATION_SOURCE} />
                <AdvancedSelectInput source="end_forgotten_bookings_after_1_hour" />
              </>
            )
          }
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData }) =>
            formData.has_mobility_budget && (
              <>
                <FormDivider />
                <AdvancedDateTimeInput validate={required()} source="pro_hours_start" mode={DATETIME_INPUT_MODE_TIME} />
                <AdvancedDateTimeInput
                  validate={[required(), (value) => validateEndTimeAfterStartTime(formData.pro_hours_start, value)]}
                  source="pro_hours_end"
                  mode={DATETIME_INPUT_MODE_TIME}
                />
              </>
            )
          }
        </FormDataConsumer>
        <FormDivider />
        <AdvancedSelectInput source="is_free_to_join" />
        <AdvancedSelectInput source="display_step_duration_slider" />
        <FormDataConsumer>
          {({ formData }) =>
            !formData.display_step_duration_slider && (
              <NumberInput
                source="booking_default_duration"
                validate={validateBookingDefaultDuration}
                parse={(v) => (v ? v * 60 : null)}
                format={(v) => (v ? v / 60 : null)}
                min={1}
                max={24}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {translate('mymove.units.time.hours', get(formData, 'booking_default_duration') || 1)}
                    </InputAdornment>
                  ),
                }}
              />
            )
          }
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData }) => (
            <NumberInput
              source="booking_maximum_duration"
              validate={validateBookingMaximumDuration}
              min={1}
              max={365}
              parse={(v) => (v ? v * (24 * 60) : null)}
              format={(v) => (v ? v / (24 * 60) : null)}
              helperText="resources.accounts.forms.helperTexts.booking_maximum_duration"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {translate('mymove.units.time.days', get(formData, 'booking_maximum_duration') || 1)}
                  </InputAdornment>
                ),
              }}
            />
          )}
        </FormDataConsumer>
        <FormDivider />
        <TextInput source="mobile_legal_url" type="url" validate={required()} />
      </Box>
    </SimpleForm>
  )
}

export default AccountFormLayout
