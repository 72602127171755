import { makeStyles } from '@mui/styles'
import { useWatch } from 'react-hook-form'
import ArrowIcon from '@mui/icons-material/ArrowForward'
import classnames from 'classnames'

import { useCommonStyles } from '../config/theme'
import { parseDateAsDateTime } from '../utils/dates'

import AdvancedDateTimeInput from './AdvancedDateTimeInput'

const useStyles = makeStyles((theme) => ({
  arrowIcon: {
    width: 20,
    height: 20,
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    marginTop: 8,
  },
}))

export const AdvancedDatesFilter = ({ source = ['timeline_start', 'timeline_end'], ...props }) => {
  const values = useWatch()
  const commonClasses = useCommonStyles()
  const classes = useStyles()
  return (
    <>
      <AdvancedDateTimeInput
        {...props}
        source={source[0]}
        maxDate={values[source[1]] ? parseDateAsDateTime(values[source[1]]) : undefined}
      />
      <ArrowIcon className={classnames(commonClasses.contrastColor, classes.arrowIcon)} />
      <AdvancedDateTimeInput
        {...props}
        source={source[1]}
        minDate={values[source[0]] ? parseDateAsDateTime(values[source[0]]) : undefined}
      />
    </>
  )
}
