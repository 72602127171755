import { fetchUtils } from 'react-admin'

import { errorParser } from '../config/errors'
import { serializeFile } from '../utils'
import env from '../config/env'

import { mock } from './mocks'

export default (baseDataProvider, apiProvider) => {
  const uploadPicture = (resource, picture_data) =>
    serializeFile(picture_data.rawFile)
      .then((serializedFile) =>
        errorParser(apiProvider)(`/pictures/${resource}`, {
          method: 'POST',
          body: JSON.stringify(serializedFile),
        }),
      )
      .then(({ json }) => json)

  const uploadVehicleInformationDocument = (information_document_data) => {
    const formData = new FormData()
    information_document_data.title && formData.append('title', information_document_data.title)
    information_document_data.content && formData.append('content', information_document_data.content)
    formData.append('file', information_document_data.rawFile)
    return errorParser(apiProvider)('/vehicles/documents', {
      method: 'POST',
      body: formData,
    }).then(({ json }) => json)
  }

  const processData = async (resource, data) => {
    const { picture_data, information_document_data, ...restData } = data

    const [picture, information_document] = await Promise.all([
      picture_data?.rawFile ? uploadPicture(resource, picture_data).then(({ url }) => url) : picture_data?.src,
      information_document_data?.rawFile
        ? uploadVehicleInformationDocument(information_document_data).then(({ url }) => url)
        : information_document_data?.src,
    ])

    return { ...restData, picture, information_document }
  }

  // Decorate all provider methods with errorParser & mocked data
  const base = Object.fromEntries(
    Object.entries(baseDataProvider).map(([name, func]) => [name, mock(name, errorParser(func))]),
  )

  const sanitizeFilterParams = (filters) => {
    return Object.fromEntries(Object.entries(filters).filter(([key, value]) => value !== ''))
  }

  return {
    ...base,
    // Return deleted record after delete
    delete: (resource, params) => base.delete(resource, params).then(() => ({ data: params })),
    create: async (resource, params) => {
      const processedData = await processData(resource, params.data)
      return base.create(resource, { ...params, data: processedData })
    },
    // Return record id in case it's not returned by the backend
    getOne: (resource, params) => {
      // Handle special case for 'account' resource
      if (resource === 'accounts') {
        return errorParser(apiProvider)('/account', {
          method: 'GET',
        }).then(({ json }) => ({ data: { ...json, id: null } }))
      }
      return base.getOne(resource, params).then((response) => ({ data: { id: params.id, ...response.data } }))
    },
    update: async (resource, params) => {
      if (resource === 'accounts') {
        return errorParser(apiProvider)('/account', {
          method: 'PUT',
          body: JSON.stringify(params.data),
        }).then(({ json }) => ({ data: { ...json, id: null } }))
      }
      const processedData = await processData(resource, params.data)
      return base.update(resource, { ...params, data: processedData })
    },
    getList: (resource, params) => {
      const sanitizedFilters = sanitizeFilterParams(params.filter)
      const sanitizedParams = { ...params, filter: sanitizedFilters }
      return base.getList(resource, sanitizedParams)
    },
    getAvailableLoginMethods: async () => {
      const { json } = await fetchUtils.fetchJson(env.API_URL + '/login-methods')
      return { data: json }
    },
  }
}
