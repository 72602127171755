import Badge from '@mui/material/Badge'
import DefaultIcon from '@mui/icons-material/ViewList'
import { makeStyles } from '@mui/styles'
import { MenuItemLink, usePermissions, useResourceDefinitions, useSidebarState } from 'react-admin'
import { useTranslate } from 'ra-core'

import { useAccounts } from '../api/accountsProvider'
import { SYSTEM_PERMISSION_READ, UI_PERMISSION_MENU } from '../config/permissions'
import { SIDEBAR_CLOSED_WIDTH, SIDEBAR_OPEN_WIDTH } from '../config/theme'
import { hasResourcePermission } from '../domain/permissions'

const useStyles = makeStyles({
  menuContainer: {
    // We set menu container width here to prevent menu items tooltips being too far to the right when sidebar is closed
    width: (props) => (props.sidebarIsOpen ? SIDEBAR_OPEN_WIDTH : SIDEBAR_CLOSED_WIDTH),
    marginTop: 12,
  },
  menuItemLink: {
    marginBottom: 5,
    overflow: 'visible',
  },
})

const Menu = ({ onMenuClick }) => {
  const translate = useTranslate()
  const { currentAccount } = useAccounts()
  const [sidebarIsOpen] = useSidebarState()
  const classes = useStyles({ sidebarIsOpen })

  const resourcesDefinitions = useResourceDefinitions()
  const resources = Object.keys(resourcesDefinitions).map((name) => resourcesDefinitions[name])
  const { permissions } = usePermissions()
  const displayedResources = resources.filter((resource) =>
    hasResourcePermission(permissions, resource.name, SYSTEM_PERMISSION_READ, UI_PERMISSION_MENU),
  )

  return (
    <div className={classes.menuContainer}>
      {displayedResources.map((resource) => {
        let icon = resource.icon ? <resource.icon /> : <DefaultIcon />
        if (currentAccount && resource.options?.badge) {
          icon = (
            <Badge badgeContent={currentAccount.counts[resource.name]} color="error" overlap="rectangular">
              {icon}
            </Badge>
          )
        }
        return (
          <MenuItemLink
            key={resource.name}
            to={`/${resource.name}`}
            primaryText={translate(`resources.${resource.name}.name`, 2)}
            leftIcon={icon}
            onClick={onMenuClick}
            classes={{ root: classes.menuItemLink }}
          />
        )
      })}
    </div>
  )
}

export default Menu
