import classnames from 'classnames'
import ArrowIcon from '@mui/icons-material/ArrowForward'

import { useCommonStyles } from '../config/theme'

import DateTooltipField from './DateTooltipField'

const PeriodField = ({
  startedOnSource = 'startedOn',
  endedOnSource = 'endedOn',
  label = 'resources.common.fields.period',
  ...props
}) => {
  const classes = useCommonStyles()

  return (
    <span className={classnames(classes.textWithSmallIcons, props.className)}>
      <DateTooltipField {...props} source={startedOnSource} />
      <ArrowIcon />
      <DateTooltipField {...props} source={endedOnSource} />
    </span>
  )
}

export default PeriodField
