import { Avatar, Button, Card, CardActions, CardContent, CircularProgress, Typography } from '@mui/material'
import LockIcon from '@mui/icons-material/Lock'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import { capitalize } from 'lodash'
import { makeStyles } from '@mui/styles'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { Form, useAuthProvider, useCheckAuth, useSafeSetState, useTranslate } from 'ra-core'
import * as React from 'react'
import { useEffect, useRef } from 'react'
import { FormDataConsumer, Notification, required, TextInput, useDataProvider, useLogin, useNotify } from 'react-admin'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useQuery } from 'react-query'

import GoogleIcon from '../../components/icons/GoogleIcon'
import MicrosoftIcon from '../../components/icons/MicrosoftIcon'
import { useCommonStyles } from '../../config/theme'
import { useDocumentTitle } from '../../App'
import { validateEmail } from '../../utils/validators'

const useFormCardStyles = makeStyles(
  (theme) => ({
    main: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
      height: '1px',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundColor: theme.palette.background.default,
    },
    card: {
      minWidth: 300,
      marginTop: '6em',
    },
    avatar: {
      margin: '1em',
      display: 'flex',
      justifyContent: 'center',
    },
    icon: {
      backgroundColor: theme.palette.secondary[500],
    },
  }),
  { name: 'RaLogin' },
)

const FormCard = (props) => {
  const { title, children, shouldCheckAuth, ...rest } = props
  const containerRef = useRef()
  const classes = useFormCardStyles(props)

  return (
    <div className={classnames(classes.main)} {...rest} ref={containerRef}>
      <Card className={classes.card}>
        <div className={classes.avatar}>
          <Avatar className={classes.icon}>
            <LockIcon />
          </Avatar>
        </div>
        {children}
      </Card>
      <Notification />
    </div>
  )
}

FormCard.propTypes = {
  children: PropTypes.node.isRequired,
}

const useFormStyles = makeStyles(
  (theme) => ({
    form: {
      paddingBottom: theme.spacing(1),
    },
    inputsContainer: {
      padding: '0 1em 1em 1em',
    },
    input: {
      width: '100%',
      margin: '0',
    },
    button: {
      width: '100%',
    },
    ssoButton: {
      backgroundColor: '#F5F5F5',
      borderRadius: 4,
      boxShadow: '0 2px 2px 0 rgba(0, 0, 0, .24), 0 0 1px 0 rgba(0, 0, 0, .24)',
      color: 'black',
      '&:hover': {
        backgroundColor: '#C2C2C2',
      },
      '&:disabled': {
        boxShadow: 'none',
        color: '#8F8F8F',
        backgroundColor: '#595959',
      },
    },
    loadingIcon: {
      marginRight: theme.spacing(1),
    },
    footer: {
      justifyContent: 'center',
    },
  }),
  { name: 'RaLoginForm' },
)

const LoginEmailTextInput = ({ disabled }) => {
  const translate = useTranslate()
  const classes = useFormStyles()
  return (
    <TextInput
      autoFocus
      source="username"
      label={translate('mymove.email')}
      autoComplete="email"
      className={classes.input}
      disabled={disabled}
      validate={validateEmail()}
      fullWidth
      variant="standard"
    />
  )
}

const LoginForm = ({ loginRedirectionPathName, ...props }) => {
  const [submittingProvider, setSubmittingProvider] = useSafeSetState('')
  const login = useLogin()
  const notify = useNotify()
  const translate = useTranslate()
  const classes = useFormStyles(props)
  const commonClasses = useCommonStyles()
  const dataProvider = useDataProvider()

  const { data } = useQuery([], () => dataProvider.getAvailableLoginMethods())

  const availableLoginMethods = data ? data.data.map((method) => method.toLowerCase()) : []

  const submit = (values, loginType = 'password') => {
    setSubmittingProvider(loginType)

    login({ ...values, loginType }, `/redirect?path=${loginRedirectionPathName}`)
      .catch((error) => {
        notify(typeof error === 'string' ? error : error?.message || 'ra.auth.sign_in_error', {
          type: 'error',
          messageArgs: { _: typeof error === 'string' ? error : error && error.message ? error.message : undefined },
        })
      })
      .finally(() => {
        setSubmittingProvider('')
      })
  }

  const renderSSOButton = (provider, IconComponent) => (
    <CardActions className={classes.footer}>
      <Button
        disabled={!!submittingProvider}
        className={classnames(classes.button, classes.ssoButton)}
        onClick={() => submit({}, provider)}
        startIcon={submittingProvider === provider ? <CircularProgress size={18} thickness={2} /> : <IconComponent />}
      >
        {translate(`mymove.login.signInWith${capitalize(provider)}`)}
      </Button>
    </CardActions>
  )

  return availableLoginMethods ? (
    <Form onSubmit={submit} noValidate className={classes.form} disableInvalidFormNotification>
      {availableLoginMethods.includes('password') && (
        <>
          <CardContent className={classes.inputsContainer}>
            <LoginEmailTextInput disabled={!!submittingProvider} />
            <TextInput
              source="password"
              label={translate('ra.auth.password')}
              type="password"
              autoComplete="current-password"
              className={classes.input}
              disabled={!!submittingProvider}
              validate={required()}
              fullWidth
              variant="standard"
            />
          </CardContent>
          <CardActions>
            <Button
              variant="contained"
              type="submit"
              color="primary"
              disabled={!!submittingProvider}
              className={classes.button}
            >
              {submittingProvider === 'password' && (
                <CircularProgress className={classes.loadingIcon} size={18} thickness={2} />
              )}
              {translate('ra.auth.sign_in')}
            </Button>
          </CardActions>
          <CardActions className={classes.footer}>
            <FormDataConsumer>
              {({ formData }) => (
                <Typography component="span" variant="body2" color="primary">
                  <Link to={ResetPage.path + '?email=' + (formData.username || '')} className={commonClasses.link}>
                    {translate('mymove.login.forgotPassword')}
                  </Link>
                </Typography>
              )}
            </FormDataConsumer>
          </CardActions>
        </>
      )}
      {availableLoginMethods.includes('google_com') && renderSSOButton('google', GoogleIcon)}
      {availableLoginMethods.includes('microsoft_com') && renderSSOButton('microsoft', MicrosoftIcon)}
      {availableLoginMethods.includes('saml') && renderSSOButton('saml', AccountCircleIcon)}
    </Form>
  ) : null
}

const ResetForm = (props) => {
  const [isLoading, setIsLoading] = useSafeSetState(false)
  const translate = useTranslate()
  const notify = useNotify()
  const navigate = useNavigate()
  const classes = useFormStyles(props)
  const commonClasses = useCommonStyles()
  const authProvider = useAuthProvider()
  const location = useLocation()
  const params = new URLSearchParams(location.search)

  const submit = (values) => {
    setIsLoading(true)
    authProvider.resetEmail(values.email).finally(() => {
      notify(translate('mymove.login.resetEmailSent') + ' ' + values.email)
      navigate(LoginPage.path)
      setIsLoading(false)
    })
  }

  return (
    <Form
      onSubmit={submit}
      defaultValues={{ email: params.get('email') }}
      noValidate
      className={classes.form}
      disableInvalidFormNotification
    >
      <CardContent className={classes.inputsContainer}>
        <LoginEmailTextInput disabled={isLoading} />
      </CardContent>
      <CardActions>
        <Button variant="contained" type="submit" color="primary" disabled={isLoading} className={classes.button}>
          {isLoading && <CircularProgress className={classes.loadingIcon} size={18} thickness={2} />}
          {translate('mymove.login.sendResetLink')}
        </Button>
      </CardActions>
      <CardActions className={classes.footer}>
        <Typography component="span" variant="body2" color="primary">
          <Link to={LoginPage.path} className={commonClasses.link}>
            {translate('mymove.login.iKnowMyPassword')}
          </Link>
        </Typography>
      </CardActions>
    </Form>
  )
}

ResetForm.propTypes = {
  redirectTo: PropTypes.string,
}

export const LoginPage = () => {
  const checkAuth = useCheckAuth()
  const location = useLocation()
  const navigate = useNavigate()
  useDocumentTitle('Sign in')

  const [loginRedirectionPathName, setLoginRedirectionPathName] = useSafeSetState('/')
  useEffect(() => {
    checkAuth({}, false)
      .then(() => {
        // already authenticated, redirect to the home page
        navigate('/')
      })
      .catch(() => {
        // not authenticated, stay on the login page & store pathname for redirection when logging in
        if (location.state?.nextPathname) {
          setLoginRedirectionPathName(location.state.nextPathname)
        }
      })
  }, [checkAuth, navigate, location.state?.nextPathname, setLoginRedirectionPathName])

  return (
    <FormCard>
      <LoginForm loginRedirectionPathName={loginRedirectionPathName} />
    </FormCard>
  )
}
LoginPage.path = '/login'

export const ResetPage = () => {
  useDocumentTitle('Reset password')
  return (
    <FormCard>
      <ResetForm />
    </FormCard>
  )
}
ResetPage.path = '/reset-password'

export const RedirectPage = () => {
  const location = useLocation()
  useEffect(() => {
    const params = new URLSearchParams(location.search)
    window.location.replace(params.get('path'))
  }, [location])
  useDocumentTitle('Redirection...')
  return null
}
RedirectPage.path = '/redirect'
