import { Card } from '@mui/material'
import {
  RecordContextProvider,
  ResourceContextProvider,
  SimpleShowLayout,
  Title,
  TopToolbar,
  useGetOne,
} from 'react-admin'
import { useTranslate } from 'ra-core'

import { AdvancedEditInDialogButton } from '../common/edit'
import { useShowStyles } from '../common/show'
import AdvancedArrayField from '../../components/AdvancedArrayField'
import AdvancedBooleanField from '../../components/AdvancedBooleanField'
import AdvancedTextField from '../../components/AdvancedTextField'
import PeriodField from '../../components/PeriodField'
import ShowCardHeader from '../../components/ShowCardHeader'
import UnitField from '../../components/UnitField'
import {
  ACCOUNT_BILLING_TYPES_SOURCE,
  ACCOUNT_DEFAULT_BILLING_TYPE_SOURCE,
  ACCOUNT_FREE_BILLING_TYPE_REQUIRES_JUSTIFICATION_SOURCE,
} from '../../config/accounts'
import { BOOKING_BILLING_TYPE_AUTO, BOOKING_BILLING_TYPE_FREE, BOOKING_BILLING_TYPES } from '../../config/bookings'
import { hasBillingType } from '../../utils'

import AccountFormLayout, { transformValues } from './form'
import config, { ACCOUNT_BASE_PATH } from './config'

export const AccountShowPage = () => {
  const translate = useTranslate()
  const { data: account } = useGetOne(config.name, { id: null })
  const showClasses = useShowStyles()

  const commonBooleanFieldProps = {
    trueIcon: null,
    falseIcon: null,
  }

  return account ? (
    <ResourceContextProvider value="accounts">
      <RecordContextProvider value={account}>
        <Title title={translate('resources.accounts.name', 1)} />
        <TopToolbar>
          <AdvancedEditInDialogButton transform={transformValues}>
            <AccountFormLayout />
          </AdvancedEditInDialogButton>
        </TopToolbar>
        <Card>
          <ShowCardHeader />
          <SimpleShowLayout className={showClasses.fieldContainer}>
            <AdvancedTextField source="name" />
            {hasBillingType(account[ACCOUNT_BILLING_TYPES_SOURCE], BOOKING_BILLING_TYPE_AUTO) && (
              <PeriodField
                startedOnSource="pro_hours_start"
                endedOnSource="pro_hours_end"
                label="resources.accounts.fields.workingHours"
              />
            )}
          </SimpleShowLayout>
          <SimpleShowLayout className={showClasses.fieldContainer}>
            <AdvancedArrayField
              source={ACCOUNT_BILLING_TYPES_SOURCE}
              map={BOOKING_BILLING_TYPES}
              label={translate('resources.accounts.fields.billing_types', account[ACCOUNT_BILLING_TYPES_SOURCE].length)}
            />
            <AdvancedTextField source={ACCOUNT_DEFAULT_BILLING_TYPE_SOURCE} map={BOOKING_BILLING_TYPES} />
            {hasBillingType(account[ACCOUNT_BILLING_TYPES_SOURCE], BOOKING_BILLING_TYPE_FREE) &&
              [
                <AdvancedBooleanField
                  {...commonBooleanFieldProps}
                  source={ACCOUNT_FREE_BILLING_TYPE_REQUIRES_JUSTIFICATION_SOURCE}
                />,
                <AdvancedBooleanField {...commonBooleanFieldProps} source="end_forgotten_bookings_after_1_hour" />,
              ].map((e, i) => ({ ...e, key: i }))}
            <AdvancedBooleanField {...commonBooleanFieldProps} source="requires_payment" />
          </SimpleShowLayout>
          <SimpleShowLayout className={showClasses.fieldContainer}>
            <AdvancedBooleanField {...commonBooleanFieldProps} source="is_free_to_join" />
            <AdvancedBooleanField {...commonBooleanFieldProps} source="display_step_duration_slider" />
            {!account.display_step_duration_slider && (
              <UnitField
                source="booking_default_duration"
                unit="mymove.units.time.hours"
                formatValue={(v) => v / 60}
                shouldFormatUnit
              />
            )}
            <UnitField
              source="booking_maximum_duration"
              unit="mymove.units.time.days"
              formatValue={(v) => v / (24 * 60)}
              shouldFormatUnit
            />
          </SimpleShowLayout>
        </Card>
      </RecordContextProvider>
    </ResourceContextProvider>
  ) : (
    <Title title={translate('resources.accounts.name', 1)} />
  )
}

AccountShowPage.path = ACCOUNT_BASE_PATH
