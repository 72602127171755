import safeGet from 'lodash/get'

import { OPS_USER_ROLES } from '../config/permissions'
import { configs as resources } from '../resources'

import { mockPermissions } from './mocks'

const camelCase = (name) => {
  const callback = (match, group1) => group1.toUpperCase()
  return name.replace(/-([a-z])/g, callback)
}

const uiPermissions = OPS_USER_ROLES.reduce(
  (rolesAcc, role) => ({
    ...rolesAcc,
    [role]: Object.entries(resources).reduce(
      (resourcesAcc, [name, resource]) => ({
        ...resourcesAcc,
        [name]: safeGet(resource, ['options', 'permissions', role]) || [],
      }),
      {},
    ),
  }),
  {},
)

export default (apiProvider) => {
  let cache = null

  const clear = () => (cache = null)

  const fetch = async () => {
    const { json } = await apiProvider('/profile')
    const { permissions, ...currentUser } = json
    const fullPermissions = {
      currentUser,
      resources: Object.fromEntries(
        Object.entries({ ...permissions, ...mockPermissions() }).map(([resourceName, resourceSystemPerms]) => [
          resourceName,
          [...resourceSystemPerms, ...safeGet(uiPermissions, [currentUser.role, camelCase(resourceName)], [])],
        ]),
      ),
    }
    cache = fullPermissions
    return cache
  }

  const get = async () => {
    let permissions = cache
    if (!permissions) {
      permissions = await fetch()
    }
    return permissions
  }

  return { clear, fetch, get }
}
