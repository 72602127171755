import { Box } from '@mui/material'
import { pick } from 'lodash'
import { useTranslate } from 'ra-core'
import { useEffect, useMemo, useRef, useState } from 'react'
import {
  ArrayInput,
  CREATE,
  UPDATE,
  FormDataConsumer,
  RadioButtonGroupInput,
  SimpleForm,
  SimpleFormIterator,
  required,
  useGetOne,
  useListContext,
  useRecordContext,
} from 'react-admin'
import { useFormContext, useWatch } from 'react-hook-form'
import { CreateDialog } from '@react-admin/ra-form-layout'

import AdvancedBooleanInput from '../../components/AdvancedBooleanInput'
import AdvancedDateTimeInput from '../../components/AdvancedDateTimeInput'
import BasicFormToolbar from '../../components/BasicFormToolbar'
import BookingJustificationInput from '../../components/BookingJustificationInput'
import {
  ACCOUNT_BILLING_SETTINGS_SOURCES,
  ACCOUNT_BILLING_TYPES_SOURCE,
  ACCOUNT_DEFAULT_BILLING_TYPE_SOURCE,
} from '../../config/accounts'
import {
  BOOKING_BILLING_SETTINGS_SOURCE,
  BOOKING_BILLING_TYPE_FREE,
  BOOKING_BILLING_TYPE_PAID,
  BOOKING_BILLING_TYPE_SOURCE,
  BOOKING_BILLING_TYPES,
  BOOKING_ERA_UPCOMING,
  BOOKING_MAKE_BOOKING_FREE_SOURCE,
} from '../../config/bookings'
import { COMMON_INPUT_WIDTH, useCommonStyles } from '../../config/theme'
import { BILLING_TYPES_OVERRIDE_SOURCE } from '../../config/users'
import { isBookingPaid } from '../../domain/bookings'
import { hasOnlyBillingType, isDisabled } from '../../utils'
import { getDateWithThirtyMinutesOffset, getRoundedNow, validateEndDateAfterStartDate } from '../../utils/dates'
import { useSmallScreen } from '../../utils/theme'
import accountConfig from '../accounts/config'
import { FormDivider } from '../common'
import { useEditResource } from '../common/edit'
import { SimpleFormIteratorAddButton } from '../common/forms'
import hubsConfig from '../hubs/config'
import HubReferenceInput from '../hubs/input'
import organisationsConfig from '../organisations/config'
import OrganisationReferenceInput from '../organisations/input'
import usersConfig from '../users/config'
import UserReferenceInput from '../users/input'
import vehiclesConfig from '../vehicles/config'
import VehicleReferenceInput from '../vehicles/input'
import vouchersConfig from '../vouchers/config'
import VoucherReferenceInput from '../vouchers/input'

import { useGetBookingsJustifications } from './hooks'

const getBillingTypeChoices = (billingTypes) => {
  const orderedBillingTypes = Object.keys(BOOKING_BILLING_TYPES).filter((type) => billingTypes.includes(type))
  const filteredBillingTypes = pick(BOOKING_BILLING_TYPES, orderedBillingTypes)
  return Object.entries(filteredBillingTypes).map(([id, name]) => ({ id, name }))
}

export const transformValues = ({
  additional_driver_user_ids,
  billing_settings,
  billing_type,
  has_voucher,
  make_booking_free,
  ...restValues
}) =>
  restValues
    ? {
        ...restValues,
        additional_driver_user_ids: additional_driver_user_ids
          ? additional_driver_user_ids.map(({ user_id }) => user_id)
          : [],
        [BOOKING_BILLING_TYPE_SOURCE]: make_booking_free ? BOOKING_BILLING_TYPE_FREE : billing_type,
      }
    : restValues

const BillingSettingsInjector = ({ accountBillingSettings, formType, record }) => {
  const { setValue } = useFormContext()
  const [isFormReady, setIsFormReady] = useState(false)
  const [billingSettings, billingType, userId] = useWatch({
    name: [BOOKING_BILLING_SETTINGS_SOURCE, BOOKING_BILLING_TYPE_SOURCE, 'user_id'],
  })
  const { data: userData, isLoading } = useGetOne('users', { id: userId }, { enabled: Boolean(userId) })

  useEffect(() => setIsFormReady(true), [])

  useEffect(() => {
    if (isFormReady) {
      // Handle the case (and stop) where a booking that has been made free is updated
      if (
        formType === UPDATE &&
        userData &&
        record[BOOKING_BILLING_TYPE_SOURCE] === BOOKING_BILLING_TYPE_FREE &&
        hasOnlyBillingType(
          userData[BILLING_TYPES_OVERRIDE_SOURCE] || accountBillingSettings[ACCOUNT_BILLING_TYPES_SOURCE],
          BOOKING_BILLING_TYPE_PAID,
        )
      ) {
        setValue(BOOKING_BILLING_SETTINGS_SOURCE, {
          [ACCOUNT_BILLING_TYPES_SOURCE]: [BOOKING_BILLING_TYPE_FREE],
          [ACCOUNT_DEFAULT_BILLING_TYPE_SOURCE]: BOOKING_BILLING_TYPE_FREE,
        })
        return
      }
      // Handle other cases
      if (!Boolean(userId)) {
        setValue(BOOKING_BILLING_SETTINGS_SOURCE, accountBillingSettings)
      } else if (userData && !isLoading) {
        const overrides = {}
        ACCOUNT_BILLING_SETTINGS_SOURCES.forEach((src) => {
          const overrideValue = userData[`${src}_override`]
          if (overrideValue !== null) {
            overrides[src] = overrideValue
          }
        })
        setValue(BOOKING_BILLING_SETTINGS_SOURCE, {
          ...accountBillingSettings,
          ...overrides,
          ...(record && { [ACCOUNT_DEFAULT_BILLING_TYPE_SOURCE]: record[BOOKING_BILLING_TYPE_SOURCE] }),
        })
      }
    }
  }, [isFormReady, userId, setValue, isLoading]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isFormReady && !billingSettings[ACCOUNT_BILLING_TYPES_SOURCE].includes(billingType)) {
      setValue(BOOKING_BILLING_TYPE_SOURCE, billingSettings[ACCOUNT_DEFAULT_BILLING_TYPE_SOURCE])
    }
  }, [isFormReady, JSON.stringify(billingSettings)]) // eslint-disable-line react-hooks/exhaustive-deps

  return null
}

const VoucherFormSection = ({ formType }) => {
  const { setValue } = useFormContext()
  const [makeBookingFree, hasVoucher, voucherId] = useWatch({
    name: [BOOKING_MAKE_BOOKING_FREE_SOURCE, 'has_voucher', 'voucher_id'],
  })

  const onToggle = (event) => {
    setValue('has_voucher', event.target.checked)
    if (!event.target.checked) {
      setValue(vouchersConfig.options.referenceKey, null)
    }
  }

  useEffect(() => {
    if (formType === CREATE && makeBookingFree) {
      setValue('has_voucher', false)
      setValue(vouchersConfig.options.referenceKey, null)
    }
  }, [setValue, formType, makeBookingFree])

  return (
    <>
      <AdvancedBooleanInput
        source="has_voucher"
        helperText={hasVoucher ? false : 'resources.bookings.forms.helperTexts.voucher_id'}
        readOnly={makeBookingFree}
        onChange={onToggle}
        defaultValue={Boolean(voucherId)}
        sx={{ width: 330 }}
      />
      {hasVoucher && <VoucherReferenceInput label="resources.vouchers.fields.code" readOnly={makeBookingFree} />}
    </>
  )
}

const AdditionalDriversArrayInput = () => {
  const translate = useTranslate()
  const [userId, additionalDriverUserIds] = useWatch({ name: ['user_id', 'additional_driver_user_ids'] })

  const maxAmountOfDrivers = 19
  const excludedDriverUserIds = additionalDriverUserIds?.map((element) => element?.user_id) || []
  const excludedUserIds = [userId, ...excludedDriverUserIds].filter(Boolean)

  return (
    <ArrayInput source="additional_driver_user_ids" helperText={false}>
      <SimpleFormIterator
        disableReordering
        addButton={<SimpleFormIteratorAddButton label="resources.bookings.forms.giveAccessToAnAdditionalUser" />}
        disableAdd={additionalDriverUserIds?.length >= maxAmountOfDrivers}
        TransitionProps={{ enter: false, exit: false }}
      >
        <UserReferenceInput label={translate('resources.bookings.fields.user_id')} excludedIds={excludedUserIds} />
      </SimpleFormIterator>
    </ArrayInput>
  )
}

const BookingFormLayout = ({ defaultValues, disabledInputsSources = [], onClose, type = UPDATE, ...props }) => {
  const bookingsJustifications = useGetBookingsJustifications()
  const { data: account } = useGetOne(accountConfig.name, { id: null })
  const { filterValues } = useListContext()

  const record = useRecordContext(props)
  const transformedRecord = useMemo(() => {
    if (type === UPDATE) {
      return {
        ...record,
        additional_driver_user_ids: record.additional_driver_user_ids
          ? record.additional_driver_user_ids.map((id) => ({ user_id: id }))
          : [],
      }
    }
    return record
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const isSmallScreen = useSmallScreen()
  const commonClasses = useCommonStyles()

  const now = useRef(getRoundedNow())
  const end = useRef(getDateWithThirtyMinutesOffset())

  const accountBillingSettings = account
    ? ACCOUNT_BILLING_SETTINGS_SOURCES.reduce((acc, src) => {
        acc[src] = account[src]
        return acc
      }, {})
    : {}

  defaultValues = {
    start_scheduled_on: now.current,
    end_scheduled_on: end.current,
    [BOOKING_BILLING_SETTINGS_SOURCE]: accountBillingSettings,
    ...filterValues,
    ...(filterValues?.organisation_id && filterValues?.hub_id ? {} : { vehicle_id: undefined }),
    ...defaultValues,
  }

  return Boolean(account) && Boolean(bookingsJustifications) ? (
    <SimpleForm
      toolbar={<BasicFormToolbar formType={type} onCancel={onClose} />}
      defaultValues={defaultValues}
      record={transformedRecord}
      mode="onBlur"
    >
      <Box>
        <OrganisationReferenceInput
          readOnly={type === UPDATE || isDisabled(disabledInputsSources, organisationsConfig.options.referenceKey)}
        />
        <HubReferenceInput
          readOnly={type === UPDATE || isDisabled(disabledInputsSources, hubsConfig.options.referenceKey)}
        />
        <FormDataConsumer>
          {({ formData }) => (
            <VehicleReferenceInput
              formType={type}
              readOnly={
                (type === UPDATE && formData.era !== BOOKING_ERA_UPCOMING) ||
                isDisabled(disabledInputsSources, vehiclesConfig.options.referenceKey)
              }
            />
          )}
        </FormDataConsumer>
        <UserReferenceInput
          readOnly={type === UPDATE || isDisabled(disabledInputsSources, usersConfig.options.referenceKey)}
        />
        <FormDivider />
        <FormDataConsumer>
          {({ formData }) => (
            <>
              <AdvancedDateTimeInput
                validate={required()}
                source="start_scheduled_on"
                minDate={now.current}
                readOnly={type === UPDATE && formData.era !== BOOKING_ERA_UPCOMING}
              />
              <AdvancedDateTimeInput
                validate={[
                  required(),
                  (value) => validateEndDateAfterStartDate(formData.start_scheduled_on, value, 30, 1),
                ]}
                source="end_scheduled_on"
                minDate={now.current}
              />
            </>
          )}
        </FormDataConsumer>
        <FormDivider />
        <BillingSettingsInjector
          accountBillingSettings={accountBillingSettings}
          formType={type}
          record={transformedRecord}
        />
        <FormDataConsumer>
          {({ formData }) => (
            <RadioButtonGroupInput
              source={BOOKING_BILLING_TYPE_SOURCE}
              validate={required()}
              choices={getBillingTypeChoices(formData[BOOKING_BILLING_SETTINGS_SOURCE][ACCOUNT_BILLING_TYPES_SOURCE])}
              style={{ width: COMMON_INPUT_WIDTH }}
              helperText={false}
            />
          )}
        </FormDataConsumer>
        <BookingJustificationInput source="justification" bookingsJustifications={bookingsJustifications} />
        <FormDataConsumer>
          {({ formData }) =>
            type === CREATE &&
            hasOnlyBillingType(
              formData[BOOKING_BILLING_SETTINGS_SOURCE][ACCOUNT_BILLING_TYPES_SOURCE],
              BOOKING_BILLING_TYPE_PAID,
            ) && (
              <>
                <FormDivider />
                <AdvancedBooleanInput
                  source={BOOKING_MAKE_BOOKING_FREE_SOURCE}
                  className={isSmallScreen ? null : commonClasses.doubleInput}
                  defaultValue={false}
                />
              </>
            )
          }
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData }) =>
            isBookingPaid(formData) && (
              <>
                <FormDivider />
                <VoucherFormSection formType={type} />
              </>
            )
          }
        </FormDataConsumer>
        <FormDivider />
        <AdditionalDriversArrayInput />
      </Box>
    </SimpleForm>
  ) : null
}

export const useCreateBooking = ({ disabledInputsSources }) => {
  const [createPopupState, setCreatePopupState] = useState({ isOpen: false, values: {} })
  const handleCreatePopupOpen = (values) => setCreatePopupState({ isOpen: true, values })
  const handleCreatePopupClose = () => setCreatePopupState({ isOpen: false, values: {} })
  const dialog = (
    <CreateDialog
      isOpen={createPopupState.isOpen}
      open={handleCreatePopupOpen}
      close={handleCreatePopupClose}
      transform={transformValues}
    >
      <BookingFormLayout
        type={CREATE}
        disabledInputsSources={disabledInputsSources}
        defaultValues={createPopupState.values}
        onClose={handleCreatePopupClose}
      />
    </CreateDialog>
  )
  return [handleCreatePopupOpen, dialog]
}
export const useEditBooking = () => useEditResource('bookings', <BookingFormLayout />, transformValues)

export default BookingFormLayout
