import * as React from 'react'
import { useRedirect } from 'react-admin'
import { IconButton } from '@mui/material'
import RedirectIcon from '@mui/icons-material/LaunchRounded'

import { useCommonStyles } from '../config/theme'

const ShowButton = ({ resource, id, hidden }) => {
  const commonClasses = useCommonStyles()
  const redirect = useRedirect()
  if (!id || hidden) {
    return null
  }
  return (
    <IconButton className={commonClasses.primaryColor} onClick={() => redirect('show', resource, id)} size="large">
      <RedirectIcon />
    </IconButton>
  )
}

export default ShowButton
