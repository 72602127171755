import { stringify } from 'query-string'
import { useTranslate } from 'ra-core'
import { forwardRef } from 'react'
import { useRecordContext, useResourceContext } from 'react-admin'
import { Link } from 'react-router-dom'
import { Button, ListItemIcon, MenuItem, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import { getResourceByName } from '../resources'

const useStyles = makeStyles((theme) => ({
  icon: { paddingRight: '0.25em' },
  menuIcon: { minWidth: theme.spacing(5) },
  link: {
    display: 'inline-flex',
    alignItems: 'center',
  },
}))

const useReferenceLink = ({ filterValues, reference, source, ...props }) => {
  const translate = useTranslate()
  const record = useRecordContext(props)
  const resource = useResourceContext()
  const sourceResource = getResourceByName(resource)
  const referenceResource = getResourceByName(reference)
  const genericLabel = `resources.${referenceResource.name}.name`
  let label
  let to
  if (source && record[source]) {
    label = translate(genericLabel, 1)
    to = { pathname: '/' + referenceResource.name + (record ? '/' + record[source] + '/show' : '') }
  } else {
    label = translate(genericLabel, 2)
    to = {
      pathname: '/' + referenceResource.name,
      search: stringify({
        filter: JSON.stringify({
          ...referenceResource.options.defaultFilterValues,
          ...filterValues,
          [sourceResource.options.referenceKey]: record ? record.id : null,
        }),
      }),
    }
  }
  return { to, label, ReferenceResourceIcon: referenceResource.icon }
}

const ReferenceLink = ({ filterValues, record, reference, source, variant }) => {
  const { to, label, ReferenceResourceIcon } = useReferenceLink({ filterValues, record, reference, source })
  const classes = useStyles()
  return (
    <Button
      color="primary"
      component={Link}
      to={to}
      className={classes.link}
      onClick={(e) => e.stopPropagation()}
      variant={variant}
    >
      {ReferenceResourceIcon && <ReferenceResourceIcon className={classes.icon} />}
      {label}
    </Button>
  )
}

export const ReferenceMenuItem = forwardRef(({ reference, source, filterValues }, ref) => {
  const { to, label, ReferenceResourceIcon } = useReferenceLink({ reference, source, filterValues })
  const classes = useStyles()
  return (
    <MenuItem component={Link} to={to} ref={ref}>
      <ListItemIcon className={classes.menuIcon}>
        {ReferenceResourceIcon && <ReferenceResourceIcon color="inherit" />}
      </ListItemIcon>
      <Typography color="inherit">{label}</Typography>
    </MenuItem>
  )
})

export default ReferenceLink
