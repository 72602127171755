import { Box, InputAdornment } from '@mui/material'
import { get } from 'lodash'
import { useEffect, useState } from 'react'
import {
  ArrayInput,
  FormDataConsumer,
  maxValue,
  minValue,
  number,
  NumberInput,
  RadioButtonGroupInput,
  required,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  UPDATE,
  useRecordContext,
} from 'react-admin'
import { useFormContext, useWatch } from 'react-hook-form'
import { useTranslate } from 'ra-core'

import OrganisationReferenceInput from '../organisations/input'
import AddressInputMain from '../../components/AddressInputMain'
import AdvancedBooleanInput from '../../components/AdvancedBooleanInput'
import AdvancedDateTimeInput, { DATETIME_INPUT_MODE_TIME } from '../../components/AdvancedDateTimeInput'
import BasicFormToolbar from '../../components/BasicFormToolbar'
import { FormDivider } from '../common'
import { FormSubTitle } from '../common/forms'
import {
  HUB_ADDRESS_FILLING_METHOD_AUTOCOMPLETE,
  HUB_ADDRESS_FILLING_METHOD_MANUAL,
  HUB_ADDRESS_FILLING_METHODS,
  PLACE_DETAILS_FIELD_FORMATTED_ADDRESS,
} from '../../config/addresses'
import {
  HUB_HAS_PICK_UP_AND_DROP_OFF_HOURS_SOURCE,
  HUB_PICK_UP_AND_DROP_OFF_HOURS_SOURCE,
  HUB_PICK_UP_AND_DROP_OFF_HOURS_TYPE_CUSTOM,
  HUB_PICK_UP_AND_DROP_OFF_HOURS_TYPE_OPEN_ALL_DAY,
  HUB_PICK_UP_AND_DROP_OFF_HOURS_TYPES,
} from '../../config/hubs'
import { useCommonStyles } from '../../config/theme'
import {
  formatHubPickUpAndDropOffHoursWeekDayParams,
  getHubPickUpAndDropOffHoursWeekDayParams,
} from '../../domain/hubs'
import { getWeekDayFromIndex, validateEndTimeAfterStartTime } from '../../utils/dates'
import { useSmallScreen } from '../../utils/theme'
import { getMissingFieldsFromObject, validateAddress } from '../../utils/validators'

export const hubPickUpAndDropOffHoursTypeChoices = Object.entries(HUB_PICK_UP_AND_DROP_OFF_HOURS_TYPES).map(
  ([k, v]) => ({ id: k, name: v }),
)

export const validateLatitude = [
  required(),
  number('mymove.validation.number.invalid'),
  minValue(-90, 'mymove.validation.coordinates.latitude.notInRange'),
  maxValue(90, 'mymove.validation.coordinates.latitude.notInRange'),
]

export const validateLongitude = [
  required(),
  number('mymove.validation.number.invalid'),
  minValue(-180, 'mymove.validation.coordinates.longitude.notInRange'),
  maxValue(180, 'mymove.validation.coordinates.longitude.notInRange'),
]

export const validateGeofencing = [
  required(),
  number('mymove.validation.number.invalid'),
  minValue(0, 'mymove.validation.number.notNegative'),
]

export const transformValues = ({
  address,
  address_filling_method,
  has_pick_up_and_drop_off_hours,
  pick_up_and_drop_off_hours,
  ...restValues
}) => {
  if (address_filling_method === HUB_ADDRESS_FILLING_METHOD_MANUAL) {
    // We only want the formatted_address key in this case
    Object.keys(address).forEach((key) => {
      if (key !== PLACE_DETAILS_FIELD_FORMATTED_ADDRESS) {
        address[key] = null
      }
    })
  }
  return restValues
    ? {
        ...restValues,
        address,
        [HUB_PICK_UP_AND_DROP_OFF_HOURS_SOURCE]: has_pick_up_and_drop_off_hours
          ? pick_up_and_drop_off_hours.map((weekDay) => formatHubPickUpAndDropOffHoursWeekDayParams(weekDay))
          : null,
      }
    : restValues
}

export const HubAddressFillingMethodInput = ({ source, addressSource, positionSource }) => {
  const { setValue } = useFormContext()
  return (
    <RadioButtonGroupInput
      row={false}
      label={false}
      source={source}
      validate={required()}
      choices={HUB_ADDRESS_FILLING_METHODS}
      helperText="resources.hubs.forms.addressFillingMethod.helperText"
      onChange={(newValue) => {
        if (newValue === HUB_ADDRESS_FILLING_METHOD_AUTOCOMPLETE) {
          setValue(addressSource, null)
          setValue(positionSource, null)
        }
      }}
    />
  )
}

export const HubAddressInput = ({ target }) => {
  const translate = useTranslate()
  const isSmallScreen = useSmallScreen()
  const commonClasses = useCommonStyles()
  const sourcePrefix = target ? `${target}.` : ''
  const label = translate('resources.hubs.fields.address')

  return (
    <FormDataConsumer>
      {({ formData }) =>
        (formData[target] ?? formData).address_filling_method === HUB_ADDRESS_FILLING_METHOD_AUTOCOMPLETE ? (
          <AddressInputMain
            source={`${sourcePrefix}address`}
            label={label}
            latLngSources={[`${sourcePrefix}position.latitude`, `${sourcePrefix}position.longitude`]}
            validate={validateAddress()}
          />
        ) : (
          <TextInput
            source={`${sourcePrefix}address.${PLACE_DETAILS_FIELD_FORMATTED_ADDRESS}`}
            label={label}
            validate={required()}
            helperText="resources.hubs.forms.helperTexts.manual_address"
            className={isSmallScreen ? commonClasses.commonInput : commonClasses.doubleInput}
          />
        )
      }
    </FormDataConsumer>
  )
}

export const HubPickUpAndDropOffHoursSection = ({ target }) => {
  const translate = useTranslate()
  const { setValue } = useFormContext()
  const [isFormReady, setIsFormReady] = useState(false)

  const sourcePrefix = target ? `${target}.` : ''
  const hasPickUpAndDropOffHoursSourceWithPrefix = sourcePrefix + HUB_HAS_PICK_UP_AND_DROP_OFF_HOURS_SOURCE
  const pickUpAndDropOffHoursSourceWithPrefix = sourcePrefix + HUB_PICK_UP_AND_DROP_OFF_HOURS_SOURCE
  const [hasPickUpAndDropOffHours, pickUpAndDropOffHours] = useWatch({
    name: [hasPickUpAndDropOffHoursSourceWithPrefix, pickUpAndDropOffHoursSourceWithPrefix],
  })

  useEffect(() => setIsFormReady(true), [])
  useEffect(() => {
    if (isFormReady) {
      if (hasPickUpAndDropOffHours === false && pickUpAndDropOffHours == null) {
        setValue(
          pickUpAndDropOffHoursSourceWithPrefix,
          Array.from({ length: 7 }, () => ({ type: HUB_PICK_UP_AND_DROP_OFF_HOURS_TYPE_OPEN_ALL_DAY })),
        )
      } else if (hasPickUpAndDropOffHours && pickUpAndDropOffHours) {
        setValue(
          pickUpAndDropOffHoursSourceWithPrefix,
          pickUpAndDropOffHours.map((weekDay) =>
            getHubPickUpAndDropOffHoursWeekDayParams(weekDay.start_time, weekDay.end_time),
          ),
        )
      }
    }
  }, [isFormReady]) // eslint-disable-line react-hooks/exhaustive-deps

  return hasPickUpAndDropOffHours ? (
    <>
      <FormDivider />
      <ArrayInput
        source={pickUpAndDropOffHoursSourceWithPrefix}
        helperText={false}
        label={false}
        sx={{ margin: '6px 0 0 0' }}
      >
        <SimpleFormIterator
          disableAdd
          disableRemove
          disableReordering
          getItemLabel={(index) => translate(getWeekDayFromIndex(index))}
          sx={{
            '& .RaSimpleFormIterator-index': { width: 90 },
            '& .RaSimpleFormIterator-line': { marginTop: 3, paddingLeft: 2 },
          }}
        >
          <SelectInput
            source="type"
            label="resources.hubs.pickUpAndDropOffHours.openingTypes.name"
            choices={hubPickUpAndDropOffHoursTypeChoices}
            validate={required()}
          />
          <FormDataConsumer>
            {({ scopedFormData, getSource }) =>
              scopedFormData.type === HUB_PICK_UP_AND_DROP_OFF_HOURS_TYPE_CUSTOM && (
                <AdvancedDateTimeInput
                  validate={required()}
                  source={getSource('start_time')}
                  label="resources.hubs.pickUpAndDropOffHours.startTime"
                  mode={DATETIME_INPUT_MODE_TIME}
                />
              )
            }
          </FormDataConsumer>
          <FormDataConsumer>
            {({ scopedFormData, getSource }) =>
              scopedFormData.type === HUB_PICK_UP_AND_DROP_OFF_HOURS_TYPE_CUSTOM && (
                <AdvancedDateTimeInput
                  validate={[required(), (value) => validateEndTimeAfterStartTime(scopedFormData.start_time, value)]}
                  readOnly={!Boolean(scopedFormData.start_time)}
                  source={getSource('end_time')}
                  label="resources.hubs.pickUpAndDropOffHours.endTime"
                  mode={DATETIME_INPUT_MODE_TIME}
                />
              )
            }
          </FormDataConsumer>
        </SimpleFormIterator>
      </ArrayInput>
    </>
  ) : null
}

const HubFormLayout = ({ type = UPDATE, organisationId, onClose }) => {
  const isSmallScreen = useSmallScreen()
  const commonClasses = useCommonStyles()
  const record = useRecordContext()
  const translate = useTranslate()

  const defaultValues = {
    organisation_id: organisationId,
    address_filling_method: HUB_ADDRESS_FILLING_METHOD_AUTOCOMPLETE,
    [HUB_HAS_PICK_UP_AND_DROP_OFF_HOURS_SOURCE]: false,
  }

  if (type === UPDATE) {
    const numberOfAddressMissingFields = getMissingFieldsFromObject(record.address).length
    defaultValues.address_filling_method =
      numberOfAddressMissingFields > 0 ? HUB_ADDRESS_FILLING_METHOD_MANUAL : HUB_ADDRESS_FILLING_METHOD_AUTOCOMPLETE
    defaultValues[HUB_HAS_PICK_UP_AND_DROP_OFF_HOURS_SOURCE] = Boolean(record[HUB_PICK_UP_AND_DROP_OFF_HOURS_SOURCE])
  }

  return (
    <SimpleForm
      toolbar={<BasicFormToolbar formType={type} onCancel={onClose} />}
      defaultValues={defaultValues}
      mode="onBlur"
    >
      <Box>
        <FormSubTitle text="identification" isFirstChild />
        <OrganisationReferenceInput readOnly />
        <TextInput source="name" validate={required()} />
        <FormSubTitle text="geolocation" />
        <HubAddressFillingMethodInput
          source="address_filling_method"
          addressSource="address"
          positionSource="position"
        />
        <HubAddressInput />
        <FormDataConsumer>
          {({ formData }) => (
            <NumberInput
              source="position.latitude"
              validate={validateLatitude}
              min={-90}
              max={90}
              readOnly={formData.address_filling_method === HUB_ADDRESS_FILLING_METHOD_AUTOCOMPLETE}
              InputProps={{ endAdornment: <InputAdornment position="end">°</InputAdornment> }}
            />
          )}
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData }) => (
            <NumberInput
              source="position.longitude"
              validate={validateLongitude}
              min={-180}
              max={180}
              readOnly={formData.address_filling_method === HUB_ADDRESS_FILLING_METHOD_AUTOCOMPLETE}
              InputProps={{ endAdornment: <InputAdornment position="end">°</InputAdornment> }}
            />
          )}
        </FormDataConsumer>
        <FormDivider />
        <FormDataConsumer>
          {({ formData }) => (
            <NumberInput
              source="geofencing"
              validate={validateGeofencing}
              min={0}
              helperText="resources.hubs.forms.helperTexts.geofencing"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {translate('mymove.units.distance.meters', get(formData, 'geofencing') || 1)}
                  </InputAdornment>
                ),
              }}
            />
          )}
        </FormDataConsumer>
        <FormSubTitle text="schedules" />
        <AdvancedBooleanInput
          source={HUB_HAS_PICK_UP_AND_DROP_OFF_HOURS_SOURCE}
          className={isSmallScreen ? null : commonClasses.doubleInput}
          sx={{ marginBottom: 2 }}
        />
        <HubPickUpAndDropOffHoursSection />
      </Box>
    </SimpleForm>
  )
}

export default HubFormLayout
