import { IconButton, Portal, Tooltip } from '@mui/material'
import HelpIcon from '@mui/icons-material/HelpOutlineRounded'
import { makeStyles } from '@mui/styles'
import { useTranslate } from 'ra-core'
import { useEffect, useRef } from 'react'
import { Layout, useLocaleState } from 'react-admin'
import { useTheme } from '@mui/styles'

import env from '../config/env'

import CustomAppBar from './AppBar'
import { LANGUAGE_CODE_CACHE_KEY } from './LanguageSwitch'
import CustomMenu from './Menu'

const useStyles = makeStyles({
  helpButton: {
    width: 48,
    height: 48,
    margin: 8,
  },
  helpButtonIcon: {
    width: 36,
    height: 36,
  },
})

export default (props) => {
  const helpRootEl = useRef(document.getElementById('help-root'))
  const translate = useTranslate()
  const classes = useStyles()
  const theme = useTheme()

  const [, setLocale] = useLocaleState()
  useEffect(() => {
    const languageCode = window.localStorage.getItem(LANGUAGE_CODE_CACHE_KEY)
    if (languageCode) setLocale(languageCode)
  }, [setLocale])

  return (
    <>
      <Layout {...props} theme={theme} appBar={CustomAppBar} menu={CustomMenu} />
      <Portal container={helpRootEl.current}>
        <Tooltip title={translate('mymove.dashboardUserGuide')}>
          <IconButton
            className={classes.helpButton}
            disableRipple
            onClick={() => window.open(env.DASHBOARD_USER_GUIDE_URL, '_blank')}
            size="large"
          >
            <HelpIcon className={classes.helpButtonIcon} />
          </IconButton>
        </Tooltip>
      </Portal>
    </>
  )
}
