import Card from '@mui/material/Card'
import makeStyles from '@mui/styles/makeStyles'
import { useMemo } from 'react'
import {
  ExportButton,
  Pagination,
  ReferenceManyField,
  TopToolbar,
  useListContext,
  useResourceContext,
} from 'react-admin'
import { CardHeader } from '@mui/material'
import classnames from 'classnames'

import { SYSTEM_PERMISSION_CREATE, SYSTEM_PERMISSION_READ } from '../../config/permissions'
import { useResourcePermissions } from '../../domain/permissions'
import { useCommonStyles } from '../../config/theme'
import { useSmallScreen } from '../../utils/theme'

import { CardTitle } from './index'

export const useListStyles = makeStyles((theme) => ({
  card: {
    marginTop: theme.spacing(3),
  },
  titleContainer: {
    [theme.breakpoints.up('md')]: {
      borderBottom: `solid 1px ${theme.palette.divider}`,
    },
  },
}))

export const ListReference = ({ children, classes: classesOverride = {}, showPagination = true, ...props }) => {
  const classes = useListStyles({ classes: classesOverride })
  const [hasRead] = useResourcePermissions(props.reference, SYSTEM_PERMISSION_READ)
  return hasRead ? (
    <Card className={classes.card}>
      <ReferenceManyField {...props} perPage={10} pagination={showPagination ? <Pagination /> : null}>
        {children}
      </ReferenceManyField>
    </Card>
  ) : null
}

export const ListActions = ({ hasExport = false, children }) => {
  const { resource, sort, filterValues, displayedFilters, selectedIds, total } = useListContext()
  const [hasCreate] = useResourcePermissions(resource, SYSTEM_PERMISSION_CREATE)
  return useMemo(
    () => (
      <TopToolbar>
        {children}
        {hasExport && (
          <ExportButton resource={`${resource}/export`} sort={sort} filterValues={filterValues} maxResults={10000} />
        )}
      </TopToolbar>
    ),
    [resource, sort, hasCreate, displayedFilters, filterValues, selectedIds, total, children], // eslint-disable-line react-hooks/exhaustive-deps
  )
}

export const ListCardTitle = () => {
  const resource = useResourceContext()
  return <CardTitle text={`resources.${resource}.name`} args={2} />
}

export const GenericListLayout = ({
  action = null,
  compactListLayout,
  regularListLayout,
  editPopupDialog = null,
  title = <ListCardTitle />,
}) => {
  const listClasses = useListStyles()
  const commonClasses = useCommonStyles()
  const isSmallScreen = useSmallScreen()

  return (
    <>
      <CardHeader
        title={title}
        className={classnames(commonClasses.titleContainer, listClasses.titleContainer)}
        sx={{ display: 'flex', alignItems: 'center', minHeight: 40 }}
        action={action}
      />
      {isSmallScreen ? compactListLayout : regularListLayout}
      {editPopupDialog}
    </>
  )
}
