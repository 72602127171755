import {
  GoogleAuthProvider,
  OAuthProvider,
  onAuthStateChanged,
  SAMLAuthProvider,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
} from 'firebase/auth'
import { capitalize } from 'lodash'
import * as Sentry from '@sentry/react'

import { getUserFullName } from '../components/UserFullNameField'

export default (firebaseClient, accountsProvider, apiClient, permissionsProvider, history, publicRoutes = []) => {
  const needsAuthentication = () => !publicRoutes.includes(history.location.pathname)

  const onAuthLoad = () =>
    new Promise((resolve) => {
      onAuthStateChanged(firebaseClient.auth, resolve)
    })

  const signInWithProvider = async (provider) => {
    await signInWithPopup(firebaseClient.auth, provider)
  }

  return {
    login: async ({ username, password, loginType }) => {
      await signOut(firebaseClient.auth)
      try {
        switch (loginType) {
          case 'microsoft':
            await signInWithProvider(new OAuthProvider('microsoft.com'))
            break
          case 'google':
            await signInWithProvider(new GoogleAuthProvider())
            break
          case 'saml':
            await signInWithProvider(new SAMLAuthProvider('saml.default'))
            break
          default:
            await signInWithEmailAndPassword(firebaseClient.auth, username, password)
        }
        await permissionsProvider.fetch()
      } catch (error) {
        let message = `Login error with ${loginType ? capitalize(loginType) : 'email/password'}: ${error.message}`

        if (error.code === 'auth/invalid-login-credentials') {
          message = 'Invalid credentials. Please check your email and password.'
        }
        Sentry.captureException(error)
        throw new Error(message)
      }
    },
    logout: async () => {
      accountsProvider.clear()
      permissionsProvider.clear()
      await signOut(firebaseClient.auth)
    },
    checkError: async (error) => {
      const { status } = error
      if (status === 401) {
        await signOut(firebaseClient.auth)
        throw new Error('Invalid credentials')
      }
    },
    checkAuth: async () => {
      await onAuthLoad()
      if (needsAuthentication() && !firebaseClient.auth.currentUser) {
        throw new Error('Please log in')
      }
    },
    resetEmail: async (email) => {
      return sendPasswordResetEmail(firebaseClient.auth, email, { url: window.location.origin })
    },
    getIdentity: async () => {
      const { currentUser } = await permissionsProvider.get()
      return { ...currentUser, fullName: getUserFullName(currentUser) }
    },
    getPermissions: () => {
      return needsAuthentication() ? permissionsProvider.get() : null
    },
  }
}
