import { Box } from '@mui/material'
import { required, SelectInput, SimpleForm, TextInput, UPDATE, useGetIdentity } from 'react-admin'
import { useTranslate } from 'ra-core'

import BasicFormToolbar from '../../components/BasicFormToolbar'
import DefinitionList from '../../components/DefinitionList'
import { OPS_USER_ROLES_AND_PERMISSIONS, OPS_USER_ROLES_NAMES } from '../../config/permissions'
import { validateEmail } from '../../utils/validators'
import { FormDivider } from '../common'
import OrganisationReferenceInput from '../organisations/input'

const opsUserRoleChoices = Object.entries(OPS_USER_ROLES_NAMES).map(([k, v]) => ({ id: k, name: v }))

const OpsUserFormLayout = ({ type = UPDATE, onClose }) => {
  const { identity } = useGetIdentity()
  const translate = useTranslate()

  if (!identity) {
    return null
  }

  const currentUserRestrictedToOrganisationId = identity.restricted_to_organisation_id
  const defaultValues = type === UPDATE ? {} : { restricted_to_organisation_id: currentUserRestrictedToOrganisationId }

  return (
    <SimpleForm toolbar={<BasicFormToolbar formType={type} onCancel={onClose} />} defaultValues={defaultValues}>
      <Box>
        <TextInput source="first_name" validate={required()} />
        <TextInput source="last_name" validate={required()} />
        <TextInput source="email" validate={validateEmail()} type="email" />
        <OrganisationReferenceInput
          source="restricted_to_organisation_id"
          validate={null}
          readOnly={!!currentUserRestrictedToOrganisationId}
        />
        <FormDivider />
        <SelectInput
          source="role"
          choices={opsUserRoleChoices}
          validate={required()}
          helperText={type === UPDATE ? translate('resources.ops-users.forms.helperTexts.role') : null}
        />
        <DefinitionList items={OPS_USER_ROLES_AND_PERMISSIONS} />
      </Box>
    </SimpleForm>
  )
}

export default OpsUserFormLayout
