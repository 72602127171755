import { CREATE, Create, CreateActions, useNotify, useRefresh, useResourceContext } from 'react-admin'
import { useNavigate } from 'react-router-dom'
import { cloneElement } from 'react'
import { useTranslate } from 'ra-core'
import { Typography } from '@mui/material'
import { CreateInDialogButton } from '@react-admin/ra-form-layout'

import { useResourcePermissions } from '../../domain/permissions'
import { SYSTEM_PERMISSION_CREATE } from '../../config/permissions'

import { Title } from './show'

export default ({ titleComponent, formLayoutComponent, transformValues }) => {
  const navigate = useNavigate()
  const notify = useNotify()
  const refresh = useRefresh()
  const resource = useResourceContext()
  const translate = useTranslate()

  const onSuccess = () => {
    notify(`resources.${resource}.forms.create.success`)
    navigate(-1)
    refresh()
  }

  const onError = (error) => notify(error.message, { type: 'warning' })

  const createTitleComponent = cloneElement(titleComponent ?? Title, {
    details: translate(`resources.${resource}.forms.create.pageTitle`),
  })
  const createFormLayoutComponent = cloneElement(formLayoutComponent, { type: CREATE })

  return (
    <Create
      title={createTitleComponent}
      actions={<CreateActions />}
      mutationOptions={{ onSuccess, onError }}
      transform={transformValues}
    >
      {createFormLayoutComponent}
    </Create>
  )
}

export const AdvancedCreateInDialogButton = ({ title, children, sx, ...props }) => {
  const translate = useTranslate()
  const resource = useResourceContext()
  const hasCreate = useResourcePermissions(resource, SYSTEM_PERMISSION_CREATE)

  title = title || `resources.${resource}.forms.create.title`

  return (
    hasCreate && (
      <CreateInDialogButton
        sx={{ '& .MuiDialogTitle-root': { borderBottom: '1px solid', borderColor: 'divider' }, ...sx }}
        title={<Typography variant="h6">{translate(title)}</Typography>}
        mutationOptions={{ mutationMode: 'pessimistic' }}
        {...props}
      >
        {cloneElement(children, { type: CREATE })}
      </CreateInDialogButton>
    )
  )
}
