import { Card, CardContent, Grid, IconButton, Tooltip, Typography } from '@mui/material'
import DownloadIcon from '@mui/icons-material/GetApp'
import classnames from 'classnames'
import countries from 'i18n-iso-countries'
import { get, isEmpty } from 'lodash'
import { FunctionField, Loading, SimpleShowLayout, Title, TopToolbar, useGetIdentity } from 'react-admin'

import config, { PAYOUT_BASE_PATH, PAYOUT_TITLE } from '../config'
import { useGetPayoutReports, useGetPayoutSellerAccount, useGetPayoutSettings, useGetPayoutSetupStatus } from '../hooks'
import { usePayoutCommonStyles, usePayoutIconColorStyles, usePayoutShowStyles } from '../styles'
import { CardTitle } from '../../common'
import { useShowStyles } from '../../common/show'
import AdvancedTextField from '../../../components/AdvancedTextField'
import ShowCardHeader from '../../../components/ShowCardHeader'
import {
  PAYOUT_BANK_ACCOUNT_STATUSES_COLORS,
  PAYOUT_BANK_ACCOUNT_STATUSES_ICONS,
  PAYOUT_BANK_ACCOUNT_STATUSES_LABELS,
  PAYOUT_REPORT_STATUS_GENERATED,
  PAYOUT_REPORT_STATUSES_COLORS,
  PAYOUT_REPORT_STATUSES_ICONS,
  PAYOUT_REPORT_STATUSES_LABELS,
  PAYOUT_SETUP_STATUS_COMPLETED,
} from '../../../config/payout'
import { SYSTEM_PERMISSION_READ } from '../../../config/permissions'
import { useCommonStyles } from '../../../config/theme'
import { useResourcePermissions } from '../../../domain/permissions'
import { formatDate, formatDateTime, parseDateAsDateTime } from '../../../utils/dates'
import { useSmallScreen } from '../../../utils/theme'

countries.registerLocale(require('i18n-iso-countries/langs/en.json'))

const LegalEntityAddressField = (props) => {
  const commonClasses = useCommonStyles()
  return (
    <FunctionField
      {...props}
      className={commonClasses.fullWidth}
      render={(record, source) => {
        const address = get(record, source)
        return `${address?.street} ${address?.houseNumber}, ${address?.zipcode} ${address?.city}, ${countries.getName(
          address?.country,
          'en',
        )}`
      }}
    />
  )
}
LegalEntityAddressField.defaultProps = {
  source: 'legalEntity.address',
  label: 'Address',
}

const SetupCompletedView = () => {
  const sellerAccount = useGetPayoutSellerAccount()
  const settings = useGetPayoutSettings()
  const reports = useGetPayoutReports()

  const { identity } = useGetIdentity()
  const isSmallScreen = useSmallScreen()
  const commonClasses = useCommonStyles()
  const showClasses = useShowStyles()
  const payoutCommonClasses = usePayoutCommonStyles()
  const payoutShowClasses = usePayoutShowStyles({ isSmallScreen })
  const payoutIconColorClasses = usePayoutIconColorStyles()

  if (!sellerAccount || !settings || !identity) {
    return <Loading className={payoutCommonClasses.loadingContainer} />
  }

  return (
    <>
      <Card className={payoutShowClasses.card}>
        <ShowCardHeader title={PAYOUT_TITLE} />
      </Card>
      <Grid container direction={isSmallScreen ? 'column-reverse' : 'row'} spacing={1}>
        <Grid item md={8}>
          <Card className={payoutShowClasses.card}>
            <CardContent className={showClasses.subTitleContainer}>
              <CardTitle text="Bank accounts" size={16} />
            </CardContent>
            <CardContent className={commonClasses.borderTop}>
              {isEmpty(sellerAccount.externalAccounts) ? (
                <Typography variant="body2">No bank account</Typography>
              ) : (
                sellerAccount.externalAccounts
                  .sort((a, b) => a.weight - b.weight)
                  .map((account, index) => {
                    const isPrimary = index === 0
                    const accountStatus = account.kycStatus.toUpperCase()
                    const StatusIcon = PAYOUT_BANK_ACCOUNT_STATUSES_ICONS[accountStatus]
                    return (
                      <div
                        key={account.code}
                        className={classnames(
                          commonClasses.flexRowCenterContainer,
                          isPrimary ? commonClasses.solidBorder : commonClasses.dashedBorder,
                          payoutShowClasses.bankAccountContainer,
                          payoutShowClasses.listItemContainer,
                        )}
                      >
                        <div className={classnames(commonClasses.flexRowCenterContainer, commonClasses.halfWidth)}>
                          <Tooltip title={PAYOUT_BANK_ACCOUNT_STATUSES_LABELS[accountStatus]} placement="right">
                            <StatusIcon
                              className={classnames(
                                payoutShowClasses.statusIcon,
                                payoutIconColorClasses[PAYOUT_BANK_ACCOUNT_STATUSES_COLORS[accountStatus]],
                              )}
                            />
                          </Tooltip>
                          <Typography variant="body2">
                            {account.accountNumber
                              .replace(/[^\dA-Z]/g, '')
                              .replace(/(.{4})/g, '$1 ')
                              .trim()}
                          </Typography>
                        </div>
                        <div
                          className={classnames(
                            commonClasses.flexRowCenterContainer,
                            commonClasses.halfWidth,
                            payoutShowClasses.listItemRightContainer,
                          )}
                        >
                          <Typography variant="body2" className={payoutShowClasses.bankAccountDisplayName}>
                            {account.displayName}
                          </Typography>
                        </div>
                      </div>
                    )
                  })
              )}
            </CardContent>
          </Card>
          <Card>
            <CardContent className={showClasses.subTitleContainer}>
              <CardTitle text="Reports" size={16} />
            </CardContent>
            <CardContent className={commonClasses.borderTop}>
              {isEmpty(reports) ? (
                <Typography variant="body2">No reports</Typography>
              ) : (
                reports
                  .sort((a, b) => parseDateAsDateTime(b.createdAt) - parseDateAsDateTime(a.createdAt))
                  .map((report) => {
                    const reportStatus = report.status.toUpperCase()
                    const isGenerated = reportStatus === PAYOUT_REPORT_STATUS_GENERATED
                    const StatusIcon = PAYOUT_REPORT_STATUSES_ICONS[reportStatus]
                    return (
                      <div
                        key={report.id}
                        className={classnames(
                          commonClasses.flexRowCenterContainer,
                          commonClasses.solidBorder,
                          payoutShowClasses.listItemContainer,
                        )}
                      >
                        <div
                          className={classnames(
                            commonClasses.flexRowCenterContainer,
                            isSmallScreen ? commonClasses.spacer : commonClasses.halfWidth,
                          )}
                        >
                          <Tooltip title={PAYOUT_REPORT_STATUSES_LABELS[reportStatus]} placement="right">
                            <StatusIcon
                              className={classnames(
                                payoutShowClasses.statusIcon,
                                payoutIconColorClasses[PAYOUT_REPORT_STATUSES_COLORS[reportStatus]],
                              )}
                            />
                          </Tooltip>
                          <Typography variant="body2">
                            {`New splits until ${
                              Boolean(report.toDate) && report.toDate !== 'null' ? formatDate(report.toDate) : 'n/a'
                            }`}
                          </Typography>
                        </div>
                        <div
                          className={classnames(
                            commonClasses.flexRowCenterContainer,
                            isSmallScreen ? null : commonClasses.halfWidth,
                            payoutShowClasses.listItemRightContainer,
                          )}
                        >
                          <Typography variant="body2">
                            {isSmallScreen
                              ? null
                              : Boolean(report.createdAt) && report.createdAt !== 'null'
                              ? `Created on ${formatDateTime(report.createdAt)}`
                              : 'No creation date'}
                          </Typography>
                          <Tooltip title="Download report" placement="left">
                            <IconButton
                              className={commonClasses.primaryColor}
                              disabled={!isGenerated || report.url === 'null'}
                              href={report.url}
                              size="large"
                            >
                              <DownloadIcon />
                            </IconButton>
                          </Tooltip>
                        </div>
                      </div>
                    )
                  })
              )}
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={4}>
          <Card>
            <CardContent className={showClasses.subTitleContainer}>
              <CardTitle text="Company info" size={16} />
            </CardContent>
            <SimpleShowLayout record={sellerAccount} className={showClasses.fieldContainer}>
              <AdvancedTextField
                source="legalEntity.legalName"
                label="Legal name"
                className={commonClasses.fullWidth}
              />
              <AdvancedTextField
                source="legalEntity.registrationNumber"
                label="Registration number"
                className={commonClasses.fullWidth}
              />
              <AdvancedTextField
                source="legalEntity.taxNumber"
                label="VAT number"
                className={commonClasses.fullWidth}
              />
              <LegalEntityAddressField />
              <AdvancedTextField source="legalEntity.phone" label="Phone number" className={commonClasses.fullWidth} />
              <AdvancedTextField source="legalEntity.email" label="Email address" className={commonClasses.fullWidth} />
            </SimpleShowLayout>
          </Card>
        </Grid>
      </Grid>
    </>
  )
}

export const PayoutShowScreen = () => {
  const [hasRead] = useResourcePermissions(config.name, SYSTEM_PERMISSION_READ)
  const { status: payoutSetupStatus } = useGetPayoutSetupStatus()
  const isSmallScreen = useSmallScreen()

  return (
    <>
      <Title title={PAYOUT_TITLE} />
      {!isSmallScreen && <TopToolbar></TopToolbar>}
      {hasRead && payoutSetupStatus === PAYOUT_SETUP_STATUS_COMPLETED && <SetupCompletedView />}
    </>
  )
}

PayoutShowScreen.path = PAYOUT_BASE_PATH
