import { DeleteButton } from '../common/buttons'
import { AdvancedCreateInDialogButton } from '../common/create'

import VehicleUnavailabilityFormLayout, { transformValues } from './form'

export const DeleteVehicleUnavailabilityButton = () => (
  <DeleteButton
    confirmTitle="resources.maintenances.actions.delete.confirmTitle"
    successMessage="resources.maintenances.actions.delete.success"
  />
)

export const CreateVehicleUnavailabilityButton = (props) => (
  <AdvancedCreateInDialogButton label="resources.maintenances.actions.create.name" transform={transformValues}>
    <VehicleUnavailabilityFormLayout {...props} />
  </AdvancedCreateInDialogButton>
)
