import PlusIcon from '@mui/icons-material/AddCircleOutline'
import { Button } from '@mui/material'
import { FormDataConsumer, useResourceContext, useTranslate } from 'react-admin'

import { GenericCardTitle } from './show'

export const FORM_TYPE_FILTER = 'FILTER'

export const FormSubTitle = ({ text, condition, isFirstChild = false }) => (
  <FormDataConsumer>
    {({ formData }) =>
      !condition || condition(formData) ? (
        <GenericCardTitle
          text={text}
          sx={{
            padding: '16px',
            paddingTop: isFirstChild ? '6px' : '16px',
            marginTop: isFirstChild ? 0 : 2,
            marginBottom: '10px',
            borderTop: isFirstChild ? null : '1px solid',
            borderBottom: '1px solid',
            borderColor: 'divider',
          }}
        />
      ) : null
    }
  </FormDataConsumer>
)

export const SimpleFormIteratorAddButton = ({ label = 'ra.action.add', ...props }) => {
  const translate = useTranslate()
  return (
    <Button color="primary" size="small" startIcon={<PlusIcon />} {...props}>
      {translate(label)}
    </Button>
  )
}

export const useHelperText = (source) => {
  const translate = useTranslate()
  const resource = useResourceContext()

  const helperTextKey = `resources.${resource}.forms.helperTexts.${source}`
  const helperText = translate(helperTextKey)

  // Check if the translated text is the same as the key (which happens when no translation is found)
  return helperText !== helperTextKey ? helperText : null
}
