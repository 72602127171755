import { AdvancedCreateInDialogButton } from '../common/create'
import { useResourcePermissions } from '../../domain/permissions'
import { SYSTEM_PERMISSION_CREATE, SYSTEM_PERMISSION_UPDATE } from '../../config/permissions'
import { AdvancedEditInDialogButton } from '../common/edit'

import HubFormLayout, { transformValues } from './form'
import config from './config'

export const CreateHubButton = ({ organisationId }) => {
  const hasCreate = useResourcePermissions(config.name, SYSTEM_PERMISSION_CREATE)

  return (
    hasCreate && (
      <AdvancedCreateInDialogButton label="resources.hubs.actions.create.name" transform={transformValues}>
        <HubFormLayout organisationId={organisationId} />
      </AdvancedCreateInDialogButton>
    )
  )
}

export const EditHubButton = () => {
  const hasEdit = useResourcePermissions(config.name, SYSTEM_PERMISSION_UPDATE)

  return (
    hasEdit && (
      <AdvancedEditInDialogButton label="resources.hubs.actions.update.name" transform={transformValues}>
        <HubFormLayout />
      </AdvancedEditInDialogButton>
    )
  )
}
