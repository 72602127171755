import classnames from 'classnames'
import { isEmpty } from 'lodash'
import { useTranslate } from 'ra-core'
import { Children, cloneElement } from 'react'
import {
  ResourceContextProvider,
  useGetOne,
  useGetRecordRepresentation,
  usePermissions,
  useRecordContext,
  useResourceContext,
} from 'react-admin'
import ArrowIcon from '@mui/icons-material/ChevronRight'
import { Card, CardActions, CardContent, CardHeader, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import ActionButton from '../../components/ActionButton'
import ReferenceLink from '../../components/ReferenceLink'
import { SYSTEM_PERMISSION_ACTIONS, SYSTEM_PERMISSION_READ } from '../../config/permissions'
import { useCommonStyles } from '../../config/theme'
import { hasResourcePermission, useResourcePermissions } from '../../domain/permissions'
import { isAllowed } from '../../utils'
import { getResourceByName } from '..'

import { CardTitle } from '.'

export const useShowStyles = makeStyles((theme) => ({
  card: {
    marginTop: theme.spacing(3),
  },
  row: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  boldText: {
    fontWeight: 'bold',
  },
  unorderedList: {
    marginTop: 4,
    marginBottom: 0,
    paddingLeft: 18,
  },
  unorderedListItem: {
    marginBottom: 8,
    '&:last-child': {
      marginBottom: 0,
    },
    '& > p': {
      fontSize: 14,
      marginTop: 0,
      marginBottom: 4,
    },
  },
  expanded: {
    flex: 1,
  },
  actionsContainer: {
    borderTop: `solid 1px ${theme.palette.divider}`,
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    marginLeft: -theme.spacing(0.5),
    marginBottom: -theme.spacing(1),
    flexWrap: 'wrap',
    '& > *': {
      marginLeft: theme.spacing(0.5) + ' !important',
      marginRight: theme.spacing(0.5) + ' !important',
      marginBottom: '0 !important',
      '&:last-child': {
        marginRight: '0 !important',
      },
    },
  },
  actionsHelperText: {
    color: theme.palette.text.secondary,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  fieldContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    borderTop: `solid 1px ${theme.palette.divider}`,
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0 + ' !important',
    paddingBottom: theme.spacing(2) + ' !important',
    '&:last-child': {
      paddingBottom: theme.spacing(3) + ' !important',
    },
    '& > .ra-field': {
      margin: theme.spacing(2),
      marginBottom: 0,
    },
  },
  subTitleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 26,
  },
  italicHintText: {
    fontStyle: 'italic',
    color: theme.palette.text.hint,
  },
  disabledText: {
    textDecoration: 'line-through',
  },
  overrideText: {
    fontWeight: 500,
  },
  map: {
    padding: '0 !important',
    maxWidth: 480,
  },
  textFieldWithFullWidth: {
    width: '100%',
    margin: '0 !important',
  },
  textareaField: {
    whiteSpace: 'pre-wrap',
  },
}))

export const Title = () => {
  const commonClasses = useCommonStyles()
  const resource = useResourceContext()
  const record = useRecordContext()
  const recordRepresentation = useGetRecordRepresentation(resource)
  const translate = useTranslate()
  return (
    <span className={commonClasses.textWithLargeIcons}>
      {translate(`resources.${resource}.name`, 2)}
      <ArrowIcon titleAccess=" > " />
      {recordRepresentation(record)}
    </span>
  )
}

export const ShowCard = ({ children }) => {
  const classes = useShowStyles()
  return <Card className={classes.card}>{children}</Card>
}

const ShowReferenceBase = ({ children, errorText, errorTitle, ...props }) => {
  const { data: record, isError, isFetching } = useGetOne(props.resource, { id: props.id })
  const showClasses = useShowStyles()

  if ((isError || (!record && isFetching)) && errorTitle && errorText) {
    return (
      <ShowCard>
        <CardHeader title={<CardTitle text={errorTitle} />} />
        <CardContent className={showClasses.fieldContainer}>
          <Typography component="p" variant="body2" ml={2} mt={2.5}>
            {errorText}
          </Typography>
        </CardContent>
      </ShowCard>
    )
  }

  const childrenProps = { ...props, record }
  return record ? (
    typeof children === 'function' ? (
      children(childrenProps)
    ) : (
      <ShowCard>{cloneElement(Children.only(children), childrenProps)}</ShowCard>
    )
  ) : null
}

export const ShowReference = ({ children, errorText, errorTitle, reference, source, ...props }) => {
  const record = useRecordContext(props)
  return (
    <ResourceContextProvider value={reference}>
      <ShowReferenceBase errorText={errorText} errorTitle={errorTitle} id={record[source]} resource={reference}>
        {children}
      </ShowReferenceBase>
    </ResourceContextProvider>
  )
}

export const ShowActions = ({ allowedActions, className, helperText }) => {
  const resource = useResourceContext()
  const [hasActions] = useResourcePermissions(resource, SYSTEM_PERMISSION_ACTIONS)
  const classes = useShowStyles()

  if (!hasActions || isEmpty(allowedActions)) {
    return null
  }
  return (
    <>
      <CardActions className={classnames(classes.actionsContainer, className)}>
        {allowedActions.map((action) => (
          <ActionButton key={action} action={action} />
        ))}
      </CardActions>
      {helperText && (
        <Typography variant="caption" display="block" className={classes.actionsHelperText}>
          {helperText}
        </Typography>
      )}
    </>
  )
}

export const ShowReferenceLinks = ({ excluded = [], filterValues, record, sources = {} }) => {
  const { permissions } = usePermissions()
  const resource = useResourceContext()
  const classes = useShowStyles()

  const resourceConfig = getResourceByName(resource)
  let { references } = resourceConfig.options
  references = (references || []).filter(
    (r) => hasResourcePermission(permissions, r, SYSTEM_PERMISSION_READ) && isAllowed(excluded, r),
  )
  return references.length > 0 ? (
    <CardActions className={classes.actionsContainer}>
      {references
        .map((reference) => {
          const referenceConfig = getResourceByName(reference)
          const referenceKey = referenceConfig?.options.referenceKey
          return referenceConfig ? (
            <ReferenceLink
              key={referenceConfig.name}
              record={record}
              reference={referenceConfig.name}
              source={sources[referenceKey] ?? referenceKey}
              filterValues={filterValues}
              variant="outlined"
            />
          ) : null
        })
        .filter(Boolean)}
    </CardActions>
  ) : null
}

export const GenericCardTitle = ({ text, sx }) => {
  const resource = useResourceContext()
  return <CardTitle text={`resources.${resource}.show.titles.${text}`} size={17} sx={sx} />
}

export const ShowSubSectionTitleCard = ({ text }) => {
  const showClasses = useShowStyles()
  const commonClasses = useCommonStyles()
  return (
    <CardContent className={classnames(showClasses.subTitleContainer, commonClasses.borderTop)}>
      <GenericCardTitle text={text} />
    </CardContent>
  )
}
