import get from 'lodash/get'
import { useTranslate } from 'ra-core'
import { useGetIdentity, useGetOne, useNotify, useRecordContext, useResourceContext } from 'react-admin'
import React, { useEffect, useMemo, useState } from 'react'
import { Button, Card, CardContent } from '@mui/material'
import { makeStyles } from '@mui/styles'

import { useApi } from '../api/apiProvider'
import { getErrorDataFromError } from '../config/errors'
import { VEHICLE_TYPE_CAR, VEHICLE_TYPES_ICONS } from '../config/vehicles'
import GoogleMap from '../vendor/googlemaps'

import Map, { getMapCircle, getMapMarkerGroup } from './Map'
import SensitiveDataAccessDialog from './SensitiveDataAccessDialog'

const useStyles = makeStyles({
  blurredMapContainer: {
    position: 'relative',
    padding: '0px !important',
  },
  blurredMap: {
    filter: 'blur(4px)',
    height: 360,
    width: 480,
    margin: -0.75,
  },
  accessButton: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
})

const VehicleMapField = () => {
  const record = useRecordContext()
  const { data: hub } = useGetOne('hubs', { id: record.hub_id }, { enabled: Boolean(record.hub_id) })
  const { identity } = useGetIdentity()
  const [justification, setJustification] = useState('')
  const resource = useResourceContext()
  const notify = useNotify()
  const translate = useTranslate()

  const [fetchVehiclePosition, { data: vehiclePositionData, loading }] = useApi(`/${resource}/position/${record.id}`, {
    method: 'POST',
    body: JSON.stringify({ justification }),
    onFailure: (error) => {
      const { message } = getErrorDataFromError(error)
      notify(translate('mymove.errors.errorWithMessage', { message }), { type: 'warning' })
    },
  })

  useEffect(() => {
    if (identity && !identity.has_restricted_vehicle_position_access) {
      fetchVehiclePosition()
    }
  }, [identity?.has_restricted_vehicle_position_access]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleConfirm = () => {
    if (!justification || loading) return
    fetchVehiclePosition()
  }

  const mapInfo = useMemo(() => {
    let vehicleMarkers = []
    if (vehiclePositionData) {
      const vehicleIconPath = VEHICLE_TYPES_ICONS[get(record, 'type', VEHICLE_TYPE_CAR)].path
      const { position: vehiclePosition, last_known_address: vehicleAddress } = vehiclePositionData
      const vehicleInfo = {
        name: translate(`resources.${resource}.fields.last_known_address`),
        description: vehicleAddress,
      }
      vehicleMarkers = getMapMarkerGroup(vehiclePosition, vehicleIconPath, vehicleInfo)
    }

    let hubCircles = []
    if (hub) {
      hubCircles = [getMapCircle(hub.geofencing, hub.position)]
    }
    return { vehicleMarkers, hubCircles }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    JSON.stringify(hub), // eslint-disable-line react-hooks/exhaustive-deps
    JSON.stringify(vehiclePositionData), // eslint-disable-line react-hooks/exhaustive-deps
    translate,
  ])

  if (!identity) return null

  return identity.has_restricted_vehicle_position_access && !vehiclePositionData ? (
    <BlurredMapRequestAccess
      justification={justification}
      loading={loading}
      onConfirm={handleConfirm}
      setJustification={setJustification}
    />
  ) : (
    <Map markers={mapInfo.vehicleMarkers} circles={mapInfo.hubCircles} />
  )
}

export default VehicleMapField

const BlurredMapRequestAccess = ({ justification, loading, onConfirm, setJustification }) => {
  const classes = useStyles()
  const resource = useResourceContext()
  const [isJustificationDataDialogOpen, setIsJustificationDataDialogOpen] = useState(false)
  const translate = useTranslate()

  const handleClose = () => {
    setIsJustificationDataDialogOpen(false)
    setJustification('')
  }

  const handleConfirm = () => {
    onConfirm()
    handleClose()
  }

  return (
    <Card>
      <CardContent className={classes.blurredMapContainer}>
        <div className={classes.blurredMap}>
          <GoogleMap />
        </div>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setIsJustificationDataDialogOpen(true)}
          className={classes.accessButton}
        >
          {translate(`resources.${resource}.mapAccessRequest.buttonTitle`)}
        </Button>
        <SensitiveDataAccessDialog
          handleClose={handleClose}
          handleConfirm={handleConfirm}
          isConfirmDisabled={!justification}
          isOpen={isJustificationDataDialogOpen}
          loading={loading}
          onJustificationChange={(event) => setJustification(event.target.value)}
          translationPrefix={`resources.${resource}.show.locationData`}
        />
      </CardContent>
    </Card>
  )
}
