import { Typography } from '@mui/material'
import { Datagrid } from 'react-admin'

import AdvancedBooleanField from '../../components/AdvancedBooleanField'
import AdvancedTextField from '../../components/AdvancedTextField'
import DateTooltipField from '../../components/DateTooltipField'
import LocalDateField from '../../components/LocalDateField'
import StaticText from '../../components/StaticText'
import { useCommonStyles } from '../../config/theme'
import { GenericListLayout } from '../common/list'
import CompactList from '../../components/CompactList'
import { useResourcePermissions } from '../../domain/permissions'
import { SYSTEM_PERMISSION_CREATE } from '../../config/permissions'
import { AdvancedCreateInDialogButton } from '../common/create'

import config from './config'
import { PricingStrategyPanel, PricingStrategyTable } from './show'
import PricingStrategyFormLayout from './form'

export const PricingStrategiesListLayout = ({ organisationId, ...props }) => {
  const commonClasses = useCommonStyles()
  const [hasCreate] = useResourcePermissions(config.name, SYSTEM_PERMISSION_CREATE)

  return (
    <GenericListLayout
      action={
        hasCreate && (
          <AdvancedCreateInDialogButton
            label="resources.pricing-strategies.actions.create.name"
            sx={{ '& .MuiDialog-paper': { maxWidth: '1280px' } }}
          >
            <PricingStrategyFormLayout organisationId={organisationId} disabledInputsSources={['organisation_id']} />
          </AdvancedCreateInDialogButton>
        )
      }
      compactListLayout={
        <CompactList
          {...props}
          alignItems="flex-start"
          icon={<config.icon />}
          title={(record) => (
            <>
              <Typography component="span" variant="body1" className={commonClasses.inline} color="textPrimary">
                <AdvancedTextField record={record} source="name" />
              </Typography>{' '}
              <Typography component="span" variant="body1" className={commonClasses.inline} color="textSecondary">
                <AdvancedBooleanField
                  record={record}
                  source="active"
                  falseIcon={null}
                  trueLabel={null}
                  falseLabel={null}
                />
              </Typography>
            </>
          )}
          body={(record) => (
            <>
              <StaticText>{'resources.pricing-strategies.fields.start_date'}: </StaticText>
              <LocalDateField record={record} source="start_date" />
              <br />
              <StaticText>{'resources.pricing-strategies.fields.created_on'} </StaticText>
              <DateTooltipField record={record} source="created_on" addTime />
            </>
          )}
          collapsibleBody={(record) => <PricingStrategyTable record={record} />}
        />
      }
      regularListLayout={
        <Datagrid {...props} expand={<PricingStrategyPanel />}>
          <AdvancedTextField source="name" />
          <LocalDateField source="start_date" />
          <DateTooltipField source="created_on" addTime />
          <AdvancedBooleanField source="active" falseIcon={null} sortable={false} trueLabel={null} falseLabel={null} />
        </Datagrid>
      }
    />
  )
}
