import { CardHeader } from '@mui/material'
import { useRecordContext, useResourceContext } from 'react-admin'

import { useCommonStyles } from '../config/theme'
import { CardTitle } from '../resources/common'

import ShowButton from './ShowButton'

const ShowCardHeader = ({ action, hasRedirect = false, title, titleArgs, ...props }) => {
  const record = useRecordContext(props)
  const resource = useResourceContext(props)
  const commonClasses = useCommonStyles()
  return (
    <CardHeader
      title={<CardTitle text={title ?? `resources.${resource}.show.titles.main`} args={titleArgs} />}
      action={action ?? <ShowButton resource={resource} id={record.id} hidden={!hasRedirect} />}
      className={commonClasses.titleContainer}
    />
  )
}

export default ShowCardHeader
