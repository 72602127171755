import { get } from 'lodash'
import { ArrayField, Datagrid, FunctionField, useRecordContext } from 'react-admin'

import {
  HUB_PICK_UP_AND_DROP_OFF_HOURS_SOURCE,
  HUB_PICK_UP_AND_DROP_OFF_HOURS_TYPE_CUSTOM,
  HUB_PICK_UP_AND_DROP_OFF_HOURS_TYPES,
} from '../config/hubs'
import { getHubPickUpAndDropOffHoursWeekDayParams } from '../domain/hubs'
import { getWeekDayFromIndex } from '../utils/dates'

import AdvancedTextField from './AdvancedTextField'
import PeriodField from './PeriodField'
import StaticText from './StaticText'

const HubPickUpAndDropOffHoursField = ({ source }) => {
  const record = useRecordContext()
  const value = get(record, source)

  if (!Boolean(value)) {
    return <StaticText>resources.hubs.pickUpAndDropOffHours.always</StaticText>
  }

  const newRecord = {
    ...record,
    [source]: value.map((weekDay, index) => ({
      label: getWeekDayFromIndex(index),
      ...getHubPickUpAndDropOffHoursWeekDayParams(weekDay.start_time, weekDay.end_time),
    })),
  }

  return (
    <ArrayField record={newRecord} source={source}>
      <Datagrid>
        <AdvancedTextField source="label" label="mymove.dates.day" />
        <FunctionField
          label="resources.hubs.pickUpAndDropOffHours.openingTypes.name"
          render={(record) =>
            record.type === HUB_PICK_UP_AND_DROP_OFF_HOURS_TYPE_CUSTOM ? (
              <PeriodField record={record} startedOnSource="start_time" endedOnSource="end_time" />
            ) : (
              <AdvancedTextField record={record} source="type" map={HUB_PICK_UP_AND_DROP_OFF_HOURS_TYPES} />
            )
          }
        />
      </Datagrid>
    </ArrayField>
  )
}

HubPickUpAndDropOffHoursField.defaultProps = {
  source: HUB_PICK_UP_AND_DROP_OFF_HOURS_SOURCE,
}

export default HubPickUpAndDropOffHoursField
