import { Box, CardContent, CircularProgress, TextField } from '@mui/material'
import classnames from 'classnames'
import { debounce } from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import {
  FunctionField,
  ImageField,
  ResourceContextProvider,
  SimpleShowLayout,
  useGetOne,
  useNotify,
  useRecordContext,
  useReference,
  useResourceContext,
} from 'react-admin'
import { useTranslate } from 'ra-core'

import AdvancedBooleanField from '../../components/AdvancedBooleanField'
import AdvancedNumberField from '../../components/AdvancedNumberField'
import AdvancedTextField from '../../components/AdvancedTextField'
import AdvancedShow from '../../components/AdvancedShow'
import AdvancedUrlField from '../../components/AdvancedUrlField'
import OperationalStatusSection from '../../components/OperationalStatusSection'
import PeriodField from '../../components/PeriodField'
import PriceField from '../../components/PriceField'
import ShowCardHeader from '../../components/ShowCardHeader'
import UnitField from '../../components/UnitField'
import VehicleLockField from '../../components/VehicleLockField'
import VehicleMapField from '../../components/VehicleMapField'
import { viewModeList } from '../../components/ViewModeToggle'
import { useApi } from '../../api/apiProvider'
import { DAMAGES_STATUS_VALIDATED } from '../../config/damages'
import {
  SYSTEM_PERMISSION_GATEWAY_LINK,
  SYSTEM_PERMISSION_PATCH,
  SYSTEM_PERMISSION_POSITION,
} from '../../config/permissions'
import { useCommonStyles } from '../../config/theme'
import {
  VEHICLE_ENERGY_TYPE_ELECTRIC,
  VEHICLE_ENERGY_TYPE_FUEL,
  VEHICLE_ENERGY_TYPES,
  VEHICLE_GATEWAYS,
  VEHICLE_SPECIAL_FIELD_CODE_CHARGING_INSTRUCTIONS,
  VEHICLE_SPECIAL_FIELD_CODE_FUEL_CARD_PIN,
  VEHICLE_SPECIAL_FIELD_CODE_FUELING_INFO,
  VEHICLE_SPECIAL_FIELD_CODE_PARKING_SPOT,
  VEHICLE_SPECIAL_FIELDS,
  VEHICLE_TRANSMISSION_TYPES,
  VEHICLE_TYPE_CAR_BLUEPRINT_SETS,
  VEHICLE_TYPES,
} from '../../config/vehicles'
import { useResourcePermissions } from '../../domain/permissions'
import { isVehicleCar, isVehicleElectricCar, isVehicleFuelCar } from '../../domain/vehicles'
import { useSmallScreen } from '../../utils/theme'
import bookingsConfig from '../bookings/config'
import { BookingsListLayout } from '../bookings/list'
import { BookingShowLayout } from '../bookings/show'
import { CardTitle } from '../common'
import { ListReference } from '../common/list'
import { ShowActions, ShowCard, ShowReferenceLinks, ShowSubSectionTitleCard, useShowStyles } from '../common/show'
import damageReportsConfig from '../damageReports/config'
import { DamageReportsListLayout } from '../damageReports/list'
import HubField from '../hubs/field'
import OrganisationField from '../organisations/field'
import vehicleDetailsConfig from '../vehicleDetails/config'
import vehicleUnavailabilitiesConfig from '../vehicleUnavailabilities/config'
import { VehicleUnavailabilitiesListLayout } from '../vehicleUnavailabilities/list'

import config from './config'

const VehicleSpecialField = ({ code, label }) => {
  const record = useRecordContext()
  return (
    <FunctionField
      label={label}
      render={() => {
        const detail = record.details?.find((item) => item.code === code)
        if (code === VEHICLE_SPECIAL_FIELD_CODE_PARKING_SPOT && record.parking_spot) {
          // Show both static and dynamic parking spots if present
          return `${detail?.value || ''} ${record.parking_spot}`.trim()
        }
        return detail?.value ?? 'n/a'
      }}
    />
  )
}

export const VehicleShowLayout = ({
  title = 'resources.vehicles.show.titles.main',
  hasRedirect = false,
  excluded = [],
  ...props
}) => {
  const record = useRecordContext(props)
  const resource = useResourceContext()
  const { data: vehicleDetails } = useGetOne(vehicleDetailsConfig.name, { id: record.id })

  const notify = useNotify()
  const translate = useTranslate()
  const showClasses = useShowStyles()
  const commonClasses = useCommonStyles()
  const isSmallScreen = useSmallScreen()

  const [hasPatch, hasGatewayLink] = useResourcePermissions(
    resource,
    SYSTEM_PERMISSION_PATCH,
    SYSTEM_PERMISSION_GATEWAY_LINK,
  )

  const [hasPositionForVehicles] = useResourcePermissions(config.name, SYSTEM_PERMISSION_POSITION)
  const [notes, setNotes] = useState(null)
  const [notesBeingSaved, setNotesBeingSaved] = useState(false)
  const debouncedSetNotes = useCallback(debounce(setNotes, 2000), []) // eslint-disable-line react-hooks/exhaustive-deps
  const [runSaveNotesQuery] = useApi(`/vehicles/${record.id}`, {
    method: 'PATCH',
    body: JSON.stringify({ notes }),
    onSuccess: () => {
      setNotesBeingSaved(false)
    },
    onFailure: (error) => {
      setNotesBeingSaved(false)
      notify(translate('resources.vehicles.show.couldNotSaveNotes', { errorMessage: error.message }), {
        type: 'warning',
      })
    },
  })

  useEffect(() => {
    if (vehicleDetails) {
      setNotes(vehicleDetails.notes)
    }
  }, [JSON.stringify(vehicleDetails), setNotes]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // Don't save notes on initial render
    if (notes === null || !vehicleDetails || notes === vehicleDetails.notes) {
      return
    }
    runSaveNotesQuery()
  }, [notes, vehicleDetails?.notes]) // eslint-disable-line react-hooks/exhaustive-deps

  const simpleShowLayoutCommonProps = { record, className: showClasses.fieldContainer }
  const operationalStatusSectionProps = { data: vehicleDetails?.status_timeline }

  return (
    <>
      <ShowCardHeader hasRedirect={hasRedirect} record={record} title={title} />
      {vehicleDetails && (
        <>
          <div className={isSmallScreen ? null : showClasses.row}>
            <div className={showClasses.expanded}>
              <ShowSubSectionTitleCard text="identification" />
              <div className={isSmallScreen ? null : showClasses.row}>
                <div className={showClasses.expanded}>
                  <SimpleShowLayout {...simpleShowLayoutCommonProps}>
                    <ImageField
                      source="picture"
                      title="model"
                      label={false}
                      sx={{
                        '& img': {
                          width: 'auto !important',
                          maxHeight: '6rem !important',
                          marginTop: '9px !important',
                        },
                      }}
                    />
                    <AdvancedTextField source="type" map={VEHICLE_TYPES} />
                    {isVehicleCar(record) && (
                      <AdvancedTextField source="blueprint_set" map={VEHICLE_TYPE_CAR_BLUEPRINT_SETS} />
                    )}
                    <AdvancedTextField source="brand" />
                    <AdvancedTextField source="model" />
                    <AdvancedTextField source="year" />
                    <AdvancedTextField source="designation" />
                    {isVehicleCar(record) && <AdvancedTextField source="identification_number" />}
                  </SimpleShowLayout>
                </div>
              </div>
              <ShowSubSectionTitleCard text="status" />
              <SimpleShowLayout {...simpleShowLayoutCommonProps}>
                <UnitField source="autonomy" />
                <AdvancedTextField source="mileage" />
                <VehicleLockField />
              </SimpleShowLayout>
              <ShowActions
                allowedActions={vehicleDetails.allowed_actions}
                helperText={translate('resources.vehicles.actions.helperText')}
              />
              {isSmallScreen && <OperationalStatusSection {...operationalStatusSectionProps} />}
              {isVehicleCar(record) && (
                <>
                  <ShowSubSectionTitleCard text="characteristics" />
                  <SimpleShowLayout {...simpleShowLayoutCommonProps}>
                    <AdvancedTextField source="transmission_type" map={VEHICLE_TRANSMISSION_TYPES} />
                    <AdvancedBooleanField source="keyless" trueIcon={null} falseIcon={null} />
                    <AdvancedNumberField source="number_of_seats" />
                    <UnitField source="volume" unit="m³" />
                    <PriceField source="value" />
                    <AdvancedTextField source="energy_type" map={VEHICLE_ENERGY_TYPES} />
                    {record.energy_type === VEHICLE_ENERGY_TYPE_ELECTRIC && <UnitField source="maximum_autonomy" />}
                    {record.energy_type === VEHICLE_ENERGY_TYPE_ELECTRIC && (
                      <VehicleSpecialField
                        code={VEHICLE_SPECIAL_FIELD_CODE_CHARGING_INSTRUCTIONS}
                        label={VEHICLE_SPECIAL_FIELDS[VEHICLE_SPECIAL_FIELD_CODE_CHARGING_INSTRUCTIONS]}
                      />
                    )}
                    {record.energy_type === VEHICLE_ENERGY_TYPE_FUEL && <UnitField source="tank_size" unit="L" />}
                    {record.energy_type === VEHICLE_ENERGY_TYPE_FUEL && (
                      <UnitField source="average_consumption" unit="L/100km" />
                    )}
                    {record.energy_type === VEHICLE_ENERGY_TYPE_FUEL && (
                      <VehicleSpecialField
                        code={VEHICLE_SPECIAL_FIELD_CODE_FUELING_INFO}
                        label={VEHICLE_SPECIAL_FIELDS[VEHICLE_SPECIAL_FIELD_CODE_FUELING_INFO]}
                      />
                    )}
                  </SimpleShowLayout>
                </>
              )}
              <ShowSubSectionTitleCard text="parameters" />
              <SimpleShowLayout {...simpleShowLayoutCommonProps}>
                <OrganisationField />
                <HubField />
                <PeriodField
                  label="resources.common.fields.period"
                  startedOnSource="started_on"
                  endedOnSource="ended_on"
                  addTime
                />
                <AdvancedNumberField source="price_factor" />
                <PriceField source="security_deposit_amount" />
                {isVehicleCar(record) && (
                  <PriceField source="price_per_km" label="resources.organisations.fields.price_per_km" />
                )}
                <VehicleSpecialField
                  code={VEHICLE_SPECIAL_FIELD_CODE_PARKING_SPOT}
                  label={VEHICLE_SPECIAL_FIELDS[VEHICLE_SPECIAL_FIELD_CODE_PARKING_SPOT]}
                />
                {isVehicleElectricCar(record) && (
                  <UnitField
                    source="charging_buffer"
                    unit="mymove.units.time.hours"
                    formatValue={(v) => v / 60}
                    shouldFormatUnit
                  />
                )}
                {isVehicleFuelCar(record) && (
                  <VehicleSpecialField
                    code={VEHICLE_SPECIAL_FIELD_CODE_FUEL_CARD_PIN}
                    label={VEHICLE_SPECIAL_FIELDS[VEHICLE_SPECIAL_FIELD_CODE_FUEL_CARD_PIN]}
                  />
                )}
                {!isVehicleCar(record) && (
                  <VehicleSpecialField
                    code={VEHICLE_SPECIAL_FIELD_CODE_CHARGING_INSTRUCTIONS}
                    label={VEHICLE_SPECIAL_FIELDS[VEHICLE_SPECIAL_FIELD_CODE_CHARGING_INSTRUCTIONS]}
                  />
                )}
              </SimpleShowLayout>
              <ShowSubSectionTitleCard text="providerDetails" />
              <SimpleShowLayout {...simpleShowLayoutCommonProps}>
                <AdvancedBooleanField
                  source="installed"
                  trueIcon={null}
                  falseIcon={null}
                  trueLabel="resources.vehicles.enums.installed.true"
                  falseLabel="resources.vehicles.enums.installed.false"
                />
                <AdvancedTextField source="gateway.code" map={VEHICLE_GATEWAYS} />
                <AdvancedTextField source="gateway.vehicle_id" />
                {hasGatewayLink && (
                  <AdvancedUrlField
                    record={vehicleDetails}
                    source="gateway.link"
                    text={translate('resources.vehicles.show.goToVehicleConsole')}
                  />
                )}
              </SimpleShowLayout>
            </div>
            <Box
              width="100%"
              maxWidth={isSmallScreen ? null : 480}
              className={isSmallScreen ? null : commonClasses.borderLeft}
            >
              {!isSmallScreen && <OperationalStatusSection {...operationalStatusSectionProps} />}
              {hasPositionForVehicles && (
                <>
                  <ShowSubSectionTitleCard text="location" />
                  <SimpleShowLayout
                    record={record}
                    className={classnames(commonClasses.borderTop, showClasses.map)}
                    sx={{ '& .RaSimpleShowLayout-row': { margin: 0, width: 480 } }}
                  >
                    <VehicleMapField label={false} />
                  </SimpleShowLayout>
                </>
              )}
              <CardContent className={classnames(showClasses.subTitleContainer, commonClasses.borderTop)}>
                <CardTitle
                  size={17}
                  text={
                    <div className={commonClasses.flexRowCenterContainer}>
                      <div>{translate('resources.vehicles.fields.notes')}</div>
                      {hasPatch && notesBeingSaved && <CircularProgress size={18} style={{ marginLeft: 12 }} />}
                    </div>
                  }
                />
              </CardContent>
              {hasPatch ? (
                <SimpleShowLayout record={vehicleDetails} className={commonClasses.borderTop}>
                  <TextField
                    key={vehicleDetails.id}
                    id="notes-textarea-input"
                    defaultValue={vehicleDetails.notes ?? ''}
                    onChange={(event) => {
                      if (event.target.value === notes) {
                        setNotesBeingSaved(false)
                        debouncedSetNotes.cancel()
                      } else {
                        setNotesBeingSaved(true)
                        debouncedSetNotes(event.target.value)
                      }
                    }}
                    className={showClasses.textFieldWithFullWidth}
                    placeholder={translate('resources.vehicles.notesPlaceholder')}
                    multiline
                  />
                </SimpleShowLayout>
              ) : (
                <SimpleShowLayout record={vehicleDetails} className={showClasses.fieldContainer}>
                  <AdvancedTextField
                    label={false}
                    source="notes"
                    fallback={translate('resources.vehicles.notesPlaceholder')}
                    className={showClasses.textareaField}
                  />
                </SimpleShowLayout>
              )}
            </Box>
          </div>
          <ShowReferenceLinks excluded={excluded} record={record} />
        </>
      )}
    </>
  )
}

const VehicleAside = () => {
  const record = useRecordContext()
  const { data: vehicleDetails } = useGetOne(vehicleDetailsConfig.name, { id: record.id })
  const { referenceRecord: currentBooking } = useReference({
    reference: 'bookings',
    id: vehicleDetails?.current_booking_id,
    options: { enabled: Boolean(vehicleDetails?.current_booking_id) },
  })
  const { referenceRecord: currentBookingOrganisation } = useReference({
    reference: 'organisations',
    id: currentBooking?.organisation_id,
    options: { enabled: Boolean(currentBooking?.organisation_id) },
  })

  const [viewMode, setViewMode] = useState(viewModeList)
  const translate = useTranslate()

  return (
    <>
      {currentBooking && currentBookingOrganisation && (
        <ShowCard>
          <ResourceContextProvider value="bookings">
            <BookingShowLayout
              excluded={['organisations', 'hubs', 'vehicles']}
              hasRedirect
              record={currentBooking}
              title={translate('resources.common.show.currentBookingFor', {
                organisationName: currentBookingOrganisation.name,
              })}
            />
          </ResourceContextProvider>
        </ShowCard>
      )}
      <ListReference
        reference={vehicleUnavailabilitiesConfig.name}
        target="vehicle_id"
        sort={vehicleUnavailabilitiesConfig.options.defaultSort}
      >
        <VehicleUnavailabilitiesListLayout
          createProps={{
            defaultValues: { organisation_id: record.organisation_id, vehicle_id: record.id },
            disabledInputsSources: ['organisation_id', 'vehicle_id'],
          }}
          excluded={['organisations', 'vehicles']}
        />
      </ListReference>
      <ListReference
        reference="bookings"
        target="vehicle_id"
        sort={bookingsConfig.options.defaultSort}
        filter={bookingsConfig.options.defaultFilterValues}
        showPagination={viewMode === viewModeList}
      >
        <BookingsListLayout
          excluded={['vehicles']}
          disabledInputsSources={['organisation_id', 'hub_id', 'vehicle_id']}
          viewMode={viewMode}
          setViewMode={setViewMode}
          schedulerProps={{
            creationInitialValues: {
              organisation_id: record.organisation_id,
              hub_id: record.hub_id,
              vehicle_id: record.id,
            },
            filter: { vehicle_id: record.id },
            groupFilter: { id: [record.id] },
            relatedResource: 'users',
          }}
        />
      </ListReference>
      <ListReference
        reference="damage-reports"
        target="vehicle_id"
        sort={damageReportsConfig.options.defaultSort}
        filter={{ status: DAMAGES_STATUS_VALIDATED }}
      >
        <DamageReportsListLayout excluded={['organisations', 'hubs', 'vehicles']} />
      </ListReference>
    </>
  )
}

export default () => (
  <AdvancedShow aside={<VehicleAside />}>
    <VehicleShowLayout />
  </AdvancedShow>
)
