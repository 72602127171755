import { isEmpty } from 'lodash'
import { useGetOne } from 'react-admin'

import bookingDetailsConfig from '../bookingDetails/config'
import { SYSTEM_PERMISSION_CREATE } from '../../config/permissions'
import { useResourcePermissions } from '../../domain/permissions'
import { AdvancedCreateInDialogButton } from '../common/create'

import { useGetTransactionsListForBooking } from './hooks'
import BookingTransactionFormLayout, { transformValues } from './form'
import config from './config'

export const CreateBookingTransactionButton = ({ bookingId }) => {
  const [hasCreate] = useResourcePermissions(config.name, SYSTEM_PERMISSION_CREATE)
  const { data: bookingDetails } = useGetOne(
    bookingDetailsConfig.name,
    { id: bookingId },
    { enabled: Boolean(bookingId) },
  )
  const { data: bookingTransactions } = useGetTransactionsListForBooking(bookingId)

  return hasCreate && !isEmpty(bookingDetails?.allowed_transaction_actions) && !isEmpty(bookingTransactions) ? (
    <AdvancedCreateInDialogButton
      filterValues={{ booking_id: bookingId }}
      isFloatingOnSmallScreen={false}
      transform={transformValues}
    >
      <BookingTransactionFormLayout bookingId={bookingId} />
    </AdvancedCreateInDialogButton>
  ) : null
}
