import Icon from '@mui/icons-material/People'

import { getUserFullName } from '../../components/UserFullNameField'
import {
  UI_PERMISSION_MENU,
  OPS_USER_ROLE_OWNER,
  OPS_USER_ROLE_ADMIN,
  OPS_USER_ROLE_SUPPORT,
  OPS_USER_ROLE_PLANNER,
  OPS_USER_ROLE_VIEWER,
} from '../../config/permissions'
import { USER_ACTIONS } from '../../config/users'

export default {
  name: 'users',
  recordRepresentation: getUserFullName,
  icon: Icon,
  options: {
    permissions: {
      [OPS_USER_ROLE_OWNER]: [UI_PERMISSION_MENU],
      [OPS_USER_ROLE_ADMIN]: [UI_PERMISSION_MENU],
      [OPS_USER_ROLE_SUPPORT]: [UI_PERMISSION_MENU],
      [OPS_USER_ROLE_PLANNER]: [UI_PERMISSION_MENU],
      [OPS_USER_ROLE_VIEWER]: [UI_PERMISSION_MENU],
    },
    referenceKey: 'user_id',
    defaultSort: { field: 'last_name', order: 'ASC' },
    defaultFilterValues: { closed: false },
    references: [],
    actions: USER_ACTIONS,
    badge: true,
  },
}
