import { useGetList } from 'react-admin'

import { useAccounts } from '../../api/accountsProvider'
import hubsConfig from '../hubs/config'
import organisationsConfig from '../organisations/config'

export const useCurrentAccountSelectors = () => {
  const { currentAccount } = useAccounts()
  const currentAccountId = currentAccount?.id

  const pagination = { page: 1, perPage: 1 }
  const commonFilter = { account_id: currentAccountId }
  const commonOptions = { enabled: Boolean(currentAccountId) }

  const organisations = useGetList(
    organisationsConfig.name,
    {
      pagination,
      sort: organisationsConfig.options.defaultSort,
      filter: { ...organisationsConfig.options.defaultFilterValues, ...commonFilter },
    },
    commonOptions,
  )
  const hubs = useGetList(
    hubsConfig.name,
    { pagination, sort: hubsConfig.options.defaultSort, filter: commonFilter },
    commonOptions,
  )

  const hasSingleOrganisation = organisations.total === 1
  const singleOrganisationId = hasSingleOrganisation ? organisations.data[0].id : null
  const hasSingleHub = hubs.total === 1

  return { currentAccount, hasSingleOrganisation, singleOrganisationId, hasSingleHub }
}
