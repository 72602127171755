import Icon from '@mui/icons-material/Healing'
import { useGetRecordRepresentation, useRecordContext, useReference } from 'react-admin'

import {
  OPS_USER_ROLE_ADMIN,
  OPS_USER_ROLE_LEASING_COMPANY,
  OPS_USER_ROLE_OWNER,
  OPS_USER_ROLE_PLANNER,
  OPS_USER_ROLE_SUPPORT,
  OPS_USER_ROLE_VIEWER,
  UI_PERMISSION_MENU,
} from '../../config/permissions'
import { DAMAGES_STATUS_REPORTED } from '../../config/damages'

export default {
  name: 'damage-reports',
  recordRepresentation: () => {
    const DamageReportRecordRepresentation = () => {
      const record = useRecordContext()
      const organisationRecordRepresentation = useGetRecordRepresentation('organisations')
      const vehicleRecordRepresentation = useGetRecordRepresentation('vehicles')

      const { referenceRecord: organisationRecord } = useReference({
        reference: 'organisations',
        id: record.organisation_id,
      })
      const { referenceRecord: vehicleRecord } = useReference({ reference: 'vehicles', id: record.vehicle_id })

      return `${organisationRecordRepresentation(organisationRecord)} • ${vehicleRecordRepresentation(vehicleRecord)}`
    }
    return <DamageReportRecordRepresentation />
  },
  options: {
    permissions: {
      [OPS_USER_ROLE_OWNER]: [UI_PERMISSION_MENU],
      [OPS_USER_ROLE_ADMIN]: [UI_PERMISSION_MENU],
      [OPS_USER_ROLE_SUPPORT]: [UI_PERMISSION_MENU],
      [OPS_USER_ROLE_PLANNER]: [UI_PERMISSION_MENU],
      [OPS_USER_ROLE_LEASING_COMPANY]: [UI_PERMISSION_MENU],
      [OPS_USER_ROLE_VIEWER]: [UI_PERMISSION_MENU],
    },
    defaultSort: { field: 'created_on', order: 'DESC' },
    defaultFilterValues: { status: DAMAGES_STATUS_REPORTED },
    references: ['organisations', 'users', 'vehicles'],
    badge: true,
  },
  icon: Icon,
}
