import { Typography } from '@mui/material'
import { Datagrid, Filter, List } from 'react-admin'
import { useTranslate } from 'ra-core'
import { useTheme } from '@mui/styles'

import AdvancedSearchFilter from '../../components/AdvancedSearchFilter'
import AdvancedTextField from '../../components/AdvancedTextField'
import CompactList from '../../components/CompactList'
import ReferenceValue from '../../components/ReferenceValue'
import StaticText from '../../components/StaticText'
import StatusField from '../../components/StatusField'
import UnitField from '../../components/UnitField'
import VehicleIconField from '../../components/VehicleIconField'
import VehicleLockField from '../../components/VehicleLockField'
import { getVehicleName } from '../../components/VehicleNameField'
import { useCommonStyles } from '../../config/theme'
import { getStatusStyles } from '../../domain/statuses'
import { isVehicleInstalled } from '../../domain/vehicles'
import { useCurrentAccountSelectors } from '../accounts/hooks'
import { AdvancedCreateInDialogButton } from '../common/create'
import { AdvancedEditInDialogButton } from '../common/edit'
import useGetOrganisationWithHubFilters from '../common/filters'
import { GenericListLayout, ListActions } from '../common/list'
import HubField from '../hubs/field'
import OrganisationField from '../organisations/field'

import config from './config'
import vehiclesExporter from './exporter'
import { VehicleActiveFilter, VehicleLockFilter, VehicleTypeFilter } from './filter'
import VehicleFormLayout, { transformValues } from './form'

const VehiclesFilters = (props) => {
  const organisationWithHubFilters = useGetOrganisationWithHubFilters()
  return (
    <Filter {...props}>
      <AdvancedSearchFilter alwaysOn />
      {organisationWithHubFilters && organisationWithHubFilters.map((Filter) => Filter)}
      <VehicleTypeFilter alwaysOn />
      <VehicleActiveFilter alwaysOn />
      <VehicleLockFilter alwaysOn />
    </Filter>
  )
}

const vehicleRowStyle = (record) => {
  const isInstalled = isVehicleInstalled(record)
  return { opacity: isInstalled ? 1 : 0.4 }
}

export const VehiclesListLayout = () => {
  const translate = useTranslate()
  const { hasSingleOrganisation, hasSingleHub } = useCurrentAccountSelectors()

  const theme = useTheme()
  const commonClasses = useCommonStyles()

  return (
    <GenericListLayout
      compactListLayout={
        <CompactList
          linkType="show"
          itemStyle={vehicleRowStyle}
          icon={<VehicleIconField />}
          iconBadgeColor={(record) => getStatusStyles(record.status, theme).mainColor}
          title={(record) => (
            <>
              <Typography component="span" variant="body1" className={commonClasses.inline} color="textPrimary">
                {getVehicleName(record)}
              </Typography>
              <Typography component="span" variant="body1" className={commonClasses.inline} color="textSecondary">
                {' • '}
                {record.designation}
              </Typography>
            </>
          )}
          body={(record) => (
            <>
              <StaticText>{`${translate('resources.organisations.name', 1)}: `}</StaticText>
              <ReferenceValue
                record={record}
                reference="organisations"
                source="organisation_id"
                target="name"
                fallback="n/a"
              />
              <br />
              <StaticText>{`${translate('resources.hubs.name', 1)}: `}</StaticText>
              <ReferenceValue record={record} reference="hubs" source="hub_id" target="name" fallback="n/a" />
            </>
          )}
          references={config.options.references}
        />
      }
      regularListLayout={
        <Datagrid rowClick="show" rowStyle={vehicleRowStyle}>
          <StatusField />
          {!hasSingleOrganisation && (
            <OrganisationField sortable={false} label={translate('resources.organisations.name', 1)} />
          )}
          {!hasSingleHub && <HubField sortable={false} />}
          <VehicleIconField color="textSecondary" />
          <AdvancedTextField source="brand" />
          <AdvancedTextField source="model" />
          <AdvancedTextField source="designation" />
          <UnitField source="autonomy" />
          <VehicleLockField />
          <AdvancedEditInDialogButton transform={transformValues}>
            <VehicleFormLayout />
          </AdvancedEditInDialogButton>
        </Datagrid>
      }
    />
  )
}

export default () => {
  return (
    <List
      sort={config.options.defaultSort}
      filters={<VehiclesFilters />}
      filterDefaultValues={config.options.defaultFilterValues}
      exporter={vehiclesExporter}
      actions={
        <ListActions hasExport>
          <AdvancedCreateInDialogButton transform={transformValues}>
            <VehicleFormLayout />
          </AdvancedCreateInDialogButton>
        </ListActions>
      }
    >
      <VehiclesListLayout />
    </List>
  )
}
