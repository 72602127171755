import LibraryBooksTwoToneIcon from '@mui/icons-material/LibraryBooksTwoTone'

import { OPS_USER_ROLE_ADMIN, OPS_USER_ROLE_OWNER, UI_PERMISSION_MENU } from '../../config/permissions'

export default {
  name: 'events',
  options: {
    permissions: {
      [OPS_USER_ROLE_OWNER]: [UI_PERMISSION_MENU],
      [OPS_USER_ROLE_ADMIN]: [UI_PERMISSION_MENU],
    },
    defaultSort: { field: 'date', order: 'DESC' },
  },
  icon: LibraryBooksTwoToneIcon,
}
