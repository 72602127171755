import { Box, Typography } from '@mui/material'
import FalseIcon from '@mui/icons-material/Clear'
import TrueIcon from '@mui/icons-material/Done'
import { makeStyles } from '@mui/styles'
import classnames from 'classnames'
import { isEmpty } from 'lodash'
import { useRef, useState } from 'react'
import { useWatch } from 'react-hook-form'
import {
  CREATE,
  UPDATE,
  FormDataConsumer,
  Labeled,
  ResourceContextProvider,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  required,
  useListContext,
  useRecordContext,
} from 'react-admin'
import { useTranslate } from 'ra-core'
import { CreateDialog } from '@react-admin/ra-form-layout'
import { ReferenceManyInput } from '@react-admin/ra-relationships'

import AdvancedDateTimeInput from '../../components/AdvancedDateTimeInput'
import BasicFormToolbar from '../../components/BasicFormToolbar'
import PeriodField from '../../components/PeriodField'
import { BOOKING_ERA_CURRENT } from '../../config/bookings'
import { useCommonStyles } from '../../config/theme'
import { VEHICLE_UNAVAILABILITY_TYPES } from '../../config/vehicleUnavailabilities'
import { isDisabled } from '../../utils'
import {
  getDateWithThirtyMinutesOffset,
  getRoundedNow,
  parseDateAsDateTime,
  parseDateAsISO,
  validateEndDateAfterStartDate,
} from '../../utils/dates'
import bookingsConfig from '../bookings/config'
import { FormDivider } from '../common'
import { useEditResource } from '../common/edit'
import organisationsConfig from '../organisations/config'
import OrganisationReferenceInput from '../organisations/input'
import vehiclesConfig from '../vehicles/config'
import VehicleReferenceInput from '../vehicles/input'

const conflictingBookingsSource = '@@ra-many/maintenances/bookings/vehicle_id'

const vehicleUnavailabilityTypeChoices = Object.entries(VEHICLE_UNAVAILABILITY_TYPES).map(([k, v]) => ({
  id: k,
  name: v,
}))

const validateEndAfterStart = (value, allValues) => validateEndDateAfterStartDate(allValues.started_on, value)

const useStyles = makeStyles({
  icon: {
    verticalAlign: 'bottom',
    marginRight: '0.25em',
  },
  vehicleInputSubForm: {
    marginTop: '3px !important',
  },
})

export const transformValues = (values) => {
  const conflictingBookings = values[conflictingBookingsSource][0].bookings
  const transformedConflictingBookings = isEmpty(conflictingBookings)
    ? []
    : conflictingBookings.map(({ conflicting_booking_vehicle_id, vehicle_id, ...restFields }) => ({
        ...restFields,
        vehicle_id: conflicting_booking_vehicle_id || vehicle_id,
      }))
  return { ...values, [conflictingBookingsSource]: [{ bookings: transformedConflictingBookings }] }
}

const ConflictingBookingsSubFormRoot = ({ children }) => {
  const commonClasses = useCommonStyles()
  const classes = useStyles()
  const translate = useTranslate()
  const values = useWatch({ name: conflictingBookingsSource })
  const total = values?.[0]?.bookings?.length

  return (
    <>
      <Box p="4px 10px 20px 10px">
        {total === undefined ? null : total > 0 ? (
          <>
            <Typography variant="body1" style={{ fontWeight: 'bold' }}>
              <FalseIcon className={classes.icon} color="error" />
              {translate('resources.maintenances.conflictingBookings.true.title')}
            </Typography>
            <Typography variant="body2" style={{ margin: '13px 13px 0px 30px' }}>
              {translate('resources.maintenances.conflictingBookings.true.helperText')}
            </Typography>
          </>
        ) : (
          <Typography variant="body2">
            <TrueIcon className={classnames(commonClasses.primaryColor, classes.icon)} />
            {translate('resources.maintenances.conflictingBookings.false.title')}
          </Typography>
        )}
      </Box>
      {children}
    </>
  )
}

const ConflictingBookingsSubForm = () => {
  const values = useWatch()
  const classes = useStyles()
  const mainFormVehicleId = values[vehiclesConfig.options.referenceKey]

  return values.started_on && values.ended_on && mainFormVehicleId ? (
    <ConflictingBookingsSubFormRoot>
      <ReferenceManyInput
        filter={{
          ...bookingsConfig.options.defaultFilterValues,
          timeline_start: parseDateAsISO(values.started_on),
          timeline_end: parseDateAsISO(values.ended_on),
        }}
        label={false}
        queryOptions={{ staleTime: 0 }}
        record={{ id: mainFormVehicleId }}
        reference={bookingsConfig.name}
        sort={{ ...bookingsConfig.options.defaultSort, order: 'ASC' }}
        target={vehiclesConfig.options.referenceKey}
      >
        <SimpleFormIterator
          inline
          disableAdd
          disableRemove
          sx={{
            '& .RaSimpleFormIterator-line': { justifyContent: 'center', marginBottom: '16px' },
            '& .RaSimpleFormIterator-action': { display: 'none' },
          }}
        >
          <FormDataConsumer>
            {({ scopedFormData, getSource }) => {
              const isBookingCurrent = scopedFormData.era === BOOKING_ERA_CURRENT
              return (
                <>
                  <Labeled label="resources.common.fields.period">
                    <PeriodField
                      record={scopedFormData}
                      startedOnSource="effective_started_on"
                      endedOnSource="effective_ended_on"
                      addTime
                    />
                  </Labeled>
                  <VehicleReferenceInput
                    label="resources.bookings.fields.vehicle_id"
                    source={getSource('conflicting_booking_vehicle_id')}
                    emptyText="resources.maintenances.conflictingBookings.true.noReallocation"
                    helperText={
                      isBookingCurrent ? 'resources.bookings.forms.helperTexts.conflicting_booking_vehicle_id' : null
                    }
                    filter={{
                      available_from: scopedFormData.start_scheduled_on,
                      available_to: scopedFormData.end_scheduled_on,
                      user_id: scopedFormData.user_id,
                      hub_id: scopedFormData.hub_id,
                    }}
                    className={classes.vehicleInputSubForm}
                    readOnly={isBookingCurrent}
                    linkedToHub={false}
                    validate={null}
                    perPage={50}
                  />
                </>
              )
            }}
          </FormDataConsumer>
        </SimpleFormIterator>
      </ReferenceManyInput>
    </ConflictingBookingsSubFormRoot>
  ) : null
}

const VehicleUnavailabilityFormLayout = ({
  defaultValues,
  disabledInputsSources = [],
  onClose,
  type = UPDATE,
  ...props
}) => {
  const { filterValues } = useListContext()
  const record = useRecordContext(props)

  const now = useRef(getRoundedNow())
  const end = useRef(getDateWithThirtyMinutesOffset())

  defaultValues = {
    started_on: now.current,
    ended_on: end.current,
    ...filterValues,
    ...(filterValues?.organisation_id ? {} : { vehicle_id: undefined }),
    ...defaultValues,
  }

  return (
    <SimpleForm
      toolbar={<BasicFormToolbar formType={type} onCancel={onClose} />}
      defaultValues={defaultValues}
      record={record}
      mode="onBlur"
    >
      <Box>
        <OrganisationReferenceInput
          readOnly={type === UPDATE || isDisabled(disabledInputsSources, organisationsConfig.options.referenceKey)}
        />
        <VehicleReferenceInput
          linkedToHub={false}
          formType={type}
          readOnly={type === UPDATE || isDisabled(disabledInputsSources, vehiclesConfig.options.referenceKey)}
        />
        <FormDivider />
        <SelectInput
          choices={vehicleUnavailabilityTypeChoices}
          source="type"
          validate={required()}
          readOnly={type === UPDATE}
        />
        <TextInput multiline source="description" />
        <FormDivider />
        <AdvancedDateTimeInput validate={required()} source="started_on" minDate={now.current} />
        <FormDataConsumer>
          {({ formData }) => (
            <AdvancedDateTimeInput
              validate={[required(), validateEndAfterStart]}
              source="ended_on"
              minDate={formData.started_on ? parseDateAsDateTime(formData.started_on) : now.current}
            />
          )}
        </FormDataConsumer>
        <FormDivider
          condition={(formData) =>
            formData.started_on && formData.ended_on && formData[vehiclesConfig.options.referenceKey]
          }
        />
        <ConflictingBookingsSubForm />
      </Box>
    </SimpleForm>
  )
}

export const useCreateVehicleUnavailability = ({ disabledInputsSources }) => {
  const [createPopupState, setCreatePopupState] = useState({ isOpen: false, values: {} })
  const handleCreatePopupOpen = (values) => setCreatePopupState({ isOpen: true, values })
  const handleCreatePopupClose = () => setCreatePopupState({ isOpen: false, values: {} })
  const dialog = (
    <ResourceContextProvider value="maintenances">
      <CreateDialog
        isOpen={createPopupState.isOpen}
        open={handleCreatePopupOpen}
        close={handleCreatePopupClose}
        transform={transformValues}
      >
        <VehicleUnavailabilityFormLayout
          type={CREATE}
          disabledInputsSources={disabledInputsSources}
          defaultValues={createPopupState.values}
          onClose={handleCreatePopupClose}
        />
      </CreateDialog>
    </ResourceContextProvider>
  )
  return [handleCreatePopupOpen, dialog]
}
export const useEditVehicleUnavailability = () =>
  useEditResource('maintenances', <VehicleUnavailabilityFormLayout />, transformValues)

export default VehicleUnavailabilityFormLayout
