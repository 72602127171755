import jsonExport from 'jsonexport/dist'
import { downloadCSV } from 'react-admin'

import env from '../../config/env'
import { formatPrice } from '../../utils'
import { formatDateTimeForExport, formatDuration } from '../../utils/dates'

const bookingsExporter = (records) => {
  const employerAmountHeader = `employer_amount (${env.CURRENCY_CODE})`
  const finalPriceHeader = `final_price (${env.CURRENCY_CODE})`
  const fuelDeltaHeader = 'fuel_delta (L)'
  const initialPriceHeader = `initial_price (${env.CURRENCY_CODE})`
  const userRatingHeader = 'user_rating (/5)'

  const bookingsForExport = records.map((booking) => {
    // Remove non-used fields
    const { employer_amount, final_price, fuel_delta, initial_price, user_rating, ...bookingForExport } = booking
    // Add new fields (price formatting, duration formatting, date formatting & header change)
    bookingForExport[employerAmountHeader] = formatPrice(employer_amount)
    bookingForExport[finalPriceHeader] = formatPrice(final_price)
    bookingForExport[fuelDeltaHeader] = fuel_delta
    bookingForExport[initialPriceHeader] = formatPrice(initial_price)
    bookingForExport.time_driven = formatDuration(Math.round(booking.time_driven / 60))
    bookingForExport[userRatingHeader] = user_rating
    const dateFieldsToFormat = [
      'scheduled_start_date',
      'start_date',
      'scheduled_end_date',
      'end_date',
      'cancellation_date',
      'creation_date',
      'last_update_date',
    ]
    dateFieldsToFormat.forEach((field) => (bookingForExport[field] = formatDateTimeForExport(booking[field])))
    return bookingForExport
  })

  jsonExport(
    bookingsForExport,
    {
      // order fields in the export
      headers: [
        'id',
        'organisation_name',
        'hub_name',
        'user',
        'additional_drivers',
        'vehicle_type',
        'vehicle_model',
        'vehicle_plate',
        'billing_type',
        'payment_required',
        'payment_status',
        initialPriceHeader,
        'voucher',
        finalPriceHeader,
        employerAmountHeader,
        'justification',
        userRatingHeader,
        'period',
        'number_of_trips',
        fuelDeltaHeader,
        'time_driven',
        'number_of_km_travelled',
      ],
    },
    (err, csv) => downloadCSV(csv, 'bookings'),
  )
}

export default bookingsExporter
